export const listColumnsImport = {
    getColumns
  };
  
  function getColumns(table_name) {

    const tableColumns = [
        {
            table: 'materials',
            postUrl: '/preset/materials/import',
            postRevert: 'importations/revert',
            getUrl:'/importations/?type=materials',
            obj_data: 'materials',
            columns: [
                {name: 'name', label: 'NOME', required: true, type: 'string'},
                {name: 'description', label: 'DESCRIÇÃO', required: false, type: 'string'},
                {name: 'internal_code', label: 'CÓDIGO INTERNO', required: false, type: 'string'},
                {name: 'price', label: 'PREÇO', required: false, type: 'float'},
            ]
        },
        {
            table: 'surgeons',
            postUrl: '/preset/surgeons/import',
            postRevert: 'importations/revert',
            getUrl:'/importations/?type=surgeons',
            obj_data: 'surgeons',
            columns: [
                {name: 'cpf', label: 'CPF', required: false, type: 'string'},
                {name: 'crm_number', label: 'NÚMERO - CRM', required: false, type: 'integer'},
                {name: 'name', label: 'NOME', required: true, type: 'string'},
                {name: 'speciality', label: 'ESPECIALIDADE', required: false, type: 'string'},
                {name: 'phone_number', label: 'TELEFONE', required: false, type: 'integer'}
            ]
        },
        {
            table: 'surgical-map',
            postUrl: '/surgical-map/surgeries/import',
            postRevert: 'importations/revert',
            getUrl:'/importations/?type=surgical-map/surgeries',
            obj_data: 'surgical-maps',
            columns: [
                {name: 'procedure_surgery_probable_date', label: 'DATA DA CIRURGIA', required: true, type: 'datetime'},
                {name: 'procedure_medical_record_number', label: 'NÚMERO DO PRONTUÁRIO', required: false, type: 'string'},
                {name: 'procedure_name', label: 'NOME DO PROCEDIMENTO', required: true, type: 'string'},
                {name: 'surgical-procedure_code', label: 'CÓDIGO DO PROCEDIMENTO', required: false, type: 'string'},
                {name: 'patient_name', label: 'NOME DO PACIENTE', required: true, type: 'string'},
                {name: 'patient_birth_date', label: 'NASCIMENTO DO PACIENTE', required: false, type: 'date'},
                {name: 'patient_address', label: 'ENDEREÇO DO PACIENTE', required: false, type: 'string'},
                {name: 'patient_phone_number', label: 'TELEFONE DO PACIENTE', required: false, type: 'string'},
                {name: 'patient_cpf', label: 'CPF DO PACIENTE', required: false, type: 'integer'},
                {name: 'medical-plan_name', label: 'NOME DO CONVÊNIO', required: false, type: 'string'},
                {name: 'surgeon_name', label: 'NOME DO CIRURGIÃO', required: false, type: 'string'}
            ]
        },
        {
            table: 'surgical-map-pre',
            postUrl: 'surgical-map/pre-anesthetics/import',
            postRevert: 'importations/revert',
            getUrl:'/importations/?type=surgical-map/pre-anesthetics',
            obj_data: 'surgical-maps',
            columns: [
                {name: 'procedure_pre_probable_date', label: 'DATA DA CONSULTA', required: true, type: 'datetime'},
                {name: 'procedure_surgery_probable_date', label: 'DATA DA CIRURGIA', required: false, type: 'datetime'},
                {name: 'procedure_medical_record_number', label: 'NÚMERO DO PRONTUÁRIO', required: false, type: 'integer'},
                {name: 'procedure_name', label: 'NOME DO PROCEDIMENTO', required: true, type: 'string'},
                {name: 'surgical-procedure_code', label: 'CÓDIGO DO PROCEDIMENTO', required: false, type: 'string'},
                {name: 'patient_name', label: 'NOME DO PACIENTE', required: true, type: 'string'},
                {name: 'patient_birth_date', label: 'NASCIMENTO DO PACIENTE', required: false, type: 'date'},
                {name: 'patient_address', label: 'ENDEREÇO DO PACIENTE', required: false, type: 'string'},
                {name: 'patient_phone_number', label: 'TELEFONE DO PACIENTE', required: false, type: 'string'},
                {name: 'patient_cpf', label: 'CPF DO PACIENTE', required: false, type: 'integer'},
                {name: 'medical-plan_name', label: 'NOME DO CONVÊNIO', required: false, type: 'string'},
                {name: 'surgeon_name', label: 'NOME DO CIRURGIÃO', required: false, type: 'string'}
            ]
        }
    ];

    return tableColumns.find(x => x.table === table_name);
  
}