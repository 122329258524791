export {
  getFilterProcedures,
  setFilterProcedures,
  getFilterHealthchessLog,
  setFilterHealthchessLog
};

function getFilterProcedures() {
  return JSON.parse(localStorage.getItem('filter-procedure'));
}

function setFilterProcedures(objFilter) {
  return localStorage.setItem('filter-procedure', JSON.stringify(objFilter));
}

function getFilterHealthchessLog() {
  return JSON.parse(localStorage.getItem('filter-healthchess-log'));
}

function setFilterHealthchessLog(objFilter) {
  return localStorage.setItem('filter-healthchess-log', JSON.stringify(objFilter));
}
