import {surgeonsConstants} from '../constants';
import {surgeonsService} from "../services";

export const surgeonsActions = {
  getSurgeons,
};

function getSurgeons(filter, cancelToken) {
  return dispatch => {
    dispatch(request());

    return surgeonsService.getSurgeons(filter, cancelToken)
      .then(surgeons => {
        dispatch(success(surgeons));
        return surgeons;
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: surgeonsConstants.GETSURGEONS_REQUEST}
  }

  function success(data) {
    return {type: surgeonsConstants.GETSURGEONS_SUCCESS, data}
  }

  function failure(error) {
    return {type: surgeonsConstants.GETSURGEONS_FAILURE, error}
  }
}
