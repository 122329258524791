import React, { Component } from "react";

import {
  Layout,
  Row,
  Col,
  Form,
  Table,
  Tag,
  Input,
  Select,
  Icon,
  Tooltip,
  DatePicker,
  Button,
  Popover,
  Empty,
  Tabs,
  Spin,
  notification
} from "antd";
import { connect } from "react-redux";
import { healthchessLogActions } from "../../actions";
import locale from "antd/lib/date-picker/locale/pt_BR";
import { stringsHelp } from "../../helpers";
import { CancelToken } from "axios";
import {
  getFilterHealthchessLog,
  setFilterHealthchessLog
} from "../../helpers/filters";

import moment from "moment";

const { Content } = Layout;
const { Search } = Input;
const Option = Select.Option;
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";
const { TabPane } = Tabs;

class HealthchessLog extends Component {
  state = {
    dataHealthchess: [],
    start_date: getFilterHealthchessLog()
      ? moment(getFilterHealthchessLog().start_date, "YYYY-MM-DD")
      : moment().subtract(parseInt(1), "months"),
    end_date: getFilterHealthchessLog()
      ? moment(getFilterHealthchessLog().end_date, "YYYY-MM-DD")
      : moment(),
    filterAll: ""
  };

  cancelSource = CancelToken.source();

  loadMap = () => {
    const { dispatch, healthchessLog } = this.props;

    if (healthchessLog.loading) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    const objFilter = {
      start_date: this.state.start_date.format("YYYY-MM-DD"),
      end_date: this.state.end_date.format("YYYY-MM-DD")
    };

    dispatch(
      healthchessLogActions.getHealthchessLog(
        { params: objFilter },
        this.cancelSource.token
      )
    ).then(response => {
      this.setState({
        dataHealthchess: response
      });

      this.searchTable(this.state.filterAll);
    });
  };

  componentDidMount() {
    this.loadMap();
  }

  searchTable = value => {
    const { healthchessLog } = this.props;
    const text = value;

    setTimeout(() => {
      if (text.length) {
        let newDataHealthchess = healthchessLog.data.filter(x => {
          return (
            stringsHelp
              .removeAccent(x.name.toString().toLowerCase())
              .includes(stringsHelp.removeAccent(text.toLowerCase())) ||
            (x.json &&
              stringsHelp
                .removeAccent(x.surgeons.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase()))) ||
            (x.patient_name &&
              stringsHelp
                .removeAccent(x.patient_name.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase()))) ||
            (x.surgeons &&
              stringsHelp
                .removeAccent(x.surgeons.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase()))) ||
            (x.surgical_teams &&
              stringsHelp
                .removeAccent(x.surgical_teams.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase()))) ||
            (x.institution_name &&
              stringsHelp
                .removeAccent(x.institution_name.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase()))) ||
            (x.anaesthetists &&
              stringsHelp
                .removeAccent(x.anaesthetists.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase())))
          );
        });
        this.setState({ dataHealthchess: newDataHealthchess, filterAll: value });
      } else {
        this.setState({ dataHealthchess: healthchessLog.data, filterAll: "" });
      }
    }, 300);
  };

  setFilter = (type, value) => {
    if (type === "range_date") {
      this.setState({ start_date: value[0], end_date: value[1] }, () => {
        setFilterHealthchessLog({ start_date: value[0], end_date: value[1] });
        this.loadMap();
      });
    }

    if (type === "anaesthetist_id") {
      this.setState({ anaesthetist_id: value }, () => {
        this.loadMap();
      });
    }
  };

  render() {
    const { healthchessLog } = this.props;

    const data = this.state.dataHealthchess;

    const columnsTable = [
      {
        title: "Paciente",
        dataIndex: "patient_name",
        key: "patient_name",
        render: (patient_name, record) => {
          return patient_name;
        }
      },
      JSON.parse(localStorage.getItem("selected-institution")).is_group
        ? {
          title: "Instituição",
          dataIndex: "institution_name",
          key: "institution_name",
          render: institution_name => {
            if (
              JSON.parse(localStorage.getItem("selected-institution"))
                .is_group
            ) {
              return (
                <span>
                    <Tag
                      className="tag-custom-round"
                      color="rgb(221, 246, 246)"
                      style={ { marginBottom: 3 } }
                    >
                      { " " }
                      <Icon type="home"/>{ " " }
                      { stringsHelp.maxLength(
                        stringsHelp.formatName(institution_name),
                        40,
                        true
                      ) }
                    </Tag>
                  </span>
              );
            } else {
              return "";
            }
          }
        }
        : {},
      {
        title: "Procedimento(s)",
        dataIndex: "name",
        key: "name",
        render: name => {
          return stringsHelp.firstLetterUpper(name, true);
        }
      },
      {
        title: "Anestesiologista",
        dataIndex: "anaesthetists",
        key: "anaesthetists",
        render: anaesthetists => {
          if (!anaesthetists) return "";

          const listAnaesthetists = anaesthetists
            .split(",")
            .map(anaesthetist => {
              return (
                <span style={ { lineHeight: "28px" } }>
                  <Tag>{ anaesthetist.trim() }</Tag>
                </span>
              );
            });

          return listAnaesthetists;
        }
      },
      {
        title: "Equipe cirúrgica",
        dataIndex: "surgeons",
        key: "surgeons",
        width: 140,
        render: (surgeons, record) => {
          const content = (
            <div>
              <p>
                <small>
                  <b>Cirúrgiões</b>
                </small>
              </p>
              { surgeons ? (
                surgeons.split(",").map(surgeon => {
                  return <p>{ surgeon }</p>;
                })
              ) : (
                <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE }/>
              ) }
              <p style={ { marginTop: 5 } }>
                <small>
                  <b>Equipe auxiliar</b>
                </small>
              </p>
              { record.surgical_teams ? (
                record.surgical_teams.split(",").map(surgical_team => {
                  return <p>{ surgical_team }</p>;
                })
              ) : (
                <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE }/>
              ) }
            </div>
          );

          const contentNotFound = (
            <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE }/>
          );

          return (
            <div style={ { textAlign: "center" } }>
              <Popover
                content={
                  !surgeons && !record.surgical_teams
                    ? contentNotFound
                    : content
                }
                title="Equipe cirúrgica"
                trigger="hover"
              >
                <Button type="dashed" shape="circle" icon="team"/>
              </Popover>
            </div>
          );
        }
      },
      {
        title: "Data",
        dataIndex: "register_date",
        key: "anesthesia_start",
        width: 160,
        render: register_date => {
          return moment(register_date).format("DD/MM/YYYY HH:mm");
        }
      },
      {
        title: "Data Healthchess",
        dataIndex: "created_at",
        key: "created_at",
        width: 160,
        render: register_date => {
          return moment(register_date).format("DD/MM/YYYY HH:mm");
        }
      }
    ];

    return (
      <Content>
        <Row>
          <Col xs={ 24 } sm={ 0 }>
            <p>&nbsp;</p>
          </Col>
          <Col lg={ 24 } xl={ 16 }>
            <h1 className="title">Procedimentos Buscados pela Healthchess.</h1>
          </Col>
          <Col lg={ 24 } xl={ 8 } className="containterFilterHealthchessLog">
            <Col lg={ 24 } className="filterHealthchessLog">
              <RangePicker
                allowClear={ false }
                locale={ locale }
                value={ [
                  moment(this.state.start_date, "DD/MM/YYYY"),
                  moment(this.state.end_date, "DD/MM/YYYY")
                ] }
                style={ { width: "100%" } }
                format={ dateFormat }
                onChange={ e => this.setFilter("range_date", e) }
              />
            </Col>
          </Col>
        </Row>
        <Row>
          <Search
            placeholder="Busca livre"
            disabled={ healthchessLog.loading }
            onChange={ e => this.searchTable(e.target.value) }
            style={ { width: "100%", marginRight: 10, marginBottom: 10 } }
          />
        </Row>

        <Row>
          <Table
            size="midle"
            loading={ healthchessLog.loading }
            dataSource={ data }
            columns={ columnsTable }
            pagination={ {
              defaultPageSize: 30,
              showSizeChanger: true,
              pageSizeOptions: [ "30", "50", "100" ]
            } }
            footer={ () => {
              const rowsDataSource = !data
                ? 0
                : data.length;
              return (
                <span>
                      Total de registros: <Tag>{ rowsDataSource }</Tag>
                    </span>
              );
            } }
          />
        </Row>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { healthchessLog } = state;
  return {
    healthchessLog
  };
}

const connected = connect(mapStateToProps)(Form.create()(HealthchessLog));
export default connected;
