import Api from '../helpers/api';
import uuid from 'uuid/v4';

export const schedulingService = {
  getScheduling,
  postProcedure,
  getProcedure,
  putProcedure,
  putCancellationReason
};

function getScheduling(params, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/app/v2/schedules?${params}`, {cancelToken})
}

function getProcedure(id, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/surgical-map/procedures/${id}`, {cancelToken})
}

function postProcedure(procedure){
    procedure.uuid = uuid();
  return Api.post(`/app/procedures`, procedure)
}


function putProcedure(procedure){
  return Api.put(`/app/procedures/${procedure.id}`, procedure)
}

function putCancellationReason(id, procedure){
  return Api.put(`/app/procedures/cancellation/${id}`, procedure)
}