import {healthchessLogConstants} from '../constants';
import {healthchessLogService} from "../services";

export const healthchessLogActions = {
  getHealthchessLog,
};

function getHealthchessLog(filter, cancelToken) {
  return dispatch => {
    dispatch(request());

    return healthchessLogService.getHealthchessLog(filter, cancelToken)
      .then(healthchessLog => {
        dispatch(success(healthchessLog));
        return healthchessLog;
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: healthchessLogConstants.GETHEALTHCHESS_LOG_REQUEST}
  }

  function success(data) {
    return {type: healthchessLogConstants.GETHEALTHCHESS_LOG_SUCCESS, data}
  }

  function failure(error) {
    return {type: healthchessLogConstants.GETHEALTHCHESS_LOG_FAILURE, error}
  }
}
