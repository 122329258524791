import React, { Component } from "react";

import {
  Layout,
  Row,
  Col,
  Tooltip,
  Icon,
  Table,
  Button,
  Input,
  Modal,
  Form,
  notification,
  Divider,
  Tag
} from "antd";
import { connect } from "react-redux";
import { monitorQrcodesActions } from "../../actions";
import { monitorQrcodesService } from "../../services";
import { numbers} from "../../helpers";
import { CancelToken } from "axios";
import { stringsHelp } from "../../helpers";
import { mixPanelService } from '../../services/mixpanel.service';

const { Content } = Layout;
const FormItem = Form.Item;
const { Search } = Input;

class MonitorQRCodes extends Component {
  state = {
    modalVisible: false,
    actionNew: true,
    loadingSave: false,
    confirmVisible: false,
    confirmLoading: false,
    delete_uuid: undefined,
    dataQRCodes: []
  };

  cancelSource = CancelToken.source();

  load = () => {
    const { dispatch, monitorQrcodes } = this.props;

    if (monitorQrcodes.loading) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    dispatch(monitorQrcodesActions.getMonitorQrcodes({}, this.cancelSource.token)).then(data => {
      this.setState({
        dataQRCodes: data
      });
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.price = values.price ? numbers.numberToDoubleDecimal(values.price) : null;

        let promiseSave;

        if (values.id) {
          this.setState({ loadingSave: true });

          promiseSave = monitorQrcodesService.putMonitorQrcode(values).then(result => {
            if (result.id > 0) {
              this.handleCancel();

              notification.success({
                message: "QRCode alterado com sucesso!"
              });

              this.load();
            }
          });

        } else {
          this.setState({ loadingSave: true });

          delete values.id;

          promiseSave = monitorQrcodesService.postMonitorQrcode(values).then(result => {
            if (result.id > 0) {
              this.handleCancel();

              notification.success({
                message: "QRCode cadastrado com sucesso!"
              });

              this.load();
            }
          });
        }

        promiseSave.catch(error => {
          console.log(error);
          this.setState({ loadingSave: false });
          notification.error({
            message: error
          });
        });
      }
    });
  };

  handleCancel = () => {
    const clearMedicalPlan = {
      qrcode: "",
      id: null,
      device_id: null
    };

    this.setState({ modalVisible: false, loadingSave: false });
    this.props.form.setFieldsValue(clearMedicalPlan);
  };

  openModal = p_id => {
    if (p_id) {
      mixPanelService.tracking(mixPanelService.QRCODE_EDITAR);

      const monitorQrcodeObject = this.props.monitorQrcodes.data.find(
        s => s.id === p_id
      );

      const editMonitorQrcode = {
        qrcode: monitorQrcodeObject.qrcode,
        id: monitorQrcodeObject.id,
        device_id: monitorQrcodeObject.device_id
      };

      this.setState({ modalVisible: true});
      this.props.form.setFieldsValue(editMonitorQrcode);

    } else {
      mixPanelService.tracking(mixPanelService.QRCODE_ADD);

      const newMonitorQrcode = {
        qrcode: '',
        id: null,
        device_id: null
      };

      this.setState({ modalVisible: true});
      this.props.form.setFieldsValue(newMonitorQrcode);

    }
  };

  componentDidMount() {
    mixPanelService.tracking(mixPanelService.MENU_QRCODE);
    this.load();
  }


  showConfirm = uuid => {
    this.setState({
      confirmVisible: true,
      confirmLoading: false,
      delete_uuid: uuid
    });
  };

  handleDelete = () => {    
    let promiseSave;

    if (this.state.delete_uuid) {
      this.setState({ confirmLoading: true });

      mixPanelService.tracking(mixPanelService.QRCODE_REMOVER);

      promiseSave = monitorQrcodesService
        .deleteMonitorQrcode(this.state.delete_uuid)
        .then(result => {
          notification.success({
            message: "QRcode removido com sucesso!"
          });

          this.load();

          this.setState({
            confirmLoading: false,
            confirmVisible: false,
            delete_uuid: null
          });
        });

      promiseSave.catch(error => {
        console.log(error);
        notification.error({
          message: "Falha ao remover QRcode, tente novamente mais tarde!"
        });
      });
    }
  };

  searchTable = e => {
    const { monitorQrcodes } = this.props;
    const text = e.target.value;

    setTimeout(() => {
    if (text.length) {
      let newDataQRCodes = monitorQrcodes.data.filter(x => {
        return (
          stringsHelp
            .removeAccent(x.qrcode.toString().toLowerCase())
            .includes(stringsHelp.removeAccent(text.toLowerCase())) ||
            (x.device_id &&  stringsHelp
              .removeAccent(x.device_id.toString().toLowerCase())
              .includes(stringsHelp.removeAccent(text.toLowerCase())))
        );
      });
      this.setState({ dataQRCodes: newDataQRCodes });
    } else {
      this.setState({ dataQRCodes: monitorQrcodes.data });
    }
  }, 300);
  };

  render() {
    const { monitorQrcodes } = this.props;
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 }
      },
      colon: false
    };

    const columnsTableMonitorQrcodes = [
      {
        title: "QRCode",
        dataIndex: "qrcode",
        key: "qrcode"
      },
      {
        title: "Identificador do Equipamento",
        dataIndex: "device_id",
        key: "device_id"
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        width: 80,
        render: (id, record) => (
          <span>
            <Tooltip title="Editar">
            <Button type='link' onClick={() => this.openModal(id)}>
              <Icon type="edit" />
            </Button>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Remover">
              <Button type='link' onClick={() => this.showConfirm(id)}>
                <Icon type="delete" />
              </Button>
            </Tooltip>
          </span>
        )
      }
    ];

    return (
      <Content>
        <Row>
          <Col xs={24} sm={0}>
            <button
              onClick={() => this.openModal(null)}
              className="ant-btn ant-btn-primary ant-btn-lg ant-btn-circle add-procedure"
            >
              <Icon type="plus" fill="#ffffff" />
            </button>
          </Col>

          <Col xs={24} lg={8}  sm={16} >
            <h1 className="title">
            QRCodes 
            </h1>
          </Col>
          <Col xs={0} lg={16} sm={8} style={{display: 'flex'}}>
          <Search
                          placeholder="Buscar"
                          onChange={this.searchTable}
                          style={{ width: "100%", marginRight: 10 }}
                        />
            <Button
              type="primary"
              shape="round"
              className="add-procedure"
              onClick={() => this.openModal()}
            >
              ADICIONAR
            </Button>
          </Col>
        </Row>
        <Row>
          <Table
            size="midle"
            loading={monitorQrcodes.loading}
            dataSource={this.state.dataQRCodes}
            columns={columnsTableMonitorQrcodes}
            footer={() => {
              const rowsDataSource =  !this.state.dataQRCodes ? 0 : this.state.dataQRCodes.length
              return <span>Total de registros: <Tag>{rowsDataSource}</Tag></span>;
             }}
          />
        </Row>

        <Modal
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          title={
            <div>
              <button
                className="modal-close"
                type="button"
                onClick={this.handleCancel}
              >
                <i className="icon-close" />
              </button>
              <span>
                {this.props.form.getFieldValue('id')
                  ? "Editar QRCode"
                  : "Adicionar QRCode"}
              </span>
            </div>
          }
          footer={[
            <Button
              key="submit"
              type="primary"
              form="formMedicalPlan"
              htmlType="submit"
              loading={this.state.loadingSave}
            >
              Salvar
            </Button>
          ]}
          bodyStyle={{ paddingTop: "21px" }}
          width="701px"
          zIndex={1024}
        >
          <Form onSubmit={this.handleSubmit} id="formMedicalPlan">
            <Row gutter={28}>
              <Col md={24} lg={24} xl={24}>
                {getFieldDecorator("id", {})(<span />)}
                
                <FormItem {...formItemLayout} label="QRCode">
                  {getFieldDecorator("qrcode", {
                    rules: [
                      {
                        required: true,
                        message: "Preencha o texto do QRCode."
                      }
                    ]
                  })(<Input autoFocus={true} />)}
                </FormItem>
              </Col>

              <Col md={24} lg={24} xl={14}>
                <FormItem {...formItemLayout} label="Identificador do Equipamento">
                  {getFieldDecorator("device_id", {})(<Input />)}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title=""
          visible={this.state.confirmVisible}
          onOk={() => this.handleDelete()}
          okText="Sim"
          okType="primary"
          cancelText="Não"
          confirmLoading={this.state.confirmLoading}
          onCancel={() => this.setState({ confirmVisible: false })}
        >
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            <span style={{ fontSize: 24, color: "#ffcd56", marginRight: 10 }}>
              <Icon type="question-circle" />
            </span>{" "}
            Confirma remover o QRCode ?
          </p>
        </Modal>

      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { monitorQrcodes } = state;
  return {
    monitorQrcodes
  };
}

const connected = connect(mapStateToProps)(Form.create()(MonitorQRCodes));
export default connected;
