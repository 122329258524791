import React, { Component } from "react";
import Highlighter from "react-highlight-words";

import {
  Layout,
  Row,
  Col,
  Tooltip,
  Icon,
  Table,
  Spin,
  Button,
  Card,
  Input,
  Tag,
  Modal,
  Form,
  notification,
  Divider,
  Tree,
  Alert,
  Avatar
} from "antd";
import { connect } from "react-redux";
import { drugsActions } from "../../actions";
import { drugsService } from "../../services";
import { stringsHelp } from "../../helpers";
import { CancelToken } from "axios";
import SelectInstitutions from "../SelectInstitutions";
import { mixPanelService } from '../../services/mixpanel.service';

const { Content } = Layout;
const { Search } = Input;
const { TreeNode } = Tree;

class Drugs extends Component {
  state = {
    modalVisible: false,
    actionNew: true,
    loadingSave: false,
    modalDrug: undefined,
    modalLoading: false,
    defaultCheckedKeys: [],
    checkedDrug: { forms: [] },
    dataDrugs: [],
    selectGroupInstitutionId: null
  };

  cancelSource = CancelToken.source();

  onCheck = (checkedKeys, info) => {
    let arrayForms = { forms: [] };

    this.state.modalDrug.forms.map(currentF => {
      let arayBrands = [];

      info.checkedNodes.map(currentB => {
        if (currentB.key.includes("b-")) {
          if (currentF.id === currentB.ref) {
            arayBrands.push(parseInt(currentB.key.replace("b-", "")));
          }
        }
        return currentB;
      });

      if (arayBrands.length) {
        arrayForms.forms.push({
          id: currentF.id,
          active_principle_brands: arayBrands
        });
      } else {
        arrayForms.forms.push({ id: currentF.id, active_principle_brands: [] });
      }

      return currentF;
    });

    this.setState({ checkedDrug: arrayForms });
  };

  handleCancel = () => {
    this.setState({ modalVisible: false, loadingSave: false });
  };

  openModal = p_id => {
    let promiseSave;
    this.setState({ modalVisible: true, modalLoading: true });

    mixPanelService.tracking(mixPanelService.FARMACO_CONFIGURAR);

    let objFilter = {};
    if (this.state.selectGroupInstitutionId) {
      objFilter = { params: { institution_id: this.state.selectGroupInstitutionId } };
    }

    promiseSave = drugsService.getDrug(p_id, objFilter).then(result => {
      let newDrug = undefined;
      let defaultCheck = [];
      let checkedDrug = { forms: [] };

      if (result) {
        newDrug = {};

        newDrug.id = result.id;
        newDrug.name = result.active_principle_i18n[0].name;
        newDrug.forms = [];

        result.forms.map(function(form) {
          let newForm = {
            id: form.id,
            name:
              (form.presentation ? form.presentation + " x " : "") +
              (form.type ? form.type + " " : " ")  +
              (form.presentation_volume
                ? form.presentation_volume + "" + form.presentation_volume_unit
                : ""),
            brands: []
          };
          let arayBrands = [];

          form.brands.map(function(brand) {
            newForm.brands.push({
              id: brand.id,
              name: stringsHelp.firstLetterUpper(brand.brand),
              laboratory: stringsHelp.firstLetterUpper(brand.laboratory)
            });
            if (brand.institutions.length) {
              arayBrands.push(brand.id);
              defaultCheck.push("b-" + brand.id);
            }
            return brand;
          });


            checkedDrug.forms.push({
              id: form.id,
              active_principle_brands: arayBrands
            });
          
          newDrug.forms.push(newForm);

          return form;
        });
      }

      this.setState({
        modalLoading: false,
        modalDrug: newDrug,
        defaultCheckedKeys: defaultCheck,
        checkedDrug
      });
    });

    promiseSave.catch(error => {
      console.log(error);
      notification.error({
        message:
          "Falha ao carregar detalhes de agendamento, notifique o suporte técnico!"
      });
    });
  };

  load = () => {
    const { dispatch, drugs } = this.props;

    if (drugs.loading) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    let objFilter = {};
    if (this.state.selectGroupInstitutionId) {
      objFilter = { params: { institution_id: this.state.selectGroupInstitutionId } };
    }

    dispatch(drugsActions.getDrugs(objFilter, this.cancelSource.token)).then(
      data => {
        this.setState({
          dataDrugs: data.data
        });
      }
    );
  };

  filterInstitutionGroup = value => {
    if (!value) return;

    this.setState({ selectGroupInstitutionId: value }, () => {
      this.load();
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    if (this.state.checkedDrug.forms) {
      let promiseSave;

      this.setState({ loadingSave: true });

      mixPanelService.tracking(mixPanelService.FARMACO_SALVAR);

      if(this.state.selectGroupInstitutionId){
        this.state.checkedDrug.institution_id = this.state.selectGroupInstitutionId;
      }

      promiseSave = drugsService
        .putDrug(this.state.modalDrug.id, this.state.checkedDrug)
        .then(result => {
          this.handleCancel();

          notification.success({
            message: "Configuração realizada com sucesso!"
          });

          this.load();
        });

      promiseSave.catch(error => {
        console.log(error);
        this.setState({ loadingSave: false });
        notification.error({
          message: "Falha ao realizar configuração, tente novamente mais tarde!"
        });
      });
    } else {
      notification.error({
        message:
          "Selecione a marca comercial de acordo com apresentação farmacológica!"
      });
    }
  };

  componentDidMount() {
    mixPanelService.tracking(mixPanelService.MENU_FARMACO);

    if (
      JSON.parse(localStorage.getItem("selected-institution")) &&
      JSON.parse(localStorage.getItem("selected-institution")).is_group
    )
      return;

    this.load();

    mixPanelService.tracking(mixPanelService.MENU_FARMACO);
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 200, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 100, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Resetar
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return stringsHelp
          .removeAccent(record[dataIndex].toString().toLowerCase())
          .includes(stringsHelp.removeAccent(value.toLowerCase()));
      } else {
        return false;
      }
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  formatDrugs = drugs => {
    var newDrugs = [];

    if (drugs) {
      drugs.forEach(function(drug) {
        let newObj = drug;
        newObj.name = drug.active_principle_i18n[0].name;
        newDrugs.push(newObj);
      });
    }

    return newDrugs;
  };

  searchTable = e => {
    const { drugs } = this.props;
    const text = e.target.value;

    setTimeout(() => {
      if (text.length) {
        let newDataDrugs = drugs.data.filter(x => {
          return (
            (x.active_principle_i18n &&
              stringsHelp
                .removeAccent(
                  x.active_principle_i18n[0].name.toString().toLowerCase()
                )
                .includes(stringsHelp.removeAccent(text.toLowerCase()))) ||
            (x.active_principle_class &&
              stringsHelp
                .removeAccent(
                  x.active_principle_class.class.toString().toLowerCase()
                )
                .includes(stringsHelp.removeAccent(text.toLowerCase()))) ||
            x.brands
              .map(function(elem) {
                return stringsHelp.removeAccent(elem.name).toLowerCase();
              })
              .join(",")
              .includes(stringsHelp.removeAccent(text.toLowerCase()))
          );
        });
        this.setState({ dataDrugs: newDataDrugs });
      } else {
        this.setState({ dataDrugs: drugs.data });
      }
    }, 300);
  };

  render() {
    const { drugs, institutions } = this.props;

    const columnsTableDrugs = [
      {
        title: "Princípio ativo",
        dataIndex: "name",
        key: "name",
        render: (name, record) => (
          <div>
            <h4 style={{ color: "#343f5c" }}>
              {record.active_principle_i18n[0].name}
            </h4>
            <Tooltip title="Apresentações comerciais">
              {" "}
              <div style={{ margin: 0 }}>
                <Icon type="tag" /> &nbsp;
                {record.brands.map((item, i) => (
                  <span style={{ color: "rgba(111, 110, 110, 0.73)" }} key={i}>
                    {i > 0 ? <Divider type="vertical" /> : ""}
                    {item.institutions.length ? (
                      <Tag>{stringsHelp.firstLetterUpper(item.name)}</Tag>
                    ) : (
                      stringsHelp.firstLetterUpper(item.name)
                    )}
                  </span>
                ))}
              </div>
            </Tooltip>
          </div>
        )
      },

      {
        title: "Classe farmacológica",
        dataIndex: "active_principle_class",
        key: "active_principle_class",
        width: 200,
        render: active_principle_class => (
          <Tag>
            {active_principle_class.active_principle_class_i18n[0].name}
          </Tag>
        )
      },
      {
        title: "Configurar",
        dataIndex: "id",
        key: "id",
        width: 120,
        className: "text-center",
        render: id => (
          <Tooltip placement="left" title="Configurar apresentações">
            <span>
              <Button type="link" onClick={() => this.openModal(id)}>
                <Icon type="setting" />
              </Button>
            </span>
          </Tooltip>
        )
      }
    ];

    return (
      <Content>
        {JSON.parse(localStorage.getItem("selected-institution")) &&
        JSON.parse(localStorage.getItem("selected-institution")).is_group ? (
          <Row>
             <Col xs={24} sm={0}>
              <p>&nbsp;</p>
            </Col>
            <Col xs={24} lg={12} xxl={16}>
              <h1 className="title">Fármacos</h1>
            </Col>
            <Col xs={24} sm={0}>
              <div style={{ marginBottom: 10 }}>
              <SelectInstitutions onChange={this.filterInstitutionGroup} />
              </div>
            </Col>
            <Col xs={0} lg={12} xxl={8}>
              <div style={{ display: "flex" }}>
              <SelectInstitutions onChange={this.filterInstitutionGroup} />
              </div>
            </Col>
            {this.state.selectGroupInstitutionId ? (
              <Col span={24} style={{ paddingBottom: 10 }}>
                <Search
                  placeholder="Buscar"
                  onChange={this.searchTable}
                  style={{ width: "100%" }}
                  disabled={drugs.loading}
                />
              </Col>
            ) : (
              <Col span={24} style={{ paddingBottom: 10 }}>
                <Alert
                  message="Selecione a instituição para listar as configurações internas de fármacos"
                  type="info"
                  closeText={
                    <Icon style={{ color: "#1890ff" }} type="arrow-up" />
                  }
                  showIcon
                />
              </Col>
            )}
          </Row>
        ) : (
          <Row>
            <Col xs={24} lg={0}>
              <p>&nbsp;</p>
            </Col>

            <Col xs={24} lg={12}>
              <h1 className="title">Fármacos</h1>
            </Col>
            <Col xs={24} lg={0}>
            <div style={{ marginBottom: 10}}>
            <Search
                placeholder="Buscar"
                onChange={this.searchTable}
                style={{ width: "100%" }}
                disabled={drugs.loading}
              />
              </div>
            </Col>
            <Col xs={0} lg={12}>
            <div style={{ display: "flex" }}>
              <Search
                placeholder="Buscar"
                onChange={this.searchTable}
                style={{ width: "100%" }}
                disabled={drugs.loading}
              />
              </div>
            </Col>
          </Row>
        )}
        <Row>
          {JSON.parse(localStorage.getItem("selected-institution")) &&
          JSON.parse(localStorage.getItem("selected-institution")).is_group &&
          !this.state.selectGroupInstitutionId ? (
            <div />
          ) : (
            <Table
              size="midle"
              key="tableDrugs"
              loading={drugs.loading}
              dataSource={this.state.dataDrugs}
              columns={columnsTableDrugs}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "30", "50", "100"]
              }}
              footer={() => {
                const rowsDataSource = !this.state.dataDrugs
                  ? 0
                  : this.state.dataDrugs.length;
                return (
                  <span>
                    Total de registros: <Tag>{rowsDataSource}</Tag>
                  </span>
                );
              }}
            />
          )}
        </Row>

        <Modal
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          title={
            <div>
              <button
                className="modal-close"
                type="button"
                onClick={this.handleCancel}
              >
                <i className="icon-close" />
              </button>
              <span>{"Configurar apresentações"}</span>
              <span style={{ float: "right" }}>
                {" "}
                <Tooltip
                  placement="bottom"
                  title="Selecione as marcas comerciais de medicamentos de acordo com a apresentação farmacológica que a instituição utiliza!"
                >
                  {" "}
                  <Button shape="circle" icon="info" />{" "}
                </Tooltip>{" "}
              </span>
            </div>
          }
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={this.handleSubmit}
              loading={this.state.loadingSave}
            >
              Salvar
            </Button>
          ]}
          bodyStyle={{ paddingTop: "15px" }}
          width="701px"
          zIndex={1024}
        >
          {!this.state.modalLoading && this.state.modalDrug ? (
            <div>
                {this.state.selectGroupInstitutionId && (
                <div>
                  {JSON.parse(localStorage.getItem("selected-institution")) &&
                  institutions.items &&
                  institutions.items.size > 1
                    ? institutions.items
                        .filter(
                          i => i.id === this.state.selectGroupInstitutionId
                        )
                        .map(institution => {
                          return (
                            <div className="ant-row ant-form-item">
                              <div className="ant-col ant-form-item-label">
                                <label
                                  className="ant-form-item-no-colon"
                                  title="INSTITUIÇÃO"
                                >
                                  INSTITUIÇÃO
                                </label>
                              </div>

                              <div
                                className="ant-list-item-meta ant-group"
                                style={{ marginTop: 10 }}
                              >
                                <div className="ant-list-item-meta-avatar">
                                  <Avatar
                                    shape="square"
                                    src={
                                      institution.logo_url
                                        ? institution.logo_url
                                        : null
                                    }
                                    className="institution-logo"
                                  >
                                    Logo
                                  </Avatar>
                                </div>
                                <div className="ant-list-item-meta-content">
                                  <h4
                                    className="ant-list-item-meta-title"
                                    style={{ marginBottom: -5 }}
                                  >
                                    {stringsHelp.formatName(institution.name)}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          );
                        })
                    : ""}
                </div>
              )}
              
              <Card
                title={
                  <span style={{ fontSize: "14px", color: "#343f5c" }}>
                    {this.state.modalDrug.name.toUpperCase()}{" "}
                  </span>
                }
                bordered={false}
                extra={
                  <small>
                    <Icon type="setting" /> <b>Apresentação</b>{" "}
                    <Divider type="vertical" /> <Icon type="tag" /> Marca
                    comercial{" "}
                  </small>
                }
              >
                <Alert
                  style={{ padding: "4px 15px", marginBottom: 5 }}
                  message={
                    <span>
                      Lista de medicamentos padrões da <b>Anvisa.</b>{" "}
                      <a
                        style={{ float: "right" }}
                        href="http://portal.anvisa.gov.br/registros-e-autorizacoes/medicamentos/produtos/medicamentos-de-referencia/lista"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Saiba mais
                      </a>
                    </span>
                  }
                  type="warning"
                />
                <Tree
                  showIcon
                  checkable
                  defaultCheckedKeys={this.state.defaultCheckedKeys}
                  onCheck={this.onCheck}
                  defaultExpandAll={true}
                >
                  {this.state.modalDrug.forms.map(currentForm => {
                    return (
                      <TreeNode
                        icon={<Icon type="setting" />}
                        title={
                          <span>
                            <b>{currentForm.name}</b>
                          </span>
                        }
                        key={"f-" + currentForm.id}
                        selectable={false}
                      >
                        {currentForm.brands.map(currentBrand => {
                          return (
                            <TreeNode
                              icon={<Icon type="tag" />}
                              title={
                                <span>
                                  {currentBrand.name} {"  "}
                                  <small
                                    style={{
                                      color: "rgba(111, 110, 110, 0.65)"
                                    }}
                                  >
                                    {currentBrand.laboratory}
                                  </small>
                                </span>
                              }
                              ref={currentForm.id}
                              key={"b-" + currentBrand.id}
                              selectable={false}
                            />
                          );
                        })}
                      </TreeNode>
                    );
                  })}
                </Tree>
              </Card>

            
            </div>
          ) : (
            <Row style={{ textAlign: "center", padding: 20 }}>
              <Spin />
            </Row>
          )}
        </Modal>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { drugs, institutions } = state;
  return {
    drugs,
    institutions
  };
}

const connected = connect(mapStateToProps)(Form.create()(Drugs));
export default connected;
