import Api  from '../helpers/api';

export const patientService = {
  getPatients,
  getPatient,
  postPatient,
  putPatient
};

function getPatients(page, filter, cancelToken, config) {
  filter = filter === undefined ? '' : filter ;
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/surgical-map/patients/${page}${filter !== '' ? '/' + filter : '' }`, {
    cancelToken,
    ...config
  })
}

function getPatient(id, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/surgical-map/patients/findOne/${id}`, {cancelToken})
}

function postPatient(patient){
  return Api.post(`/app/patients`, patient)
}


function putPatient(patient){
  return Api.put(`/app/v2/patients/${patient.id}`, patient)
}
