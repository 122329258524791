import Api from '../helpers/api';

export const proceduresService = {
  getProcedures,
  getDownload
};

function getProcedures(objFilter, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;
  
  return Api.get(`surgical-map/procedures-all`,objFilter,{crossDomain:true,cancelToken})
  
}

function getDownload(pdf, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;
  
  return Api.get(`/surgical-map/procedure-download/${pdf}`,{crossDomain:true,cancelToken})
  
}
