import React, { Component } from 'react';
import 'moment/locale/pt-br';
import { connect } from 'react-redux';

import {
  Col,
  Layout, Row
} from 'antd';

import { BrowserRouter, Switch } from "react-router-dom";
import AppFooter from "./components/layout/AppFooter";
import AppMenu from "./components/layout/AppMenu";
import routes from "./routes";
import InstitutionsDropdown from "./components/layout/institutionsDropdown/InstitutionsDropdown";
import { PrivateRoute } from "./components/routes/PrivateRoute";
import LogoAxReg from "./assets/imgs/AxAgendamento.svg";
import IconAxReg from "./assets/imgs/AxReg_icon.svg";
import HelpButtonFloat from "./components/layout/HelpButtonFloat";

const { Header } = Layout;

class App extends Component {
  constructor(props) {
    super(props);

    const currentPath = window.location.pathname;

    this.state = {
      collapsed: false,
      broken: false,
      institutionDropdownArrow: 'icon-arrow-down',
      selectedMenu: currentPath,
      isOpen: false,
    };
  }

  render() {
    let cont = 0;
    const generatePrivateRoute = (route) => {
      if (route.sub && route.sub.length) {
        return route.sub.map(subRoute => generatePrivateRoute(subRoute));
      }
      cont++;
      return <PrivateRoute key={ cont } exact={ route.exact } path={ route.to } component={ route.component }/>
    };

    return (
      <BrowserRouter>

        <Layout style={ { minHeight: '100vh', padding: 0 } }>

          <Header style={ { position: 'fixed', zIndex: 1024, width: '100%', padding: '0 17px' } }>
            <Row type="flex" justify="start">
              <Col xs={ 0 } xl={ 6 }>
                <img src={ LogoAxReg } alt='Logo AxReg' height={ 50 }/>
              </Col>
              <Col xs={ 2 } xl={ 0 }>
                <img src={ IconAxReg } alt='Logo AxReg' height={ 50 }/>
              </Col>
              <Col xs={ 22 } xl={ 18 }><InstitutionsDropdown/></Col>
            </Row>
          </Header>

          <Layout>
            <AppMenu selectedMenu={ this.state.selectedMenu }
                     onBreakpoint={ (broken) => this.setState({ broken }) }
                     onCollapse={ (collapsed) => this.setState({ collapsed }) }/>
            <Layout className="main" style={ { paddingLeft: this.state.broken ? '20px' : '245px' } }>
              <Col xs={ 0 } sm={ 24 } style={ { paddingTop: '34px' } }/>
              <Col xs={ 24 } sm={ 0 } style={ { paddingTop: '10px' } }/>
              <Switch>
                {
                  routes.app.map((route) => {
                    if (route.component || route.sub) {
                      return generatePrivateRoute(route);
                    }
                  })
                }
              </Switch>

              <HelpButtonFloat/>

              <AppFooter style={ { flexGrow: 0 } }/>

            </Layout>
          </Layout>
        </Layout>
      </BrowserRouter>
    );
  }
}


function mapStateToProps(state) {
  const { institutions, authentication } = state;
  const { user } = authentication;
  return {
    user,
    institutions
  };
}

const connected = connect(mapStateToProps)(App);

export default connected;
