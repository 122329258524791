import mixpanel from 'mixpanel-browser';
import {logService} from './log.service';

mixpanel.init('ca797dd7d649108053cc307bf5fbdfc9');

export const mixPanelService = {
  tracking,
  LOGIN: 'LOGIN',
  MENU_MAPA: 'MENU_MAPA',
  MAPA_ADD_PRE: 'MAPA_ADD_PRE',
  MAPA_ADD_TRANS: 'MAPA_ADD_TRANS',
  MAPA_IMPORTAR_PROCEDIMENTO: 'MAPA_IMPORTAR_PROCEDIMENTO',
  MAPA_SELECIONAR_PRE: 'MAPA_SELECIONAR_PRE',
  MAPA_SELECIONAR_TRANS: 'MAPA_SELECIONAR_TRANS',
  MAPA_ABRIR_AGENDAMENTO: 'MAPA_ABRIR_AGENDAMENTO',
  MAPA_MUDAR_DATA: 'MAPA_MUDAR_DATA',
  MAPA_BUSCA_PACIENTE: 'MAPA_BUSCA_PACIENTE',
  MENU_PACIENTE: 'MENU_PACIENTE',
  PACIENTE_ABRIR: 'PACIENTE_ABRIR',
  PACIENTE_EDITAR: 'PACIENTE_EDITAR',
  PACIENTE_SALVAR: 'PACIENTE_SALVAR',
  PACIENTE_CRIAR: 'PACIENTE_CRIAR',
  PACIENTE_BUSCAR: 'PACIENTE_BUSCAR',
  MENU_ATENDIMENTO: 'MENU_ATENDIMENTO',
  ATENDIMENTO_SELECIONAR_PRE: 'ATENDIMENTO_SELECIONAR_PRE',
  ATENDIMENTO_SELECIONAR_TRANS: 'ATENDIMENTO_SELECIONAR_TRANS',
  ATENDIMENTO_SELECIONAR_POS: 'ATENDIMENTO_SELECIONAR_POS',
  ATENDIMENTO_BAIXAR_PDF: 'ATENDIMENTO_BAIXAR_PDF',
  ATENDIMENTO_FILTRAR_ANESTESISTAS: 'ATENDIMENTO_FILTRAR_ANESTESISTAS',
  MENU_FARMACO: 'MENU_FARMACO',
  FARMACO_CONFIGURAR: 'FARMACO_CONFIGURAR',
  FARMACO_SALVAR: 'FARMACO_SALVAR',
  MENU_CIRUGIAO: 'MENU_CIRUGIAO',
  CIRUGIAO_SALVAR: 'CIRUGIAO_SALVAR',
  CIRUGIAO_EDITAR: 'CIRUGIAO_EDITAR',
  MENU_SETOR_SALA: 'MENU_SETOR_SALA',
  SETOR_ADD: 'SETOR_ADD',
  SETOR_SALA_ADD: 'SETOR_SALA_ADD',
  SETOR_EDITAR: 'SETOR_EDITAR',
  SETOR_SALA_EDITAR: 'SETOR_SALA_EDITAR',
  SETOR_REMOVER: 'SETOR_REMOVER',
  SETOR_SALA_REMOVER: 'SETOR_SALA_REMOVER',
  MENU_CONVENIO: 'MENU_CONVENIO',
  CONVENIO_ADD: 'CONVENIO_ADD',
  CONVENIO_EDITAR: 'CONVENIO_EDITAR',
  CONVENIO_REMOVER: 'CONVENIO_REMOVER',
  MENU_MATERIAL: 'MENU_MATERIAL',
  MATERIAL_IMPORTAR: 'MATERIAL_IMPORTAR',
  MATERIAL_ADD: 'MATERIAL_ADD',
  MATERIAL_EDITAR: 'MATERIAL_EDITAR',
  MATERIAL_REMOVER: 'MATERIAL_REMOVER',
  MENU_EQUIPE_AUXILIAR: 'MENU_EQUIPE_AUXILIAR',
  EQUIPE_AUXILIAR_ADD: 'EQUIPE_AUXILIAR_ADD',
  EQUIPE_AUXILIAR_EDITAR: 'EQUIPE_AUXILIAR_EDITAR',
  MENU_QRCODE: 'MENU_QRCODE',
  QRCODE_ADD: 'QRCODE_ADD',
  QRCODE_EDITAR: 'QRCODE_EDITAR',
  QRCODE_REMOVER: 'QRCODE_REMOVER',
  MENU_USUARIO: 'MENU_USUARIO',
  USUARIO_NOVO_CONVITE: 'USUARIO_NOVO_CONVITE',
};

async function tracking(event) {
  const selected = JSON.parse(localStorage.getItem('selected-institution'));
  const user = JSON.parse(localStorage.getItem('user'));

  if (selected) {
    const {user_id, institution_id} = selected;
    await logService.saveUserLog(user_id, institution_id, event)
  
    if (user.type !== 'AD' ) {
      console.log('tracking', event);
      mixpanel.track(`MANAGER_${event}`, {
        USER_ID: selected.user_id,
        institution_id: selected.id || selected.institution_id,
        group_id: selected.group_id || null,
      });
    }
  }
}
