import React, { Component } from "react";
import { ExcelRenderer } from "react-excel-renderer";
import { connect } from "react-redux";
import {
  Upload,
  Icon,
  Modal,
  Button,
  Tag,
  Table,
  Row,
  Select,
  Popover,
  Steps,
  Spin,
  notification,
  Switch,
  Card,
  Col,
  Progress,
  Statistic,
  List,
  Divider,
  Tooltip,
  Collapse,
  Popconfirm,
  Dropdown,
  Menu,
  Avatar,
  Form,
} from 'antd';
import { listColumnsImport } from '../helpers/columnsImport';
import { importService } from '../services/import.service';
import { numbers, stringsHelp } from '../helpers';
import { IconTabPreSvg, IconTabProcedureSvg } from '../helpers/icons';
import { mixPanelService } from '../services/mixpanel.service';
import Papa from 'papaparse';

import moment from "moment";

const Dragger = Upload.Dragger;
const { Option, OptGroup } = Select;
const Step = Steps.Step;
const Panel = Collapse.Panel;

class ImportExcel extends Component {
  state = {
    loadingImports: false,
    dataSourceImports: [],
    modalVisible: false,
    columnsImport: [],
    dataImportTotal: [],
    dataSubmit: [],
    dataError: [],
    existError: false,
    dataImport: [],
    totalData: 0,
    columnsRef: [],
    optionSelect: [],
    fileName: "",
    StepCurrent: 0,
    loadingPlan: false,
    titleFirst: true,
    loadingImport: false,
    fieldSelects: [],
    isConfigTableStandard: false,
    selectedInstitution: null,
    addDropdownArrow: "down"
  };

  handleCancel = () => {
    this.setState({
      loadingImports: false,
      modalVisible: false,
      columnsImport: [],
      dataImportTotal: [],
      dataSubmit: [],
      dataError: [],
      existError: false,
      dataImport: [],
      totalData: 0,
      columnsRef: [],
      optionSelect: [],
      fileName: "",
      StepCurrent: 0,
      loadingPlan: false,
      titleFirst: true,
      loadingImport: false,
      fieldSelects: [],
      dataBlankLine: [],
      isConfigTableStandard: false,
      addDropdownArrow: "down"
    });

    setTimeout(() => {
      this.setState({ selectedInstitution: null });
    }, 1000);
  };

  handleFinish = () => {
    this.setState({
      modalVisible: false,
      columnsImport: [],
      dataImportTotal: [],
      dataSubmit: [],
      dataError: [],
      existError: false,
      dataImport: [],
      totalData: 0,
      columnsRef: [],
      optionSelect: [],
      fileName: "",
      StepCurrent: 0,
      loadingPlan: false,
      titleFirst: true,
      loadingImport: false,
      fieldSelects: [],
      dataBlankLine: [],
      isConfigTableStandard: false,
      addDropdownArrow: "down"
    });

    setTimeout(() => {
      this.setState({ selectedInstitution: null });
    }, 1000);

    this.props.reload();
  };

  loadImports = key => {
    if (key.length) {
      this.setState({ loadingImports: true });

      const getUrl = listColumnsImport.getColumns(this.props.table).getUrl;
      let promiseSave;
      promiseSave = importService.getImports(getUrl).then(result => {
        this.setState({
          loadingImports: false,
          dataSourceImports: result
        });
      });

      promiseSave.catch(error => {
        console.log(error);
        notification.error({
          message: "Falha ao realizar importação, tente novamente mais tarde!"
        });
      });
    } else {
      this.setState({
        loadingImports: false
      });
    }
  };

  addArrowChange = a => {
    if (a) {
      this.setState({ addDropdownArrow: "up" });
    } else {
      this.setState({ addDropdownArrow: "down" });
    }
  };

  revertImport = import_id => {
    if (import_id) {
      this.setState({ loadingImports: true });

      const postRevert = listColumnsImport.getColumns(this.props.table)
        .postRevert;
      let promiseSave;

      promiseSave = importService
        .postRevert(postRevert, import_id)
        .then(result => {
          notification.success({
            message: "Importação desativada com sucesso!"
          });

          this.loadImports([1]);
          this.props.reload();
        });

      promiseSave.catch(error => {
        console.log(error);
        this.setState({ loadingImports: false });
        notification.error({
          message: "Falha ao realizar importação, tente novamente mais tarde!"
        });
      });
    }
  };

  showModal = () => {
    const { type } = this.props;

    if (type === 'materials') {
      mixPanelService.tracking(mixPanelService.MATERIAL_IMPORTAR);
    }

    if (this.props.table) {
      this.setState({
        modalVisible: true,
        optionSelect: listColumnsImport.getColumns(this.props.table).columns
      });

      this.loadImports([1]);
    }
  };

  showModalMap = (selected) => {
    const { type } = this.props;

    if (type === 'map') {
      mixPanelService.tracking(mixPanelService.MAPA_IMPORTAR_PROCEDIMENTO);
    }

    this.props.setTableImport(selected);

    setTimeout(() => {
      console.log(this.props.table);

      this.showModal();
    }, 300);
  };

  checkInstitution(institution) {
    if (this.props.table) {
      this.setState({
        selectedInstitution: institution,
        modalVisible: true,
        optionSelect: listColumnsImport.getColumns(this.props.table).columns
      });

      this.loadImports([1]);
    }
  }

  fileHandler = file => {
    this.setState({ loadingPlan: true });

    try {

        //just pass the fileObj as parameter
        ExcelRenderer(file, (err, resp) => {
          this.setState({ loadingPlan: false });

          if (err) {
            console.log(err);
            notification.error({
              message:
                "Falha ao importar planilha, informe ao suporte técnico!",
              duration: null
            });
          } else {
            let newRows = [];
            let newBlankLines = [];

            if (file.type === "text/csv") {
              const self = this;

              Papa.parse(file, {
                complete: function(results) {
                  newRows = results.data.filter(x => Object.keys(x).length > 0 && x[0].length > 0); // blank lines ignore
                  newBlankLines = results.data.filter(
                    x => Object.keys(x).length === 0 || x[0].length === 0
                  ); // blank lines ignore

                  self.setState({
                    columnsImport: resp.cols,
                    dataImport: newRows.slice(0, 5),
                    dataImportTotal: newRows,
                    dataBlankLine: newBlankLines,
                    totalData: self.state.titleFirst
                      ? newRows.length - 1
                      : newRows.length,
                    fileName: file.name,
                    columnsRef: Array(resp.cols.length).fill(null),
                    StepCurrent: 1
                  });
                  
                }
              });

            } else {
              newRows = resp.rows.filter(x => Object.keys(x).length > 0); // blank lines ignore
              newBlankLines = resp.rows.filter(
                x => Object.keys(x).length === 0
              ); // blank lines ignore

              this.setState({
                columnsImport: resp.cols,
                dataImport: newRows.slice(0, 5),
                dataImportTotal: newRows,
                dataBlankLine: newBlankLines,
                totalData: this.state.titleFirst
                  ? newRows.length - 1
                  : newRows.length,
                fileName: file.name,
                columnsRef: Array(resp.cols.length).fill(null),
                StepCurrent: 1
              });
            }
            
          }
        });
      
    } catch (error) {
      notification.error({
        message: "Falha ao importar planilha, informe ao suporte técnico!",
        duration: null
      });
    }

    return false;
  };

  selectChange = (value, index) => {
    const newOptions = this.state.optionSelect.filter(function(obj) {
      return obj.name !== value;
    });
    const newColumns = this.state.columnsRef;
    newColumns[index] = listColumnsImport
      .getColumns(this.props.table)
      .columns.find(x => x.name === value);

    let newFieldSelects = this.state.fieldSelects;
    newFieldSelects.push(index);

    this.setState({
      optionSelect: newOptions,
      columnsRef: newColumns,
      fieldSelects: newFieldSelects
    });
  };

  onCloseTag = (value, index) => {
    const newOptions = this.state.optionSelect;
    newOptions.push(
      listColumnsImport
        .getColumns(this.props.table)
        .columns.find(x => x.name === value)
    );
    const newColumns = this.state.columnsRef;
    newColumns[index] = null;
    let newFieldSelects = this.state.fieldSelects;
    let newIndex = newFieldSelects.indexOf(index);
    if (newIndex !== -1) newFieldSelects.splice(newIndex, 1);

    this.setState({
      optionSelect: newOptions,
      columnsRef: newColumns,
      fieldSelects: newFieldSelects
    });
  };

  onSwitch = checked => {
    this.setState({
      titleFirst: checked,
      totalData: checked
        ? this.state.dataImportTotal.length - 1
        : this.state.dataImportTotal.length
    });
  };

  next() {
    const current = this.state.StepCurrent + 1;
    this.setState({ StepCurrent: current });
  }

  prev() {
    const current = this.state.StepCurrent - 1;
    this.setState({ StepCurrent: current });
  }

  clearCPF(cpf = '') {
    const clearCpf = cpf.replace(/\.|-/gm, '');
    return clearCpf.length === 11 ? clearCpf : 'invalid';
  }

  clearSurgeons(surgeons) {
    const setCpf = new Set();
    const surgeonList = surgeons.map((surgeon) => {
      if (!surgeon.cpf) return surgeon;
      surgeon.cpf = this.clearCPF(surgeon.cpf);
      if (setCpf.has(surgeon.cpf)) {
        return null;
      }
      setCpf.add((surgeon.cpf));
      return surgeon;
    });
    return surgeonList.filter((surgeon) => surgeon);
  }

  handleSubmit(objImport, import_name) {
    this.setState({ loadingImport: true, loadingPlan: true });

    const { postUrl } = listColumnsImport.getColumns(this.props.table);
    const objData = listColumnsImport.getColumns(this.props.table).obj_data;
    let promiseSave;

    const customObjImport = {};
    customObjImport.file_name = import_name;
    if (this.state.selectedInstitution) {
      customObjImport.institution_id = this.state.selectedInstitution.id;
    }
    if (listColumnsImport.getColumns(this.props.table).table === 'surgeons') {
      objImport = this.clearSurgeons(objImport);
    }
    customObjImport[objData] = objImport;

    promiseSave = importService
      .postImport(postUrl, customObjImport)
      .then(result => {
        this.setState({
          loadingImport: false,
          loadingPlan: false,
          StepCurrent: 2,
          existError: false,
          totalData: objImport.length
        });
      });

    promiseSave.catch(error => {
      console.log(error);
      notification.error({
        message: "Falha ao realizar importação, tente novamente mais tarde!"
      });
      this.setState({ loadingImport: false, loadingPlan: false });
    });
  }

  handleIgnoreImport() {
    if (this.state.dataImport.length > this.state.dataError.length) {
      let newArraySubmit = this.state.dataSubmit;

      this.state.dataError.map(valueRef => {
        const indexRemove = valueRef.line - 2;
        newArraySubmit.splice(indexRemove, 1);
        return valueRef;
      });

      this.handleSubmit(newArraySubmit, this.state.fileName);
    } else {
      notification.error({
        message:
          "Ops, não existe linhas sem falhas, corrija sua tabela e tente novamente!"
      });
    }
  }

  handleImport() {
    if (this.validRequired()) {
      this.configDataImport();
    } else {
      notification.error({
        message:
          "Selecione os campos de referência (Obrigatórios) para prosseguir com a importação!"
      });
    }
  }

  parseTypeField(type) {
    let result = undefined;
    switch (type) {
      case "string":
        result = "Campo texto";
        break;
      case "integer":
        result = "Número inteiro";
        break;
      case "float":
        result = "Número moeda - ex: (08,50)";
        break;
      case "date":
        result = "Data __/__/____";
        break;
      case "datetime":
        result = "Data e hora __/__/____ __:__";
        break;
      default:
        break;
    }
    return result;
  }

  formatFieldValue(arrayRef, value, indexRef) {
    let newValue = null;

    switch (arrayRef.type) {
      case "string":
        newValue = value[indexRef]
          ? stringsHelp.removeSpecialCharacter(
              stringsHelp.maxLength255(value[indexRef]).toString()
            )
          : "";
        break;
      case "integer":
        newValue = isNaN(value[indexRef])
          ? null
          : parseInt(value[indexRef].trim());
        break;
      case "float":
        newValue = value[indexRef]
          ? numbers.numberToDoubleDecimal(value[indexRef])
          : null;
        break;
      case "date":
        if (
          moment(
            value[indexRef].trim().toString(),
            "DD/MM/YYYY",
            true
          ).isValid()
        ) {
          newValue = moment(
            value[indexRef].trim().toString(),
            "DD/MM/YYYY"
          ).format();
        } else {
          throw Error("");
        }
        break;
      case "datetime":
        if (
          moment(
            value[indexRef].trim().toString(),
            "DD/MM/YYYY HH:mm",
            true
          ).isValid()
        ) {
          newValue = moment(
            value[indexRef].trim().toString(),
            "DD/MM/YYYY HH:mm"
          ).format();
        } else {
          throw Error("");
        }
        break;
      default:
        break;
    }

    return newValue;
  }

  configDataImport() {
    try {
      let newArrayData = [];
      let dataError = [];

      const arrayTables = listColumnsImport.getColumns(this.props.table)
        .columns;

      this.state.dataImportTotal.forEach((value, index) => {
        let newObjData = {};

        if (this.state.titleFirst && index === 0) {
          return true;
        }

        this.state.columnsRef.map((valueRef, indexRef) => {
          if (valueRef) {
            const arrayRef = arrayTables.filter(
              x => x.name === valueRef.name
            )[0];

            if (arrayRef.required) {
              if (value[indexRef]) {
                try {
                  newObjData[valueRef.name] = this.formatFieldValue(
                    arrayRef,
                    value,
                    indexRef
                  ); // receiver value
                } catch (error) {
                  dataError.push({
                    column: arrayRef.label,
                    line: this.state.titleFirst ? index + 1 : index,
                    msg: "Valor inválido para o tipo da coluna",
                    extra: this.parseTypeField(arrayRef.type)
                  });
                }
              } else {
                dataError.push({
                  column: valueRef.label,
                  line: this.state.titleFirst ? index + 1 : index,
                  msg: "Valor em branco na coluna",
                  extra: "(*) Obrigatório"
                });
              }
            } else {
              try {
                newObjData[valueRef.name] = this.formatFieldValue(
                  arrayRef,
                  value,
                  indexRef
                ); // receiver value
              } catch (error) {
                dataError.push({
                  column: arrayRef.label,
                  line: this.state.titleFirst ? index + 1 : index,
                  msg: "Valor inválido para o tipo da coluna",
                  extra: this.parseTypeField(arrayRef.type)
                });
              }
            }
          }
          return valueRef;
        });

        newArrayData.push(newObjData);
      });
      this.setState(
        {
          dataSubmit: newArrayData,
          dataError: dataError,
          loadingImport: true,
          loadingPlan: true
        },
        () => {
          console.log("newArrayData", this.state.dataSubmit);

          if (this.state.dataError.length) {
            setTimeout(() => {
              this.setState({
                loadingImport: false,
                loadingPlan: false,
                StepCurrent: 2,
                existError: true
              });
            }, 1000);
          } else {
            this.handleSubmit(this.state.dataSubmit, this.state.fileName);
          }
        }
      );
    } catch (error) {
      console.log("ERROR", error);

      notification.error({
        message: "Falha ao importar planilha, informe ao suporte técnico!",
        duration: null
      });

      return false;
    }
  }

  validRequired() {
    const arrayTables = listColumnsImport.getColumns(this.props.table).columns;
    const arrayRequired = arrayTables.filter(x => x.required === true);
    const arrayRef = this.state.columnsRef.filter(x => x !== null);
    let result = true;

    arrayRequired.map(value => {
      if (arrayRef.filter(e => e.name === value.name).length === 0) {
        result = false;
      }
      return value;
    });

    return result;
  }

  componentDidMount() {
    /* if (this.props.table) {
      this.setState({
        optionSelect: listColumnsImport.getColumns(this.props.table).columns
      });
    }*/
  }

  render() {
    const { institutions } = this.props;
    const props = {
      name: "file",
      multiple: false,
      accept: ".xlsx, .xls, .csv, .xlt",
      showUploadList: false,
      beforeUpload: file => this.fileHandler(file)
    };

    const steps = [
      {
        title: "Selecione a planilha",
        icon: "cloud-upload"
      },
      {
        title: "Configure as referências",
        icon: "api"
      },
      {
        title: "Conclusão",
        icon: "check-circle"
      }
    ];

    const columnsTableImports = [
      {
        title: "Nome do arquivo",
        dataIndex: "file_name",
        key: "file_name"
      },
      {
        title: "Data",
        dataIndex: "createdAt",
        key: "createdAt",
        render: createdAt => (
          <span>{moment(createdAt).format("DD/MM/YYYY HH:mm")}</span>
        )
      },
      {
        title: "Status",
        dataIndex: "active",
        key: "active",
        width: 80,
        render: (active, record) => (
          <span>
            {active ? (
              <Popconfirm
                placement="top"
                title="Confirma desativar essa importação ?"
                onConfirm={() => this.revertImport(record.id)}
                okText="Sim"
                cancelText="Não"
              >
                <Tag
                  style={{
                    color: "#ffffff",
                    background: "#1bbfbb",
                    borderColor: "#1bbfbb",
                    cursor: "pointer"
                  }}
                >
                  {" "}
                  Desativar
                </Tag>
              </Popconfirm>
            ) : (
              <Tag>Inativo</Tag>
            )}
          </span>
        )
      }
    ];

    const menuAdd = (
      <Menu onClick={e => this.showModalMap(e.key)}>
        {/* <Menu.Item key="surgical-map-pre">
          <Icon component={IconTabPreSvg} />
          &nbsp;&nbsp;PRÉ-ANESTÉSICO&nbsp;&nbsp;
        </Menu.Item> */}
        <Menu.Item key="surgical-map">
          <Icon component={IconTabProcedureSvg} />
          &nbsp;&nbsp;PROCEDIMENTO&nbsp;&nbsp;
        </Menu.Item>
      </Menu>
    );

    return (
      <span style={{ marginLeft: 5, marginRight: 5 }}>
        {this.props.type && this.props.type === "map" ? (
          <Dropdown overlay={menuAdd} onVisibleChange={this.addArrowChange}>
            <Button type="dashed" shape="round" icon="file-excel">
              IMPORTAR &nbsp;
              <Icon type={this.state.addDropdownArrow} />
            </Button>
          </Dropdown>
        ) : (
          <Button
            type="dashed"
            shape="round"
            icon="file-excel"
            onClick={this.showModal}
          >
            IMPORTAR
          </Button>
        )}

        <Modal
          visible={this.state.modalVisible}
          title={
            <div>
              {" "}
              {JSON.parse(localStorage.getItem("selected-institution")) &&
              JSON.parse(localStorage.getItem("selected-institution"))
                .is_group && !this.props.restrictGroup &&
              !this.state.selectedInstitution ? (
                <span>Selecione a instituição</span>
              ) : (
                <span>Importar planilha ({this.props.title})</span>
              )}
            </div>
          }
          bodyStyle={{ paddingTop: "21px" }}
          className={
            JSON.parse(localStorage.getItem("selected-institution")) &&
            JSON.parse(localStorage.getItem("selected-institution")).is_group && !this.props.restrictGroup  &&
            !this.state.selectedInstitution
              ? ""
              : "container-modal"
          }
          zIndex={1024}
          footer={[
            <span key="0001">
              {(this.state.StepCurrent === 0 ||
                this.state.StepCurrent === 1) && (
                <Button
                  key="cancel"
                  type="default"
                  onClick={this.handleCancel}
                  style={{ textAlign: "left" }}
                >
                  Cancelar
                </Button>
              )}
            </span>,
            <span key="0002">
              {this.state.StepCurrent === 1 && (
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => this.handleImport()}
                  loading={this.state.loadingImport}
                  style={{ marginLeft: 8 }}
                >
                  Importar dados
                </Button>
              )}
            </span>,
            <span key="0003">
              {this.state.StepCurrent === 2 && this.state.existError && (
                <span>
                  <Button
                    key="cancel"
                    type="default"
                    onClick={this.handleCancel}
                    style={{ textAlign: "left" }}
                  >
                    Cancelar
                  </Button>{" "}
                  <Button
                    key="submit"
                    type="dashed"
                    onClick={() => this.handleIgnoreImport()}
                    loading={this.state.loadingImport}
                    style={{
                      marginLeft: 8,
                      color: "#1bbfbb",
                      borderColor: "#1bbfbb"
                    }}
                  >
                    Ignorar falhas e continuar importação
                  </Button>
                </span>
              )}
            </span>,
            <span key="0004">
              {this.state.StepCurrent === 2 && !this.state.existError && (
                <Button
                  key="submit"
                  type="primary"
                  onClick={this.handleFinish}
                  style={{ marginLeft: 8 }}
                >
                  Ok
                </Button>
              )}
            </span>
          ]}
        >
          {JSON.parse(localStorage.getItem("selected-institution")) &&
          JSON.parse(localStorage.getItem("selected-institution")).is_group && !this.props.restrictGroup &&
          !this.state.selectedInstitution ? (
            <Menu>
              {JSON.parse(localStorage.getItem("selected-institution")) &&
              institutions.items &&
              institutions.items.size > 1
                ? institutions.items
                    .filter(
                      i =>
                        i.id !==
                        JSON.parse(localStorage.getItem("selected-institution"))
                          .id
                    )
                    .map(institution => {
                      return (
                        <Menu.Item
                          key={institution.id}
                          onClick={e => this.checkInstitution(institution)}
                        >
                          <div className="ant-list-item-meta ant-group check-institution">
                            <div className="ant-list-item-meta-avatar">
                              <Avatar
                                shape="square"
                                src={
                                  institution.logo_url
                                    ? institution.logo_url
                                    : null
                                }
                                className="institution-logo"
                              >
                                Logo
                              </Avatar>
                            </div>
                            <div className="ant-list-item-meta-content">
                              <h4
                                className="ant-list-item-meta-title"
                                style={{ marginBottom: -5 }}
                              >
                                {stringsHelp.firstLetterUpper(
                                  institution.name,
                                  true
                                )}
                              </h4>
                            </div>
                          </div>
                        </Menu.Item>
                      );
                    })
                : ""}
            </Menu>
          ) : (
            <div>
              {this.state.selectedInstitution && (
                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-form-item-label">
                    <label
                      className="ant-form-item-no-colon"
                      title="INSTITUIÇÃO"
                    >
                      INSTITUIÇÃO
                    </label>
                  </div>

                  <div
                    className="ant-list-item-meta ant-group"
                    style={{ marginTop: 10 }}
                  >
                    <div className="ant-list-item-meta-avatar">
                      <Avatar
                        shape="square"
                        src={
                          this.state.selectedInstitution.logo_url
                            ? this.state.selectedInstitution.logo_url
                            : null
                        }
                        className="institution-logo"
                      >
                        Logo
                      </Avatar>
                    </div>
                    <div className="ant-list-item-meta-content">
                      <h4
                        className="ant-list-item-meta-title"
                        style={{ marginBottom: -5 }}
                      >
                        {stringsHelp.formatName(
                          this.state.selectedInstitution.name
                        )}
                      </h4>
                    </div>
                  </div>
                </div>
              )}

              <Steps current={this.state.StepCurrent}>
                {steps.map(item => (
                  <Step
                    key={item.title}
                    title={item.title}
                    icon={<Icon type={item.icon} />}
                  />
                ))}
              </Steps>

              {this.state.StepCurrent === 0 && (
                <Spin size="large" spinning={this.state.loadingPlan}>
                  <Row style={{ marginTop: 20 }}>
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon" key="0011">
                        <Icon type="file-excel" />
                      </p>
                      <p className="ant-upload-text" key="0012">
                        Clique ou arraste o arquivo para esta área para fazer
                        importação
                      </p>
                      <div className="ant-upload-hint" key="0013">
                        Suporte para planilhas EXCEL E CSV <Tag>.xls</Tag>{" "}
                        <Tag>.xlsx</Tag> <Tag>.csv</Tag>
                      </div>
                    </Dragger>
                  </Row>
                  <Row style={{ marginTop: 20 }}>
                    <Collapse
                      expandIconPosition="right"
                      style={{
                        cursor: "pointer",
                        borderRadius: 10,
                        boxShadow: "0 4px 14px 1px rgba(0, 0, 0, 0.04)",
                        border: "1px solid #d9d9d96b"
                      }}
                    >
                      <Panel
                        forceRender={true}
                        header={
                          <span>
                            <Icon type="history" /> Importações anteriores{" "}
                          </span>
                        }
                        key="1"
                      >
                        <Table
                          rowKey="file_name"
                          size="midle"
                          loading={this.state.loadingImports}
                          dataSource={this.state.dataSourceImports}
                          columns={columnsTableImports}
                        />
                      </Panel>
                    </Collapse>
                  </Row>
                </Spin>
              )}

              {this.state.StepCurrent === 1 && (
                <Spin size="large" spinning={this.state.loadingPlan}>
                  <Row style={{ marginTop: 10 }}>
                    {this.state.columnsImport.length ? (
                      <div>
                        <Card
                          title={
                            <span>
                              <Icon type="paper-clip" /> {this.state.fileName}
                            </span>
                          }
                          extra={
                            <span>
                              {" "}
                              <small>Título na primeira linha?</small>
                              <br />
                              <Switch
                                style={{ float: "right" }}
                                defaultChecked
                                onChange={e => this.onSwitch(e)}
                              />
                            </span>
                          }
                          style={{ width: "100%" }}
                          className="card-import"
                        >
                          <div className="table-wrapper">
                            <table className="tb-custom">
                              <thead>
                                <tr key={1}>
                                  <th key={0} />
                                  {this.state.columnsImport.map(
                                    (valueTH, indexTH) => {
                                      return (
                                        <th key={indexTH}>{valueTH.name}</th>
                                      );
                                    }
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.dataImport.map(
                                  (valueData, indexData) => {
                                    return (
                                      <tr
                                        key={indexData}
                                        className={
                                          indexData === 0 &&
                                          this.state.titleFirst
                                            ? "table-title"
                                            : ""
                                        }
                                      >
                                        <td
                                          key={indexData}
                                          className={
                                            indexData === 0 &&
                                            this.state.titleFirst
                                              ? "td-title"
                                              : ""
                                          }
                                        >
                                          {indexData + 1}
                                        </td>
                                        {this.state.columnsImport.map(
                                          (valueTD, indexTD) => {
                                            return (
                                              <td
                                                key={indexTD}
                                                className={
                                                  this.state.fieldSelects.indexOf(
                                                    indexTD
                                                  ) > -1
                                                    ? ""
                                                    : "td-not-selected"
                                                }
                                              >
                                                {valueData[indexTD]}
                                              </td>
                                            );
                                          }
                                        )}
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <th key={0} />
                                  {this.state.columnsRef.map(
                                    (valueTF, indexTF) => {
                                      return (
                                        <th key={indexTF}>
                                          {valueTF ? (
                                            <Tag
                                              color="#36a2eb"
                                              closable
                                              onClose={e =>
                                                this.onCloseTag(
                                                  valueTF.name,
                                                  indexTF
                                                )
                                              }
                                            >
                                              {valueTF.label}
                                            </Tag>
                                          ) : (
                                            <Popover
                                              content={
                                                <Select
                                                  style={{ width: 300 }}
                                                  onChange={e =>
                                                    this.selectChange(
                                                      e,
                                                      indexTF
                                                    )
                                                  }
                                                  getPopupContainer={triggerNode =>
                                                    triggerNode.parentNode
                                                  }
                                                  autoFocus={true}
                                                >
                                                  <OptGroup label="Obrigatório">
                                                    {this.state.optionSelect
                                                      .filter(v => v.required)
                                                      .map(d => (
                                                        <Option key={d.name}>
                                                          {d.label}{" "}
                                                          <Divider type="vertical" />{" "}
                                                          <Tooltip
                                                            title={this.parseTypeField(
                                                              d.type
                                                            )}
                                                          >
                                                            <Icon type="question-circle" />
                                                          </Tooltip>
                                                        </Option>
                                                      ))}
                                                  </OptGroup>
                                                  <OptGroup label="(Não) Obrigatório">
                                                    {this.state.optionSelect
                                                      .filter(v => !v.required)
                                                      .map(d => (
                                                        <Option key={d.name}>
                                                          {d.label}{" "}
                                                          <Divider type="vertical" />{" "}
                                                          <Tooltip
                                                            title={this.parseTypeField(
                                                              d.type
                                                            )}
                                                          >
                                                            <Icon type="question-circle" />
                                                          </Tooltip>
                                                        </Option>
                                                      ))}
                                                  </OptGroup>
                                                </Select>
                                              }
                                              title="Campo de referência"
                                              trigger="click"
                                            >
                                              <Tag
                                                style={{
                                                  background: "#fff",
                                                  borderStyle: "dashed"
                                                }}
                                              >
                                                <Icon type="api" /> Referência
                                              </Tag>{" "}
                                            </Popover>
                                          )}
                                        </th>
                                      );
                                    }
                                  )}
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </Card>

                        <div style={{ marginTop: 15, color: "#343f5c" }}>
                          <Icon
                            style={{
                              fontSize: 20,
                              verticalAlign: "middle",
                              color: "#faad14"
                            }}
                            type="exclamation-circle"
                          />{" "}
                          <Tag
                            color="gold"
                            style={{
                              color: "#343f5c",
                              background: "#ffe58f47"
                            }}
                          >
                            {" "}
                            {"Colunas sem referência não serão importadas."}
                          </Tag>
                          <span style={{ float: "right" }}>
                            {this.state.dataBlankLine.length ? (
                              <Tooltip
                                title={
                                  "Foi identificado (" +
                                  this.state.dataBlankLine.length +
                                  ") linha(s) em branco que serão ignorada(s) automaticamente."
                                }
                              >
                                <Icon
                                  type="exclamation-circle"
                                  style={{ color: "red" }}
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )}{" "}
                            Total de linhas: <Tag>{this.state.totalData}</Tag>
                          </span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </Row>
                </Spin>
              )}

              {this.state.StepCurrent === 2 && (
                <Row style={{ marginTop: 30 }}>
                  {!this.state.existError ? (
                    <Col span={24} style={{ textAlign: "center" }}>
                      <Progress type="circle" percent={100} />
                      <h2 style={{ marginTop: 10, color: "#343f5c" }}>
                        Importação realizada com sucesso!
                      </h2>
                      <Statistic
                        style={{ marginTop: 20 }}
                        title="Linhas importadas"
                        value={this.state.totalData}
                        groupSeparator="."
                        prefix={<Icon type="cloud-upload" />}
                      />
                    </Col>
                  ) : (
                    <Spin size="large" spinning={this.state.loadingPlan}>
                      <Col span={24}>
                        <h2 style={{ marginTop: 10, textAlign: "center" }}>
                          <span style={{ color: "red" }}>
                            {" "}
                            <Icon type="stop" />{" "}
                          </span>{" "}
                          <span style={{ color: "#343f5c" }}>
                            Ops, identificamos falhas na importação!
                          </span>
                        </h2>
                        <div className="container-list">
                          <List
                            size="small"
                            bordered
                            dataSource={this.state.dataError}
                            renderItem={item => (
                              <List.Item
                                actions={[
                                  <span>Linha</span>,
                                  <Tag>{item.line}</Tag>
                                ]}
                              >
                                <List.Item.Meta
                                  title={
                                    <b>
                                      <Icon type="api" />{" "}
                                      <Divider type="vertical" /> {item.column}
                                    </b>
                                  }
                                  description={
                                    <span style={{ color: "red" }}>
                                      {item.msg} <Divider type="vertical" />{" "}
                                      <Tag color="red">{item.extra}</Tag>{" "}
                                    </span>
                                  }
                                />
                              </List.Item>
                            )}
                          />
                        </div>
                      </Col>
                    </Spin>
                  )}
                </Row>
              )}
            </div>
          )}
        </Modal>
      </span>
    );
  }
}

function mapStateToProps(state) {
  const { institutions } = state;
  return {
    institutions
  };
}

const connected = connect(mapStateToProps)(Form.create()(ImportExcel));
export default connected;
