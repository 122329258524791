import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Col,
  Row,
  Icon,
  Tooltip,
  Form,
  Input,
  Modal,
  Select,
  Button,
  notification,
  Card
} from "antd";

import {
  FloatingMenu,
  MainButton
} from "react-floating-button-menu";
import { CancelToken } from "axios";
import { usersService } from "../../services";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class HelpButtonFloat extends Component {
  state = {
    modalVisible: false,
    loadingSend: false
  };

  cancelSource = CancelToken.source();

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {

      if (!err) {

        let promiseSave;

        values.email = this.props.user.email;
        values.system_version =  'WEB Preset';

        this.setState({ loadingSend: true });

          promiseSave = usersService.postTickets(values).then(result => {
            if (result.id > 0) {
              
              this.handleHelpCancel();

              notification.success({
                message: "Mensagem enviada com sucesso!"
              });
            }
          });

        promiseSave.catch(error => {
          console.log(error);
          this.setState({ loadingSend: false });
          notification.error({
            message: error
          });
        });
      }
    });
  };

  handleHelpCancel = () => {

    const clearHelp= {
      subject: '',
      type: null,
      message: ''
    };

    this.setState({modalVisible: false, loadingSend: false});
    this.props.form.setFieldsValue(clearHelp);
  };

  openHelpModal = () => {

    const clearHelp= {
        subject: '',
        type: null,
        message: ''
      };

      this.setState({modalVisible: true, loadingSend: false});
      this.props.form.setFieldsValue(clearHelp);
      
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 }
      },
      colon: false
    };

    const arrayTypes = [
        {name: 'BUG', text: '(Suporte Técnico) Notificar erro no Sistema, dados inconsistentes, comportamento inesperado'},
        {name: 'HOW_TO', text: '(FAQ) Dúvidas sobre o funcionamento do sistema'}, 
        {name: 'FEATURE', text: '(Sugestão) Dicas sobre novas funcionalidades, ajustes e melhorias'},
        {name: 'SALES', text: '(Comercial) Contato com departamento comercial'}];

    return (
      <div>
        <div className="float-btn">
          <FloatingMenu
            slideSpeed={500}
            direction="up"
            spacing={8}
            isOpen={false}
          >
            <Tooltip title="Precisa de suporte?" placement="left">
              <MainButton
                iconResting={
                  <Icon
                    type="message"
                    style={{ fontSize: 25, color: "white" }}
                  />
                }
                backgroundColor="#36a2eb"
                onClick={() => this.openHelpModal()}
                size={56}
              />
            </Tooltip>
          </FloatingMenu>
        </div>

        <Modal
          visible={this.state.modalVisible}
          onCancel={this.handleHelpCancel}
          title={
            <div>
              <button
                className="modal-close"
                type="button"
                onClick={this.handleHelpCancel}
              >
                <i className="icon-close" />
              </button>
              <span>Enviar mensagem</span>
            </div>
          }
          footer={[
            <Button
              key="submit"
              type="primary"
              form="formSend"
              htmlType="submit"
              loading={this.state.loadingSend}
            >
              Enviar
            </Button>
          ]}
          bodyStyle={{ paddingTop: "21px" }}
          width="701px"
          zIndex={1024}
        >
        <Card style={{marginTop: -20, marginBottom: 20,color: '#343f5c'}}>
          Preencha o formulário abaixo e envie sua mensagem que em breve nossa equipe irá verificar.
        </Card>
          <Form onSubmit={this.handleSubmit} id="formSend">
            <Row gutter={28}>
              <Col md={24} lg={24} xl={24}>

              <FormItem {...formItemLayout} label="CATEGORIA">
                  {getFieldDecorator("type", {
                    rules: [
                      {
                        required: true,
                        message: "Selecione a categoria."
                      }
                    ]
                  })(
                    <Select>
                      {arrayTypes.map(type => {
                            return (
                              <Option key={type.name} value={type.name}>
                                {type.text}
                              </Option>
                            );
                          })}
                    </Select>
                  )}
                </FormItem>

                <FormItem {...formItemLayout} label="ASSUNTO">
                  {getFieldDecorator("subject", {
                    rules: [
                      {
                        required: true,
                        message: "Preencha o assunto."
                      }
                    ]
                  })(<Input />)}
                </FormItem>

                <FormItem {...formItemLayout} label="MENSAGEM">
                  {getFieldDecorator("message", {})(<TextArea rows={4} />)}
                </FormItem>

              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const {institutions, authentication} = state;
  const {user} = authentication;
  return {
    user,
    institutions
  };
}


const connected = withRouter(connect(mapStateToProps)(Form.create()(HelpButtonFloat)));

export default connected;
