import Api from '../helpers/api';

export const institutionService = {
  getInstitutions,
  selectInstitution,
  getInstitutionLogo,
  saveInstitutionLogo,
  associateToGroup,
};

function getInstitutionLogo(id) {
  return Api.get(`/app/institutions/${id}/logo`);
}

function getInstitutions() {
  return Api.get(`/preset/institutions`);
}

function selectInstitution(id) {
  return Api.get(`/manager/refresh-token/institution/${id}`);
}

function saveInstitutionLogo(uuid, file) {
  const formData = new FormData();
  formData.append('institution_logo', file);

  return Api.post(`web/institution/${uuid}/logo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function associateToGroup(institutionId, groupId) {
 return Api.put('/preset/institution_group/associate', {
   institution_id: institutionId,
   group_id: groupId
 })
}
