import Api from '../helpers/api';

export const logService = { saveUserLog, getUserLog }

function saveUserLog(user_id, institution_id, event) {
    return Api.post('/app/user_log', {
        user_id,
        institution_id,
        event
    })
}

function getUserLog() {
    return Api.get('/app/user_log')
}