import Map from "./components/pages/Map";
import Scheduling from "./components/pages/Scheduling";
import SchedulingPre from "./components/pages/SchedulingPre";
import Patients from "./components/pages/Patients";
import Patient from "./components/pages/Patient";
import Surgeons from "./components/pages/Surgeons";
import Surgeon from "./components/pages/Surgeon";
import Sectors from "./components/pages/Sectors";
import MedicalPlans from "./components/pages/MedicalPlans";
import Materials from "./components/pages/Materials";
import MonitorQRCodes from "./components/pages/MonitorQRCodes";
import SurgicalTeams from "./components/pages/SurgicalTeams";
import Users from "./components/pages/Users";
import Drugs from "./components/pages/Drugs";
import Procedures from "./components/pages/Procedures";
import HealthchessLog from "./components/pages/HealthchessLog";
import Institutions from "./components/pages/Institutions";
import Institution from "./components/pages/Institution";
import Log from './components/pages/Log';

export const pathRoutes = {
  login: "/login",
  logout: "/logout",
  institutions: "/institutions",
  editInstitution: "/institutions/editar/:id",
  newInstitution: "/institutions/novo",
  acceptInvite: "/accept-invite/:id",
  validateEmail: "/validate-email/:token",
  forgotPassword: "/forgot-password/:hash_validation",
  home: "/",
  schedules: "/agendamentos",
  newSchedulePatient: "/agendamentos/novo/:patient_id",
  newSchedule: "/agendamentos/novo",
  schedulesPre: "/pre-agendamentos",
  newSchedulePre: "/pre-agendamentos/novo",
  editSchedule: "/agendamentos/editar/:id",
  editSchedulePre: "/pre-agendamentos/editar/:id",
  patients: "/pacientes",
  patients_page: "/pacientes/:page/:filter?",
  newPatient: "/pacientes/novo",
  editPatient: "/pacientes/editar/:id",
  procedures: "/procedures",
  surgeons: "/cirurgioes",
  newSurgeon: "/cirurgioes/novo",
  editSurgeon: "/cirurgioes/editar/:id",
  sectors: "/setores",
  medicalPlans: "/convenios",
  materials: "/materiais",
  monitor_qrcodes: "/monitor-qrcodes",
  surgicalTeams: "/equipes-cirurgicas",
  healthchessLog: "/healthchess-log",
  drugs: "/farmacos",
  users: "/users",
  redirectToApp: "/redirect-to-app",
  log: "/log"
};

export default {
  app: [
    {
      to: pathRoutes.editSchedule,
      component: Scheduling,
      exact: false,
    },
    {
      to: pathRoutes.editSchedulePre,
      component: SchedulingPre,
      exact: false,
    },
    {
      to: pathRoutes.newSchedulePatient,
      component: Scheduling,
      exact: false,
    },
    {
      to: pathRoutes.newSchedule,
      component: Scheduling,
      exact: false,
    },
    {
      to: pathRoutes.newSchedulePre,
      component: SchedulingPre,
      exact: false,
    },
    {
      to: pathRoutes.schedules,
      component: Map,
      exact: false,
      menu: true,
      sub: false,
      description: "Mapa Cirúrgico",
      role: ["AD", "IN", "AN", "PH", "DM"],
    },
    {
      to: pathRoutes.schedulesPre,
      component: Map,
      exact: false,
    },
    {
      to: pathRoutes.newPatient,
      component: Patient,
      exact: false,
    },
    {
      to: pathRoutes.editPatient,
      component: Patient,
      exact: false,
    },
    {
      to: pathRoutes.patients_page,
      component: Patients,
      exact: false,
      menu: false,
    },
    {
      to: pathRoutes.patients,
      component: Patients,
      exact: false,
      menu: true,
      sub: false,
      description: "Pacientes",
      role: ["AD", "IN", "AN", "PH", "DM"],
    },
    {
      to: pathRoutes.procedures,
      component: Procedures,
      exact: false,
      menu: true,
      sub: false,
      description: "Atendimentos",
      role: ["AD", "IN", "AN", "PH", "DM"],
    },
    {
      to: pathRoutes.drugs,
      component: Drugs,
      exact: false,
      menu: true,
      sub: false,
      description: "Fármacos",
      role: ["AD", "IN", "AN", "PH", "DM"],
    },
    {
      to: pathRoutes.newInstitution,
      component: Institution,
      exact: false,
    },
    {
      to: pathRoutes.editInstitution,
      component: Institution,
      exact: false,
    },
    {
      to: pathRoutes.home,
      component: Map,
      exact: true,
      menu: false,
      description: "Mapa Cirúrgico",
      role: ["AD", "IN", "AN", "PH", "DM"],
    },
    {
      to: pathRoutes.newSurgeon,
      component: Surgeon,
      exact: false,
    },
    {
      to: pathRoutes.editSurgeon,
      component: Surgeon,
      exact: false,
    },
    {
      to: pathRoutes.healthchessLog,
      component: HealthchessLog,
      exact: false,
      menu: true,
      checkVisible: (institution, user) => {
        return institution.is_healthchess && user.type === "AD";
      },
      description: "Healthchess Log",
      sub: false,
    },
    {
      menu: true,
      description: "Cadastros Gerais",
      role: ["AD", "IN", "AN", "PH", "DM"],
      sub: [
        {
          to: pathRoutes.surgeons,
          component: Surgeons,
          exact: false,
          menu: true,
          description: "Cirurgiões",
          sub: true,
          role: ["AD", "IN", "AN", "PH", "DM"],
        },
        {
          to: pathRoutes.sectors,
          component: Sectors,
          exact: false,
          menu: true,
          description: "Setores e salas",
          sub: true,
          role: ["AD", "IN", "AN", "PH", "DM"],
        },
        {
          to: pathRoutes.medicalPlans,
          component: MedicalPlans,
          exact: false,
          menu: true,
          description: "Convênios",
          sub: true,
          role: ["AD", "IN", "AN", "PH", "DM"],
        },
        {
          to: pathRoutes.materials,
          component: Materials,
          exact: false,
          menu: true,
          description: "Materiais",
          sub: true,
          role: ["AD", "IN", "AN", "PH", "DM"],
        },
        {
          to: pathRoutes.monitor_qrcodes,
          component: MonitorQRCodes,
          exact: false,
          menu: true,
          not_group: false,
          description: "QRCodes",
          sub: true,
          role: ["AD", "IN", "AN", "PH", "DM"],
        },
        {
          to: pathRoutes.surgicalTeams,
          component: SurgicalTeams,
          exact: false,
          menu: true,
          description: "Equipe auxiliar",
          sub: true,
          role: ["AD", "IN", "AN", "PH", "DM"],
        },
      ],
    },
    {
      to: pathRoutes.users,
      component: Users,
      exact: false,
      menu: true,
      sub: false,
      description: "Usuários",
    },
    {
      menu: true,
      divider: true,
      role: ["AD"],
    },
    {
      to: pathRoutes.institutions,
      component: Institutions,
      exact: true,
      menu: true,
      sub: false,
      description: "Instituições",
      role: ["AD"],
    },
    {
      to: pathRoutes.log,
      component: Log,
      exact: true,
      menu: true,
      sub: false,
      description: "Log de atividades",
      checkVisible: (institution, user) => {
        const userPermissions = JSON.parse(localStorage.getItem("user-permissions"));
        return userPermissions && userPermissions.is_admin;
      },
    }
  ],
};
