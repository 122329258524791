import {sectorsConstants} from '../constants';
import {sectorsService} from "../services";

export const sectorsActions = {
  getSectors,
};

function getSectors(filter, cancelToken) {
  return dispatch => {
    
    dispatch(request());

    return sectorsService.getSectors(filter, cancelToken)
      .then(sectors => {
        console.log(sectors);
        
        dispatch(success(sectors));
        return sectors;
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: sectorsConstants.GETSECTORS_REQUEST}
  }

  function success(data) {
    return {type: sectorsConstants.GETSECTORS_SUCCESS, data}
  }

  function failure(error) {
    return {type: sectorsActions.GETSECTORS_FAILURE, error}
  }
}
