import React, { Component } from "react";

import {
  Layout,
  Row,
  Col,
  Tooltip,
  Icon,
  Table,
  Button,
  Input,
  Tag,
  Modal,
  Form,
  notification,
  Divider,
  Checkbox,
  Switch,
  DatePicker,
  Card,
  Popover
} from "antd";
import { connect } from "react-redux";
import { usersActions } from "../../actions";
import { usersService } from "../../services";
import moment from "moment";
import { CancelToken } from "axios";
import LogoLogBook from "../../assets/imgs/logbook.png";
import LogoLogSba from "../../assets/imgs/logsba.png";
import { stringsHelp } from "../../helpers";
import SelectMultipleInstitutions from '../SelectMultipleInstitutions';
import { mixPanelService } from '../../services/mixpanel.service';

const { Content } = Layout;
const FormItem = Form.Item;
const { Search } = Input;

class Users extends Component {
  state = {
    modalVisible: false,
    loadingSend: false,
    confirmVisible: false,
    confirmLoading: false,
    delete_id: null,
    is_resident: false,
    edit_institution_id: null,
    delete_institution_id: null,
    dataUsers: []
  };

  cancelSource = CancelToken.source();

  load = () => {
    const { dispatch, users } = this.props;

    if (users.loading) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    dispatch(usersActions.getUsers({}, this.cancelSource.token)).then(data => {
      this.setState({
        dataUsers: data
      });
    });
  };

  componentDidMount() {
    mixPanelService.tracking(mixPanelService.MENU_USUARIO);
    this.load();
  }

  handleInviteResend = obj => {
    if (obj) {
      try {
        this.setState({ loadingSend: true });
        let promiseSave;
        let values = {};

        values.email = obj.email.trim();
        values.expiration_date =
          obj.institutions[0].institution_user.expiration_date;
        values.is_anaesthetist =
          obj.institutions[0].institution_user.is_anaesthetist;
        values.is_dashboard = obj.institutions[0].institution_user.is_dashboard;
        values.is_manager = obj.institutions[0].institution_user.is_manager;
        values.is_resident = obj.institutions[0].institution_user.is_resident;

        promiseSave = usersService.postInvite(values).then(result => {
          if (result.id > 0) {
            notification.success({
              message: "Convite reenviado com sucesso!"
            });

            this.setState({ loadingSend: false });
          }
        });

        promiseSave.catch(error => {
          console.log(error);
          this.setState({ loadingSend: false });
          notification.error({
            message:
              "OPS! Falha ao reenviar convite, tente novamente mais tarde!"
          });
        });
      } catch (error) {
        console.log(error);
        this.setState({ loadingSend: false });
        notification.error({
          message:
            "OPS! Erro ao reenviar convite, envie um ticket para suporte técnico!"
        });
      }
    }
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (!values.institution_ids || values.institution_ids.length === 0) {
          values.institution_ids = [JSON.parse(localStorage.getItem('selected-institution')).id];
        }

        values.expiration_date = values.expiration_date
          ? values.expiration_date.endOf("day")
          : null;
        let promiseSave;

        values.is_anaesthetist = values.type.find(x => x === "is_anaesthetist")
          ? 1
          : 0;
        values.is_dashboard = values.type.find(x => x === "is_dashboard")
          ? 1
          : 0;
        values.is_manager = values.type.find(x => x === "is_manager") ? 1 : 0;
        values.is_admin = values.type.find(x => x === "is_admin") ? 1 : 0;

        // verify is resident
        values.is_resident =
          values.is_anaesthetist === 1 && this.state.is_resident ? 1 : 0;

        delete values.type;

        this.setState({ loadingSend: true });

        // new
        if (!values.id) {
          delete values.id;
          values.email = values.email.trim();

          promiseSave = usersService.postInvite(values).then(result => {
            if (result.id > 0) {
              this.handleInviteCancel();

              notification.success({
                message: "Convite enviado com sucesso!"
              });

              this.load();
            }
          });
        }
        //edit
        else {
          const user_id = values.id;
          delete values.id;
          delete values.email;

          promiseSave = usersService.putInvite(user_id, values).then(result => {
            this.handleInviteCancel();

            notification.success({
              message: "Permissão alterada com sucesso!"
            });

            this.load();
          });
        }

        promiseSave.catch(error => {
          console.log(error);
          this.setState({ loadingSend: false });
          notification.error({
            message: 'OPS! Falha ao enviar convite, tente novamente mais tarde!'
          });
        });
      }
    });
  };

  handleInviteCancel = () => {
    const clearInvite = {
      id: null,
      type: null,
      email: "",
      expiration_date: null
    };

    this.setState({
      modalVisible: false,
      loadingSend: false,
      is_resident: false,
      edit_institution_id: null
    });
    this.props.form.setFieldsValue(clearInvite);
  };

  openInviteModal = () => {
    mixPanelService.tracking(mixPanelService.USUARIO_NOVO_CONVITE);

    const clearInvite = {
      id: null,
      type: null,
      email: "",
      expiration_date: null
    };

    this.setState({
      modalVisible: true,
      loadingSend: false,
      is_resident: false
    });
    this.props.form.setFieldsValue(clearInvite);
  };

  openInviteEditModal = user_id => {
    const arrayUser = this.props.users.data.find(x => x.id === user_id);
    let arrayTypes = [];
    let edit_expiration_date = null;

    if (arrayUser.institutions[0].institution_user.is_admin) {
      arrayTypes.push("is_admin");
    }
    if (arrayUser.institutions[0].institution_user.is_anaesthetist) {
      arrayTypes.push("is_anaesthetist");
    }
    if (arrayUser.institutions[0].institution_user.is_dashboard) {
      arrayTypes.push("is_dashboard");
    }
    if (arrayUser.institutions[0].institution_user.is_manager) {
      arrayTypes.push("is_manager");
    }

    if (
      arrayUser.institutions[0].institution_user.is_anaesthetist &&
      arrayUser.institutions[0].institution_user.is_resident
    ) {
      this.setState({
        is_resident: true,
        edit_institution_id: !arrayUser.institutions[0].is_group
          ? arrayUser.institutions[0].id
          : null
      });
    } else {
      this.setState({ is_resident: false, edit_institution_id: null });
    }

    if (arrayUser.institutions[0].institution_user.expiration_date) {
      edit_expiration_date = moment(
        arrayUser.institutions[0].institution_user.expiration_date
      );
    }

    const editInvite = {
      id: user_id,
      type: arrayTypes,
      email: arrayUser.email,
      expiration_date: edit_expiration_date
    };

    this.setState({ modalVisible: true, loadingSend: false });
    this.props.form.setFieldsValue(editInvite);
  };

  handleRoomCancel = () => {
    const clearMedicalPlan = {
      surgery_center_id: null,
      name: "",
      id: null,
      uuid: null
    };

    this.setState({ modalVisible: false, loadingSave: false });
    this.props.form.setFieldsValue(clearMedicalPlan);
  };

  submitDelete = () => {
    let promiseSave;

    if (this.state.delete_id) {
      this.setState({ confirmLoading: true });
      promiseSave = usersService
        .deleteUser(this.state.delete_id, this.state.delete_institution_id)
        .then(result => {
          notification.success({
            message: "Usuário removido com sucesso!"
          });

          this.load();

          this.setState({
            confirmLoading: false,
            confirmVisible: false,
            delete_id: null
          });
        });

      promiseSave.catch(error => {
        console.log(error);
        notification.error({
          message: error
        });
      });
    }
  };

  deleteUser = (user_id, institution_id) => {
    this.setState({
      confirmVisible: true,
      confirmLoading: false,
      delete_id: user_id,
      delete_institution_id: institution_id
    });
  };

  searchTable = e => {
    const { users } = this.props;
    const text = e.target.value;

    setTimeout(() => {
      if (text.length) {
        let newDataUsers = users.data.filter(x => {
          return (
            (x.name &&
              stringsHelp
                .removeAccent(x.name.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase()))) ||
            (x.email &&
              stringsHelp
                .removeAccent(x.email.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase())))
          );
        });
        this.setState({ dataUsers: newDataUsers });
      } else {
        this.setState({ dataUsers: users.data });
      }
    }, 300);
  };

  render() {
    const { users } = this.props;
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 }
      },
      colon: false
    };

    const columnsTableUsers = [
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => {
          return (
            <span>
              {stringsHelp.firstLetterUpper(name, true)}

                  {record.institutions.map((institution, index) => {
                    if (
                      JSON.parse(localStorage.getItem('selected-institution')) &&
                      JSON.parse(localStorage.getItem('selected-institution')).id !== institution.id &&
                      institution.institution_user.active === true
                    ) {
                      return (
                        <span>
                          &nbsp;&nbsp;
                          <Tag
                            className="tag-custom-round"
                            color="rgb(221, 246, 246)"
                            style={{
                              marginBottom: 3
                            }}
                          >
                            {' '}
                            <Icon type="home" />
                            &nbsp;&nbsp;
                            <span>
                              {' '}
                              {stringsHelp.maxLength(
                                stringsHelp.formatName(
                                  institution.name
                                ),
                                40,
                                true
                              )}
                            </span>
                          </Tag>
                        </span>
                      );
                    }
                  })}
               
            </span>
          );
        }
      },
      {
        title: "E-mail",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Acessos",
        key: "perfil",        
        render: (text, record) => {
          const objskill = record.institutions[0].institution_user;

          let tagSkill = [];

          if (objskill.is_anaesthetist) {
            if (objskill.is_resident) {
              tagSkill.push(
                <Tag>
                  <Icon type="tablet" /> Anestesista <Divider type="vertical" />{" "}
                  <span style={{ color: "orange" }}>Residente</span>
                </Tag>
              );
            } else {
              tagSkill.push(
                <Tag>
                  <Icon type="tablet" /> Anestesista
                </Tag>
              );
            }
          }
          if (objskill.is_dashboard) {
            tagSkill.push(
              <Tag>
                <Icon type="bar-chart" /> Dashboard
              </Tag>
            );
          }
          if (objskill.is_manager) {
            tagSkill.push(
              <Tag>
                <Icon type="laptop" /> Manager
              </Tag>
            );
          }
          if (objskill.is_admin) {
            tagSkill.push(
              <Tag>
                <Icon type="laptop" /> Administrador
              </Tag>
            );
          }

          return (
            <div key={10} style={{ display: "inline-flex", flexWrap: 'wrap', alignItems: 'flex-start'}}>
              {tagSkill.map((component, index) => (
                <div style={{margin: '1em 0'}} key={index}> {component} </div>
              ))}
            </div>
          );
        }
      },
      {
        title: "Expiração",
        key: "expiracao",        
        render: (text, record) => {
          const expiration_date =
            record.institutions[0].institution_user.expiration_date;
          return expiration_date
            ? moment(expiration_date).format("DD/MM/YYYY")
            : "";
        }
      },
      {
        title: "Status",
        dataIndex: "active",
        key: "active",
        render: (text, record) => {
          const active = record.institutions[0].institution_user.active;

          if (active) {
            return (
              <Tag className="tag-custom-round" color="rgb(221, 246, 246)">
                Ativo
              </Tag>
            );
          } else {
            return (
              <Tag className="tag-custom-round" color="red">
                Pendente <Divider type="vertical" />{" "}
                <Popover
                  content={
                    <Button
                      type="primary"
                      loading={this.state.loadingSend}
                      onClick={() => this.handleInviteResend(record)}
                      block
                      size="small"
                    >
                      Confirmar
                    </Button>
                  }
                  title="Deseja reenviar convite?"
                  trigger="hover"
                >
                  <Icon type="mail" />
                </Popover>
              </Tag>
            );
          }
        }
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        render: (id, record) => {
          const institution_id = record.institutions[0].id;
          return (
            <span style={{ display: "flex" }}>
              <Tooltip title="Editar">
                <Button
                  type="link"
                  onClick={() => this.openInviteEditModal(id)}
                >
                  <Icon type="edit" />
                </Button>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title="Remover">
                <Button
                  type="link"
                  onClick={() => this.deleteUser(id, institution_id)}
                >
                  <Icon type="delete" />
                </Button>
              </Tooltip>
            </span>
          );
        }
      }
    ];

    return (
      <Content>
        <Row>
          <Col xs={24} sm={0}>
            <button
              onClick={() => this.openInviteModal()}
              className="ant-btn ant-btn-primary ant-btn-lg ant-btn-circle add-procedure"
            >
              <Icon type="plus" fill="#ffffff" />
            </button>
          </Col>
          <Col xs={24} lg={8} sm={16}>
            <h1 className="title">Usuários</h1>
          </Col>
          <Col xs={0} lg={16} sm={8}>
          <div style={{ display: "flex" }}>
            <Search
              placeholder="Buscar"
              onChange={this.searchTable}
              style={{ width: "100%", marginRight: 10 }}
            />
            <Button
              type="primary"
              shape="round"
              className="add-procedure"
              onClick={() => this.openInviteModal()}
            >
              NOVO CONVITE
            </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Table
            size="midle"
            loading={users.loading}
            dataSource={this.state.dataUsers}
            columns={columnsTableUsers}
            scroll={{ x: "100%" }}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "30", "50", "100"]
            }}
            footer={() => {
              const rowsDataSource = !this.state.dataUsers
                ? 0
                : this.state.dataUsers.length;
              return (
                <span>
                  Total de registros: <Tag>{rowsDataSource}</Tag>
                </span>
              );
            }}
          />
        </Row>

        <Modal
          visible={this.state.modalVisible}
          onCancel={this.handleInviteCancel}
          title={
            <div>
              <button
                className="modal-close"
                type="button"
                onClick={this.handleInviteCancel}
              >
                <i className="icon-close" />
              </button>
              <span>
                {this.props.form.getFieldValue("id")
                  ? "Alterar usuário"
                  : "Convite de usuário"}
              </span>
            </div>
          }
          footer={[
            <Button
              key="submit"
              type="primary"
              form="formUser"
              htmlType="submit"
              loading={this.state.loadingSend}
            >
              {this.props.form.getFieldValue("id") ? "Alterar" : "Enviar"}
            </Button>
          ]}
          bodyStyle={{ paddingTop: "21px" }}
          width="701px"
          zIndex={1024}
        >
          <Form onSubmit={this.handleSubmit} id="formUser">
            {getFieldDecorator("id", {})(<span />)}
            <Row gutter={28}>
              <Col md={24} lg={24} xl={24}>
                <FormItem {...formItemLayout} label="E-MAIL">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        message: "Preencha o email."
                      }
                    ]
                  })(
                    <Input
                      autoFocus={true}
                      disabled={
                        this.props.form.getFieldValue("id") ? true : false
                      }
                    />
                  )}
                </FormItem>
              </Col>
              <Col md={24} lg={24} xl={24}>
                <FormItem {...formItemLayout} label="DATA DE EXPIRAÇÃO">
                  {getFieldDecorator("expiration_date", {})(
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      placeholder=""
                      showAction={["click"]}
                    />
                  )}
                </FormItem>
              </Col>

              <Col md={24} lg={24} xl={24}>
                <FormItem
                  {...formItemLayout}
                  label={
                    <span>
                      SELECIONE O TIPO DE ACESSO{" "}
                      <small style={{ float: "right" }}>
                        <Icon type="question-circle" /> Passe o mouse para
                        detalhes
                      </small>
                    </span>
                  }
                >
                  {getFieldDecorator("type", {
                    rules: [
                      {
                        required: true,
                        message: "Selecione o tipo de acesso do usuário."
                      }
                    ]
                  })(
                    <Checkbox.Group style={{ width: "100%" }}>
                      <Row style={{ marginTop: 15 }}>
                        <Col className="col-margin-check" span={24}>
                          <Checkbox value="is_anaesthetist">
                            <Tooltip
                              placement="right"
                              title="Acesso ao aplicativo IOS para registro digital anestésico"
                            >
                              AxReg - <Icon type="tablet" /> Aplicativo{" "}
                              <Tag
                                className="tag-custom-round"
                                color="rgb(221, 246, 246)"
                              >
                                ANESTESISTA
                              </Tag>
                            </Tooltip>
                          </Checkbox>
                          {this.props.form.getFieldValue("type") &&
                            this.props.form
                              .getFieldValue("type")
                              .includes("is_anaesthetist") && (
                              <Card className="card-add-residence">
                                <span
                                  style={{
                                    fontSize: 15,
                                    fontWeight: 400,
                                    color: "#343f5c",
                                    marginLeft: 35
                                  }}
                                >
                                  Médico residente ?{" "}
                                  <Switch
                                    defaultChecked={this.state.is_resident}
                                    onChange={checked =>
                                      this.setState({ is_resident: checked })
                                    }
                                    checkedChildren={"Sim"}
                                    unCheckedChildren={"Não"}
                                  />
                                </span>
                                <Tooltip
                                  placement="right"
                                  title="Integração de procedimentos anestésicos com SBA Logbook (exclusivo para médico residente)"
                                >
                                  <span
                                    style={{
                                      color: "#7c8595",
                                      fontSize: 15,
                                      float: "right",
                                      marginRight: 25,
                                      padding: 5
                                    }}
                                  >
                                    <small style={{ marginRight: 5 }}>
                                      <Icon type="api" /> Integração
                                    </small>{" "}
                                    <img
                                      src={LogoLogBook}
                                      alt="Logo Logbook"
                                      height={25}
                                    />jn
                                    <Divider type="vertical" />
                                    <img
                                      src={LogoLogSba}
                                      alt="Logo SBA "
                                      height={20}
                                    />
                                  </span>
                                </Tooltip>

                                {this.state.is_resident &&
                                JSON.parse(
                                  localStorage.getItem("selected-institution")
                                ) &&
                                JSON.parse(
                                  localStorage.getItem("selected-institution")
                                ).is_group ? (
                                  <div
                                    style={{
                                      paddingLeft: 35,
                                      paddingRight: 35,
                                      paddingTop: 20
                                    }}
                                  >
                                    <FormItem
                                      {...formItemLayout}
                                      label="Instituição"
                                    >
                                      {getFieldDecorator("institution_ids", {
                                        initialValue: this.state
                                          .edit_institution_id
                                      })(<SelectMultipleInstitutions />)}
                                    </FormItem>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Card>
                            )}
                        </Col>
                        <Col className="col-margin-check" span={24}>
                          <Checkbox value="is_dashboard">
                            <Tooltip
                              placement="right"
                              title="Acesso ao sistema Web para visualizar dasboards de performance institucional do sitio cirúrgico"
                            >
                              AxReg - <Icon type="bar-chart" /> Dashboard{" "}
                              <Tag
                                className="tag-custom-round"
                                color="rgb(221, 246, 246)"
                              >
                                INSTITUCIONAL
                              </Tag>
                            </Tooltip>
                          </Checkbox>
                        </Col>
                        <Col className="col-margin-check" span={24}>
                          <Checkbox value="is_manager">
                            <Tooltip
                              placement="right"
                              title="Acesso limitado ao sistema Web para Manager administrativos e visualização de dados de paciente"
                            >
                              AxReg - <Icon type="laptop" /> Manager{" "}
                              <Tag
                                className="tag-custom-round"
                                color="rgb(221, 246, 246)"
                              >
                                AUXILIAR
                              </Tag>
                            </Tooltip>
                          </Checkbox>
                        </Col>
                        <Col span={24}>
                          <Checkbox value="is_admin">
                            <Tooltip
                              placement="right"
                              title="Acesso total ao sistema Web para Manager administrativos e visualização de dados de paciente incluindo o gerenciamento de usuários"
                            >
                              AxReg - <Icon type="laptop" /> Manager{" "}
                              <Tag
                                className="tag-custom-round"
                                color="rgb(221, 246, 246)"
                              >
                                ADMINISTRADOR{" "}
                              </Tag>
                            </Tooltip>
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  )}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title=""
          visible={this.state.confirmVisible}
          onOk={() => this.submitDelete()}
          okText="Sim"
          okType="primary"
          cancelText="Não"
          confirmLoading={this.state.confirmLoading}
          onCancel={() => this.setState({ confirmVisible: false })}
        >
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            <span style={{ fontSize: 24, color: "#ffcd56", marginRight: 10 }}>
              <Icon type="question-circle" />
            </span>{" "}
            Confirma remover o usuário ?
          </p>
        </Modal>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

const connected = connect(mapStateToProps)(Form.create()(Users));
export default connected;
