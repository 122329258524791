import React, { Component } from 'react'
import { CancelToken } from 'axios'
import { withRouter } from 'react-router-dom'
import { Select, Empty, Avatar } from 'antd'
import { connect } from 'react-redux'
import { stringsHelp } from '../helpers'
import { institutionActions } from '../actions'

const Option = Select.Option

class SelectInstitutions extends Component {
  state = {
    dataPatients: [],
    value: undefined,
    textSearch: '',
    searchingPatients: false
  };

  cancelSource = CancelToken.source();

  handleChange = value => {
    if (value) {
      this.setState({ value })
    } else {
      this.setState({ value: undefined })
    }

    if (typeof (this.props.onChange) === 'function') {
      this.props.onChange(value)
    }
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.institution !== prevProps.institution) {
      this.setState({ value: this.props.institution })
    }
  }

  componentDidMount () {
    const { dispatch } = this.props
    dispatch(institutionActions.getInstitutions())
  }

  render () {
    const { institutions, notClinic = false, allinst = false } = this.props
    if (institutions.items && notClinic) {
      institutions.items.map(value => console.log(value))
      institutions.items = institutions.items.filter(value => !value.is_clinic)
    }

    let options = ''

    if (institutions.items && institutions.items.size > 1) {
      options = institutions.items
        .filter(i => allinst ? i : i.id !== JSON.parse(localStorage.getItem('selected-institution')).id)
        .map(institution => {
          return (
          <Option value={institution.id} key={institution.id}>
               <div className="ant-list-item-meta ant-group" style={{ marginTop: 5 }}>
                <div className="ant-list-item-meta-avatar">
                  <Avatar
                    shape="square"
                    src={
                      institution.logo_url
                        ? institution.logo_url
                        : null
                    }
                    className="institution-logo"
                  >
                    Logo
                  </Avatar>
                </div>
                <div className="ant-list-item-meta-content">
                  <h4
                    className="ant-list-item-meta-title"
                    style={{ marginBottom: -5 }}
                  >
                    {stringsHelp.firstLetterUpper(institution.name, true)}
                  </h4>
                </div>
              </div>
          </Option>
          )
        })
    }

    return (
      <Select
      className="select-institution-group"
        style={{ width: '100%' }}
        allowClear={false}
        value={!this.props.value ? this.state.value : this.props.value}
        placeholder={'Selecione a instituição'}
        defaultActiveFirstOption={false}
        showArrow={true}
        filterOption={false}
        onChange={this.handleChange}
        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={' '} />}
      >
        {options}
      </Select>
    )
  }
}

function mapStateToProps (state) {
  const { institutions } = state
  return {
    institutions
  }
}

const connected = withRouter(
  connect(mapStateToProps)(SelectInstitutions)
)

export default connected
