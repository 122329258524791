import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Tooltip,
  message,
  Alert,
  notification,
  Spin,
  Select
} from "antd";
import { connect } from "react-redux";
import { alertActions } from "../../actions";
import { usersService } from "../../services";
import iconHeader from "../../assets/imgs/axreg-logo.png";
import iconColor from "../../assets/imgs/icon_color.png";
import MaskedInput from "react-text-mask";
import validarCpf from "validar-cpf";

const { Content, Footer } = Layout;
const Option = Select.Option;

const FormItem = Form.Item;

class Login extends Component {
  state = {
    loading: true,
    loadingSend: false,
    containerConfirm: false,
    containerExists: false,
    is_anaesthetist: false,
    is_admin: false,
    is_dashboard: false,
    is_manager: false,
    nameInstitution: "",
    permissions: [],
    user: {},
    perosonalTitle: "Dr."
  };

  componentDidMount() {
    this.load();
  }

  load = () => {
    let promiseSave;
    const { match } = this.props;
    const token = match.params.id;

    this.setState({ loading: true });

    promiseSave = usersService.getUserAccept(token).then(result => {
      if (!result.active) {
        this.setState({
          loading: false,
          user: result,
          containerConfirm: false,
          nameInstitution: result.institutions[0].name,
          is_admin: result.institutions[0].institution_user.is_admin,
          is_anaesthetist:
            result.institutions[0].institution_user.is_anaesthetist,
          is_dashboard: result.institutions[0].institution_user.is_dashboard,
          is_manager: result.institutions[0].institution_user.is_manager
        });
      } else {
        const is_confirmed = result.institutions[0].institution_user.active;

        if (is_confirmed) {
          this.setState({
            loading: false,
            user: result,
            containerConfirm: true,
            nameInstitution: result.institutions[0].name,
            is_admin: result.institutions[0].institution_user.is_admin,
            is_anaesthetist:
              result.institutions[0].institution_user.is_anaesthetist,
            is_dashboard: result.institutions[0].institution_user.is_dashboard,
            is_manager: result.institutions[0].institution_user.is_manager
          });
        } else {
          this.setState({
            loading: false,
            user: result,
            containerConfirm: false,
            containerExists: true,
            nameInstitution: result.institutions[0].name,
            is_admin: result.institutions[0].institution_user.is_admin,
            is_anaesthetist:
              result.institutions[0].institution_user.is_anaesthetist,
            is_dashboard: result.institutions[0].institution_user.is_dashboard,
            is_manager: result.institutions[0].institution_user.is_manager
          });
        }
      }
    });

    promiseSave.catch(error => {
      notification.error({
        message:
          "Falha ao localizar convite de usuário, tente novamente mais tarde ou informe ao suporte técnico!",
        duration: null
      });
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {

        values.personal_title = this.state.perosonalTitle;

        const passwordPattern = new RegExp(
          "(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}"
        );

        if (values.password) {
          if (!passwordPattern.test(values.password)) {
            message.error(
              "A senha deve conter no mínimo 8 caracteres contendo: Números, Letras Maiúsculas, Letras minúsculas e Caracteres especiais."
            );

            this.setState({ loadingSend: false });

            return false;
          }
        }

        let promiseSave;
        const { match } = this.props;
        const token = match.params.id;
        delete values.confirm_password;
        delete values.email;
        values.name = values.name ? values.name.trim() : '';

        this.setState({ loadingSend: true });

        promiseSave = usersService
          .postAcceptInvite(token, values)
          .then(result => {
            if (result.id > 0) {
              notification.success({
                message: "Confirmação realizada com sucesso!"
              });

              this.setState({
                loading: false,
                loadingSend: false,
                containerConfirm: true
              });
            }
          });

        promiseSave.catch(error => {
          console.log(error);
          this.setState({ loadingSend: false });
          notification.error({
            message: error
          });
        });
      }
    });
  };

  validatorCPF = (rule, value, callback) => {
    if (value && !validarCpf(value)) {
      callback(rule.message);
      return null;
    } else {
      callback();
      return null;
    }
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("A confirmação de senha não confere!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm_password"], { force: true });
    }
    callback();
  };

  changePersonalTitle = (value) => {
    this.setState({perosonalTitle: value});
  };

  redirectPage = type => {
    switch (type) {
      case "app":
        window.open(
          "https://itunes.apple.com/br/app/axreg/id1279049888?mt=8",
          "_blank"
        );
        break;

      case "dashboard":
        window.open("https://dashboard.axreg.app", "_blank");
        break;

      case "axreg":
        window.open("https://axreg.app", "_blank");
        break;

      default:
        break;
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 }
      },
      colon: false
    };

    const selectBefore = (
      <Select defaultValue={this.state.perosonalTitle} style={{ width: 70 }} onChange={this.changePersonalTitle}>
        <Option value="Dr.">Dr.</Option>
        <Option value="Dra.">Dra.</Option>
        <Option value="Sr.">Sr.</Option>
        <Option value="Sra.">Sra.</Option>
      </Select>
    );

    return (
      <div>
        <Layout
          className="login-page"
          style={{ minHeight: "100vh", padding: 0, background: "#1abfbb" }}
        >
          <Spin spinning={this.state.loading}>
            <Content style={{ padding: "0 10px" }}>
              {this.state.containerConfirm ? (
                <Row type="flex" justify="center" align="middle">
                  <Col xs={0} sm={2} md={3} lg={6} xl={7} xxl={8} />
                  <Col
                    xs={24}
                    sm={20}
                    md={18}
                    lg={12}
                    xl={10}
                    xxl={8}
                    style={{ paddingTop: "50px" }}
                  >
                    <div style={{ padding: "30px" }} className="login-form">
                      <Card
                        title={
                          <div style={{ textAlign: "center" }} className="logo">
                            <img
                              src={iconHeader}
                              alt="Logo AxReg"
                              className="logo-axreg-invite"
                            />
                          </div>
                        }
                        bordered={false}
                        style={{ width: "100%" }}
                      >
                        {!this.state.loading && (
                          <div>
                            <Alert
                              message="Confirmação realizada!"
                              description="Clique abaixo e acesse o AxReg"
                              type="success"
                              showIcon
                              style={{ background: "#f6ffeda1" }}
                            />

                            <Divider>Acessos disponíveis</Divider>

                            <div style={{ textAlign: "center" }}>
                              {this.state.is_anaesthetist && (
                                <Button
                                  block
                                  type="primary"
                                  icon="apple"
                                  size="large"
                                  style={{ marginTop: 10 }}
                                  onClick={() => this.redirectPage("app")}
                                >
                                  BAIXAR AXREG PARA IPAD
                                </Button>
                              )}

                              {(this.state.is_admin ||
                                this.state.is_manager) && (
                                <Button
                                  block
                                  type="default"
                                  icon="laptop"
                                  size="large"
                                  style={{ marginTop: 20 }}
                                  onClick={() => this.redirectPage("axreg")}
                                >
                                  ACESSE O AXREG CADASTROS
                                </Button>
                              )}

                              {this.state.is_dashboard && (
                                <Button
                                  block
                                  type="dashed"
                                  icon="bar-chart"
                                  size="large"
                                  style={{ marginTop: 20 }}
                                  className="btn-custom02"
                                  onClick={() => this.redirectPage("dashboard")}
                                >
                                  ACESSE O AXREG DASHBOARD
                                </Button>
                              )}
                            </div>

                            <Divider />
                            <p>
                              <small>
                                Em caso de qualquer dúvida, fique à vontade para
                                nos contatar no{" "}
                                <a
                                  href="mailto:suporte@anestech.com.br"
                                  target="_top"
                                >
                                  suporte@anestech.com.br
                                </a>
                              </small>
                            </p>
                          </div>
                        )}
                      </Card>
                    </div>
                  </Col>
                  <Col xs={0} sm={2} md={3} lg={6} xl={7} xxl={8} />
                </Row>
              ) : (
                <Row type="flex" justify="center" align="middle">
                  <Col xs={0} sm={2} md={3} lg={6} xl={7} xxl={8} />
                  <Col
                    xs={24}
                    sm={20}
                    md={18}
                    lg={12}
                    xl={10}
                    xxl={8}
                    style={{ paddingTop: "50px" }}
                  >
                    <div style={{ padding: "30px" }} className="login-form">
                      <Card
                        title={
                          <div style={{ textAlign: "center" }} className="logo">
                            <img
                              src={iconHeader}
                              alt="Logo AxReg"
                              className="logo-axreg-invite"
                            />
                          </div>
                        }
                        bordered={false}
                        style={{ width: "100%" }}
                      >
                        {!this.state.loading && (
                          <div>
                            <p style={{ fontSize: 17 }}>
                              Você foi convidado(a) pelo{" "}
                              <b>{this.state.nameInstitution}</b> para fazer
                              parte do AxReg! :)
                            </p>

                            <Form
                              onSubmit={this.handleSubmit}
                              className="login-form"
                              style={{ marginTop: 20 }}
                              autocomplete="off"
                            >
                              <FormItem {...formItemLayout} label="E-MAIL">
                                {getFieldDecorator("email", {
                                  initialValue: this.state.user.email
                                })(<Input type="email" disabled />)}
                              </FormItem>

                              {this.state.containerExists ? (
                                <Alert
                                  message="Identificamos que você já possui cadastro!"
                                  description="Clique abaixo e confirme o vinculo com a instituição"
                                  type="warning"
                                  showIcon
                                />
                              ) : (
                                <div>
                                  {" "}
                                  <FormItem {...formItemLayout} label="NOME">

                                      {getFieldDecorator("name", {
                                        rules: [
                                          {
                                            required: true,
                                            message: "Preencha o nome."
                                          }
                                        ]
                                      })(
                                        <Input addonBefore={selectBefore}  />
                                      )}

                                  </FormItem>

                                 <FormItem {...formItemLayout} label="CPF">
                                    {getFieldDecorator("document", {
                                      rules: [
                                        {
                                          validator: this.validatorCPF,
                                          message: "CPF informado inválido."
                                        },
                                        {
                                          required: true,
                                          message: "Preencha o número do CPF."
                                        }
                                      ]
                                    })(
                                      <MaskedInput
                                        maxLength={14}
                                        guide={false}
                                        mask={[
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          ".",
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          ".",
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          "-",
                                          /\d/,
                                          /\d/
                                        ]}
                                        className="ant-input"
                                      />
                                    )}
                                  </FormItem>
                                  <Divider style={{ color: "#343f5c" }} />
                                  <Row gutter={16}>
                                    <Col lg={24} xl={12}>
                                      <FormItem
                                        {...formItemLayout}
                                        label="SENHA"
                                      >
                                        {getFieldDecorator("password", {
                                          rules: [
                                            {
                                              required: true,
                                              message: "Preencha a senha."
                                            },
                                            {
                                              validator: this
                                                .validateToNextPassword
                                            }
                                          ]
                                        })(<Input.Password type="password" />)}
                                      </FormItem>
                                    </Col>

                                    <Col lg={24} xl={12}>
                                      <FormItem
                                        {...formItemLayout}
                                        label="CONFIRMAR SENHA"
                                      >
                                        {getFieldDecorator("confirm_password", {
                                          rules: [
                                            {
                                              required: true,
                                              message:
                                                "Preencha a confirmação de senha."
                                            },
                                            {
                                              validator: this
                                                .compareToFirstPassword
                                            }
                                          ]
                                        })(<Input.Password type="password" />)}
                                      </FormItem>
                                    </Col>
                                  </Row>{" "}
                                  <Alert
                                    style={{ marginTop: -10, marginBottom: 10 }}
                                    message="A senha deve conter no mínimo 8 caracteres contendo: Números, Letras Maiúsculas, Letras minúsculas e Caracteres especiais."
                                    type="warning"
                                  />
                                </div>
                              )}

                              <Form.Item>
                                <Button
                                  block
                                  type="primary"
                                  loading={this.state.loadingSend}
                                  htmlType="submit"
                                  className="btn-custom-primary"
                                  style={{ float: "rigth" }}
                                >
                                 {this.state.containerExists ? 'Confirmar' : 'Finalizar'}
                                </Button>
                              </Form.Item>
                            </Form>

                            <Divider />
                            <p>
                              <small>
                                Em caso de qualquer dúvida, fique à vontade para
                                nos contatar no{" "}
                                <a
                                  href="mailto:suporte@anestech.com.br"
                                  target="_top"
                                >
                                  suporte@anestech.com.br
                                </a>
                              </small>
                            </p>
                          </div>
                        )}
                      </Card>
                    </div>
                  </Col>
                  <Col xs={0} sm={2} md={3} lg={6} xl={7} xxl={8} />
                </Row>
              )}
            </Content>
          </Spin>
          <Footer
            style={{
              position: "relative",
              bottom: "0",
              textAlign: "center",
              background: "#1abfbb",
              color: "white"
            }}
          >
            Powered by
            <Tooltip title="Anestech">
              {" "}
              <a
                href="http://www.anestech.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={iconColor} alt="Logo" />{" "}
              </a>
            </Tooltip>
          </Footer>
        </Layout>
      </div>
    );
  }

  alerts() {
    const { alert, dispatch } = this.props;
    if (alert.message) {
      message[alert.type](alert.message);
      dispatch(alertActions.clear());
    }
    return null;
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert, institutions } = state;
  return {
    loggingIn,
    alert,
    institutions
  };
}

const connectedLoginPage = connect(mapStateToProps)(Form.create()(Login));
export default connectedLoginPage;
