import React, {Component} from 'react';
import {Checkbox, Radio, Input, Icon} from 'antd';
import {CancelToken} from "axios";
import InfiniteScroll from 'react-infinite-scroller';
import {List} from "immutable";
import {connect} from "react-redux";

import Modal from "./Modal";
import {selectsActions} from "../actions";
import {stringsHelp} from "../helpers";

class SelectAnaesthetists extends Component {

  cancelSource = CancelToken.source();

  state = {
    modalVisible: false,
    selected: new List(),
    tracks: new List(),
    hasMoreItems: true
  };

  loadAnaesthetists = (page) => {
    let value = this.filter ? this.filter.input.value : '';
    const {dispatch} = this.props;

    if (this.searchingAnaesthetistsValue !== value || this.page !== page) {
      this.page = page;

      if(this.searchingAnaesthetists && this.searchingAnaesthetistsValue !== value) {
        this.cancelSource.cancel();
        this.cancelSource = CancelToken.source();
      }

      this.searchingAnaesthetists = true;
      this.searchingAnaesthetistsValue = value;

      dispatch(selectsActions.getAnaesthetists(page, value, this.cancelSource.token))
        .then((anaesthetists) => {
          this.searchingAnaesthetists = false;

          if(anaesthetists.error){
            return null;
          }
          let tracks;
          if(anaesthetists.page > 1) {
            tracks = this.state.tracks.concat(anaesthetists.result);
          } else {
            tracks = new List(anaesthetists.result)
          }
          return this.setState({
            tracks,
            hasMoreItems: anaesthetists.page < anaesthetists.pages
          });
        });
    }
  };

  loadItems = () => {
    let page = this.page;
    page++;
    this.loadAnaesthetists(page);
  };

  setModalVisible(modalVisible) {
    this.setState({modalVisible});
  }

  filterAnaesthetists = () => {
    this.setState({
      tracks: new List(),
      hasMoreItems: true
    });

    this.loadAnaesthetists(1);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value !== prevProps.value) {
      this.changeSelected(new List(this.props.value));
    }
  }

  componentDidMount() {
    if (this.props.value) {
      this.changeSelected(new List(this.props.value));
    }
    this.loadAnaesthetists(1);
  }

  changeSelected = (selected) => {
    if (this.props.onChange) { this.props.onChange(selected); }
    this.setState({selected});
  };

  select = (event) => {
    let value = event.target.value;
    value.laterality = 1;
    this.changeSelected(this.state.selected.push(value));
  };

  deselect = (event) => {
    let value = event.target.value;
    let selected = this.state.selected.filter(v => v.id !== value.id);

    this.changeSelected(selected);
  };

  handleIsMainChange = (e) => {
    const {anaesthetist, i} = e.target.value;
    anaesthetist.is_main = !anaesthetist.is_main;
    let selected = new List(this.state.selected);
    selected[i] = anaesthetist;

    const otherMain = selected.find((v, i2) => {
      return v.is_main && i2 !== i;
    });

    if (otherMain) {
      otherMain.is_main = false;
    }

    this.changeSelected(selected);
  };

  render() {
    const {anaesthetists} = this.props;

    return (
      <div className='form-list'>
        <Input hidden={true}/>
        <Radio.Group onChange={this.handleIsMainChange} style={{width: '100%'}}>
        {
          this.state.selected.size ?
            this.state.selected.map((anaesthetist, i) => {
              let name = stringsHelp.firstLetterUpper(anaesthetist.name, true);
              console.log(anaesthetist.name, anaesthetist.is_main);
              return (
                <div className='form-list-item' title={name} key={anaesthetist.id}>
                  <span className='form-list-description'>Dr(a). {name}</span>
                  <Radio.Button value={{anaesthetist, i}} className={'star-radio'}>
                    {anaesthetist.is_main || anaesthetist.is_main_pre ? <Icon type="star" theme="filled" style={{color: '#1bbfbb'}} /> : <Icon type="star" />}
                  </Radio.Button>
                  <span className='form-list-del' onClick={() => this.deselect({target: {value: anaesthetist}})}>
                    <i className='icon-close'/>
                  </span>
                </div>
              )})
            : null
        }
        </Radio.Group>

        <div className='form-list-item'>
          <button type='button' className='form-add-link' onClick={() => this.setModalVisible(true)}>
            + Adicionar anestesiologistas
          </button>
        </div>

        <Modal
          title='Anestesiologistas'
          visible={this.state.modalVisible}
          onCancel={() => this.setModalVisible(false)}
          onOk={() => this.setModalVisible(false)}
          footer={false}
          buttonOkText={'SALVAR'}
          buttonOkType={'primary'}
        >
          <Input placeholder='Buscar' ref={input => this.filter = input} onKeyUp={this.filterAnaesthetists}/>

          <div className={`form-list ${this.state.selected.size ? '' : 'hidden' }`} style={{marginTop: '21px'}}>
            <div className='form-list-title'>SELECIONADOS ({this.state.selected.size})</div>
            <Radio.Group onChange={this.handleIsMainChange} style={{width: '100%'}}>
              {
                this.state.selected.size ?
                  this.state.selected.map((anaesthetist, i) => {
                    let name = stringsHelp.firstLetterUpper(anaesthetist.name, true);
                    return (
                      <div className='form-list-item slide-in' key={anaesthetist.id} style={{width: '100%'}}>
                        <span className='form-list-description' title={`Dr(a). ${name}`} style={{width: '100%'}}>
                            <Checkbox defaultChecked={true} value={anaesthetist} onChange={this.deselect}>
                              Dr(a). {name}
                            </Checkbox>
                            <Radio.Button value={{anaesthetist, i}} defaultChecked={anaesthetist.is_main} className={'star-radio'}>
                              {anaesthetist.is_main || anaesthetist.is_main_pre ? <Icon type="star" theme="filled" style={{color: '#1bbfbb'}} /> : <Icon type="star" />}
                            </Radio.Button>
                        </span>
                      </div>
                    )})
                  : null
              }
              </Radio.Group>
            </div>
          <div className='form-list' style={{marginTop: '21px', height: '500px', overflow: 'auto'}}>
            <div className='form-list-title'>TODOS</div>

            {
              anaesthetists ?
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={this.loadItems}
                  hasMore={this.state.hasMoreItems}
                  useWindow={false}
                >
                    {
                      this.state.tracks ?
                        this.state.tracks.map((anaesthetist, i) => {
                          let name = stringsHelp.firstLetterUpper(anaesthetist.name, true);

                          if(this.state.selected.find(a => a.id === anaesthetist.id)){
                            return null;
                          }

                          return (
                            <div className='form-list-item slide-in' key={anaesthetist.id}>
                              <span className='form-list-description' title={`Dr(a). ${name}`} >
                                <Checkbox value={anaesthetist} onChange={this.select}>
                                  Dr(a). {name}
                                </Checkbox>
                              </span>
                            </div>
                          )
                        })
                        : null
                    }
                </InfiniteScroll> : null
            }
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {selects} = state;
  const {anaesthetists} = selects;
  return {
    anaesthetists,
  };
}

const connected = connect(mapStateToProps)(SelectAnaesthetists);
export default connected;
