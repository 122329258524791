import {selectsConstants} from '../constants';
import {selectsService} from "../services";

export const selectsActions = {
  getEthnicGroups,
  getSurgeryCenters,
  getSurgeryCenterRooms,
  getRooms,
  getMedicalPlans,
  getSurgicalProceduresMostUsed,
  getSurgicalProcedures,
  getSurgeryTypes,
  getSurgeons,
  getAnaesthetists,
  getSpecialites,
  getStates,
  getTables,
  getSurgicalTeamPositions,
  getCancellationReason
};

function getSurgicalProceduresMostUsed(table_name) {
  return dispatch => {
    dispatch(request());

    selectsService.getSurgicalProceduresMostUsed(table_name)
      .then(data => {
        dispatch(success(data));
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: selectsConstants.GETSURGICALPROCEDURESMOSTUSED_REQUEST}
  }

  function success(data) {
    return {type: selectsConstants.GETSURGICALPROCEDURESMOSTUSED_SUCCESS, data}
  }

  function failure(error) {
    return {type: selectsConstants.GETSURGICALPROCEDURESMOSTUSED_FAILURE, error}
  }
}

function getSurgicalProcedures(table_name, page, filter, cancelToken) {
  return dispatch => {
    dispatch(request());

    return selectsService.getSurgicalProcedures(table_name, page, filter, cancelToken)
      .then(data => {
        dispatch(success(data));
        return data;
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: selectsConstants.GETSURGICALPROCEDURES_REQUEST}
  }

  function success(data) {
    return {type: selectsConstants.GETSURGICALPROCEDURES_SUCCESS, data}
  }

  function failure(error) {
    return {type: selectsConstants.GETSURGICALPROCEDURES_FAILURE, error}
  }
}

function getSurgeons(page, filter, signal) {
  return dispatch => {
    dispatch(request());

    return selectsService.getSurgeons(page, filter, signal)
      .then(data => {
        dispatch(success(data));
        return data;
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: selectsConstants.GETSURGEONS_REQUEST}
  }

  function success(data) {
    return {type: selectsConstants.GETSURGEONS_SUCCESS, data}
  }

  function failure(error) {
    return {type: selectsConstants.GETSURGEONS_FAILURE, error}
  }
}


function getAnaesthetists(page, filter, signal) {
  return dispatch => {
    dispatch(request());

    return selectsService.getAnaesthetists(page, filter, signal)
      .then(data => {
        dispatch(success(data));
        return data;
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: selectsConstants.GETANAESTHETISTS_REQUEST}
  }

  function success(data) {
    return {type: selectsConstants.GETANAESTHETISTS_SUCCESS, data}
  }

  function failure(error) {
    return {type: selectsConstants.GETANAESTHETISTS_FAILURE, error}
  }
}

function getEthnicGroups() {
  return dispatch => {
    dispatch(request());

    selectsService.getEthnicGroups()
      .then(data => {
        dispatch(success(data));
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: selectsConstants.GETETHNICGROUPS_REQUEST}
  }

  function success(data) {
    return {type: selectsConstants.GETETHNICGROUPS_SUCCESS, data}
  }

  function failure(error) {
    return {type: selectsConstants.GETETHNICGROUPS_FAILURE, error}
  }
}

function getSurgeryCenters(institutionId = null) {
  return dispatch => {
    dispatch(request());

    selectsService.getSurgeryCenters(institutionId)
      .then(data => {
        dispatch(success(data));
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: selectsConstants.GETSURGERYCENTERS_REQUEST}
  }

  function success(data) {
    return {type: selectsConstants.GETSURGERYCENTERS_SUCCESS, data}
  }

  function failure(error) {
    return {type: selectsConstants.GETSURGERYCENTERS_FAILURE, error}
  }
}
function getSurgeryCenterRooms(surgery_center_id) {
  return dispatch => {
    dispatch(request());

    selectsService.getSurgeryCenterRooms(surgery_center_id)
      .then(data => {
        dispatch(success(data));
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: selectsConstants.GETSURGERYCENTERROOMS_REQUEST}
  }

  function success(data) {
    return {type: selectsConstants.GETSURGERYCENTERROOMS_SUCCESS, data}
  }

  function failure(error) {
    return {type: selectsConstants.GETSURGERYCENTERROOMS_FAILURE, error}
  }
}

function getSurgeryTypes() {
  return dispatch => {
    dispatch(request());

    selectsService.getSurgeryTypes()
      .then(data => {
        dispatch(success(data));
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: selectsConstants.GETSURGERYTYPES_REQUEST}
  }

  function success(data) {
    return {type: selectsConstants.GETSURGERYTYPES_SUCCESS, data}
  }

  function failure(error) {
    return {type: selectsConstants.GETSURGERYTYPES_FAILURE, error}
  }
}

function getRooms() {
  return dispatch => {
    dispatch(request());

    selectsService.getRooms()
      .then(data => {
        dispatch(success(data));
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: selectsConstants.GETROOMS_REQUEST}
  }

  function success(data) {
    return {type: selectsConstants.GETROOMS_SUCCESS, data}
  }

  function failure(error) {
    return {type: selectsConstants.GETROOMS_FAILURE, error}
  }
}

function getMedicalPlans() {
  return dispatch => {
    dispatch(request());

    selectsService.getMedicalPlans()
      .then(data => {
        dispatch(success(data));
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: selectsConstants.GETMEDICALPLAN_REQUEST}
  }

  function success(data) {
    return {type: selectsConstants.GETMEDICALPLAN_SUCCESS, data}
  }

  function failure(error) {
    return {type: selectsConstants.GETMEDICALPLAN_FAILURE, error}
  }
}

function getSpecialites() {
  return dispatch => {
    dispatch(request());

    selectsService.getSpecialites()
      .then(data => {
        dispatch(success(data));
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: selectsConstants.GETSPECIALTIES_REQUEST}
  }

  function success(data) {
    return {type: selectsConstants.GETSPECIALTIES_SUCCESS, data}
  }

  function failure(error) {
    return {type: selectsConstants.GETSPECIALTIES_FAILURE, error}
  }
}


function getStates() {
  return dispatch => {
    dispatch(request());

    selectsService.getStates()
      .then(data => {
        dispatch(success(data));
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: selectsConstants.GETSTATES_REQUEST}
  }

  function success(data) {
    return {type: selectsConstants.GETSTATES_SUCCESS, data}
  }

  function failure(error) {
    return {type: selectsConstants.GETSTATES_FAILURE, error}
  }
}




function getTables() {
  return dispatch => {
    dispatch(request());

    selectsService.getTables()
      .then(data => {
        dispatch(success(data));
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: selectsConstants.GETTABLES_REQUEST}
  }

  function success(data) {
    return {type: selectsConstants.GETTABLES_SUCCESS, data}
  }

  function failure(error) {
    return {type: selectsConstants.GETTABLES_FAILURE, error}
  }
}


function getSurgicalTeamPositions() {
  return dispatch => {
    dispatch(request());

    selectsService.getSurgicalTeamPositions()
      .then(data => {
        dispatch(success(data));
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: selectsConstants.GETSURGICALTEAMPOSITIONS_REQUEST}
  }

  function success(data) {
    return {type: selectsConstants.GETSURGICALTEAMPOSITIONS_SUCCESS, data}
  }

  function failure(error) {
    return {type: selectsConstants.GETSURGICALTEAMPOSITIONS_FAILURE, error}
  }
}


function getCancellationReason() {
  return dispatch => {
    dispatch(request());

    selectsService.getCancellationReason()
      .then(data => {
        dispatch(success(data));
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: selectsConstants.GETCANCELLATIONREASON_REQUEST}
  }

  function success(data) {
    return {type: selectsConstants.GETCANCELLATIONREASON_SUCCESS, data}
  }

  function failure(error) {
    return {type: selectsConstants.GETCANCELLATIONREASON_FAILURE, error}
  }
}