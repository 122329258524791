import React, { Component } from "react";

import {
  Layout,
  Row,
  Col,
  Skeleton,
  Avatar,
  Icon,
  Tabs,
  Badge,
  notification,
  Spin,
  Input,
  Timeline,
  Card,
  Tag,
  Radio,
  Menu,
  Dropdown,
  Button,
  Empty,
  Divider,
  Popover,
  Select,
  Form,
  Tooltip,
} from 'antd';
import Modal from '../Modal';
import { generatePath, Link } from 'react-router-dom';
import { pathRoutes } from '../../routes';
import { connect } from 'react-redux';
import { schedulingActions, selectsActions } from '../../actions';
import { dates, stringsHelp, arrayHelp } from '../../helpers';
import { schedulingService } from '../../services';
import moment from 'moment';
import ImportExcel from '../../components/ImportExcel';
import { CancelToken } from 'axios';
import { IconTabPreSvg, IconTabProcedureSvg } from '../../helpers/icons';
import Timer from 'react-compound-timer';
import FilterDate from '../../components/FilterDate';
import { institutionActions } from '../../actions';
import ModalCheckInstitution from '../../components/ModalCheckInstitution';
import ButtonFixAnaesthetist from '../ButtonFixAnaesthetist';
import { mixPanelService } from '../../services/mixpanel.service';

const { Content } = Layout;
const TabPane = Tabs.TabPane;
const { Search } = Input;
const FormItem = Form.Item;
const { Option, OptGroup } = Select;

class Map extends Component {
  state = {
    modalVisible: false,
    modalLoading: true,
    modalProcedure: null,
    modalType: null,
    dataPreAnesthetics: [],
    dataSurgeries: [],
    dataSurgeriesRealTime: [],
    dataLoading: false,
    is_search: false,
    keyTab: "1",
    addDropdownArrow: "down",
    modalVisibleInstitutions: false,
    checkInstituionRoute: null,
    importConditionTable: "surgical-map",
    importConditionTableName: "Procedimentos cirúrgicos",
    newCancellationReason: false,
    loadingCancellation: false,
    popoverCancellationVisible: false
  };

  cancelSource = CancelToken.source();

  loadMap = (textSearch = null) => {
    const { dispatch, scheduling } = this.props;
    const selectedInstitution = JSON.parse(localStorage.getItem('selected-institution'));

    this.date = scheduling.date;
    let objParams = {};

    this.setState({ dataLoading: true });

    this.cancelSource.cancel();
    this.cancelSource = CancelToken.source();

    if (textSearch || scheduling.date) {
      if (textSearch) {
        objParams = `search=${textSearch}&type=all&limit=50&page=1`;
      } else if (scheduling.date) {
        objParams = `probable_date=${scheduling.date.format(
          "YYYY-MM-DDTHH:mm:ssZ"
        )}`;
      }

      dispatch(
        schedulingActions.getScheduling(objParams, this.cancelSource.token)
      )
        .then(data => {
          if (data.hasOwnProperty("error")) return false;

          let new_dataPreAnesthetics = [];
          let new_dataSurgeries = [];
          let new_dataSurgeriesRealTime = [];

          if (data.hasOwnProperty("pre")) {
            new_dataPreAnesthetics = data.pre.data
              .filter(x => {
                return x.data.filter(xx => {
                  let valid = true;

                  if (x.clinic_id && !selectedInstitution.is_group) {
                    valid = xx.clinic_id === selectedInstitution.id;
                  }

                  return xx.active && valid;
                });
              })
              .sort(arrayHelp.sortArray("date"));
          } else {
            new_dataPreAnesthetics.push({
              date: scheduling.date.format("YYYY-MM-DD"),
              data: !data
                ? []
                : data
                    .filter(x => {
                      let valid = true;

                      if (x.clinic_id && !selectedInstitution.is_group) {
                        valid = x.clinic_id === selectedInstitution.id;
                      }

                      return (
                        x.pre_probable_date &&
                        moment(x.pre_probable_date).format("YYYY-MM-DD") ===
                          scheduling.date.format("YYYY-MM-DD") &&
                        x.active && valid
                      );
                    })
                    .sort(arrayHelp.sortArray("pre_probable_date"))
            });
          }

          if (data.hasOwnProperty("surgeries")) {
            new_dataSurgeries = data.surgeries.data
              .filter(x => {
                return x.data.filter(xx => {
                  return xx.active;
                });
              })
              .sort(arrayHelp.sortArray("date"));
          } else {
            new_dataSurgeries.push({
              date: scheduling.date.format("YYYY-MM-DD"),
              data: !data
                ? []
                : data
                    .filter(x => {
                      return (
                        x.surgery_probable_date &&
                        moment(x.surgery_probable_date).format("YYYY-MM-DD") ===
                          scheduling.date.format("YYYY-MM-DD") &&
                        x.active
                      );
                    })
                    .sort(arrayHelp.sortArray("surgery_probable_date"))
            });

            new_dataSurgeriesRealTime = !data
              ? []
              : data
                  .filter(x => {
                    return (
                      x.surgery_probable_date &&
                      (x.anesthesia_start && !x.anesthesia_end)
                    );
                  })
                  .map(function(obj) {
                    let ms = moment(moment(), "YYYY-MM-DDTHH:mm:ssZ").diff(
                      moment(obj.anesthesia_start, "YYYY-MM-DDTHH:mm:ssZ")
                    );
                    return {
                      ...obj,
                      duration: ms
                    };
                  });
          }

          this.setState({
            dataPreAnesthetics: new_dataPreAnesthetics,
            dataSurgeries: new_dataSurgeries,
            dataSurgeriesRealTime: new_dataSurgeriesRealTime,
            dataLoading: false
          });
        })
        .catch(error => {
          console.log("ERRROR", error);
          this.setState({
            dataPreAnesthetics: [],
            dataSurgeries: [],
            dataSurgeriesRealTime: [],
            dataLoading: false
          });
        });
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { scheduling } = this.props;

    if (scheduling.date !== this.date) {
      this.loadMap();
    }
  }

  handleSubmitCancellationReason = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.cancellation_date = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        values.cancellation_reason_description = values.hasOwnProperty(
          "cancellation_reason_description"
        )
          ? values.cancellation_reason_description
          : null;
        values.cancellation_reason_id = values.hasOwnProperty(
          "cancellation_reason_id"
        )
          ? values.cancellation_reason_id
          : null;

        let promiseSave;

        this.setState({ loadingCancellation: true });

        promiseSave = schedulingService
          .putCancellationReason(this.state.modalProcedure.id, values)
          .then(result => {
            if (result.hasOwnProperty("cancellation_date")) {
              notification.success({
                message: "Procedimento cancelado com sucesso"
              });

              this.setState({
                loadingCancellation: false,
                modalVisible: false,
                popoverCancellationVisible: false
              });

              this.loadMap();
            }
          });

        promiseSave.catch(error => {
          console.log(error);
          this.setState({ loadingCancellation: false });
          notification.error({
            message: error
          });
        });
      }
    });
  };

  componentDidMount() {
    const { dispatch } = this.props;

    mixPanelService.tracking(mixPanelService.MENU_MAPA);

    dispatch(institutionActions.getInstitutions());
    dispatch(selectsActions.getCancellationReason());

    this.loadMap();
  }

  componentWillUnmount() {
    clearInterval(this.countDuration);
  }

  openModal = (procedure, type) => {
    //this.modalProcedure = procedure;

    let promiseSave;
    this.setModalVisible(true);

    promiseSave = schedulingService.getProcedure(procedure.id).then(result => {
      if (result.id > 0) {
        this.setState({
          modalLoading: false,
          modalProcedure: result,
          modalType: type
        });
      }
    });

    promiseSave.catch(error => {
      console.log(error);
      notification.error({
        message:
          "Falha ao carregar detalhes de agendamento, notifique o suporte técnico!"
      });
    });
  };

  setModalVisible = modalVisible => {
    this.setState({ modalVisible, modalLoading: true });
  };

  setModalVisibleCheckInstitution = modalVisibleInstitutions => {
    this.setState({ modalVisibleInstitutions, addDropdownArrow: "down" });
  };

  handleOk = id => {
    if(this.state.modalType === 'pre'){
      this.props.history.push(generatePath(pathRoutes.editSchedulePre, { id }));
    }
    else{
      this.props.history.push(generatePath(pathRoutes.editSchedule, { id }));
    }
  };

  handleCancel = () => {
    //this.setModalVisible(false);
    this.setState({
      modalVisible: false,
      modalLoading: false,
      modalProcedure: null,
      modalType: null
    });
  };

  SearchScheduling = e => {
    const text = e.target.value;

    if (text.length) {
      this.setState({ is_search: true });
      mixPanelService.tracking(mixPanelService.MAPA_BUSCA_PACIENTE);
      setTimeout(() => {
        if (text.length >= 3) {
          this.loadMap(text);
        }
      }, 300);
    } else {
      this.setState({ is_search: false });
      this.loadMap();
    }
  };

  addArrowChange = a => {
    if (a) {
      this.setState({ addDropdownArrow: "up" });
    } else {
      this.setState({ addDropdownArrow: "down" });
    }
  };

  checkDay = date => {
    let diff = date.diff(moment().startOf("day"), "day");

    switch (diff) {
      case 0:
        return `Hoje, ${moment(date).format("DD/MM")}`;
      case 1:
        return `Amanhã, ${moment(date).format("DD/MM")}`;
      case -1:
        return `Ontem, ${moment(date).format("DD/MM")}`;
      default:
        return moment(date).format("DD/MM/YYYY");
    }
  };

  renderStatusScheduling = (map, type, obj) => {
    try {
      if (map === "pre") {
        if (obj.cancellation_date)
          return type === "label" ? (
            <span className="s-cancel">
              <Divider type="vertical" /> Cancelado
            </span>
          ) : (
            <Icon
              type="close-circle"
              theme="filled"
              className="icon-timeline-cancel"
            />
          );
        if (obj.is_team_changed && !obj.create_pre_date)
          return type === "label" ? (
            <span>{""}</span>
          ) : (
            <span className="span-timeline-is-team-changed">
              <Icon type="arrow-up" className="icon-timeline-is-team-changed" />
            </span>
          );
        if (obj.is_team_changed && obj.create_pre_date)
          return type === "label" ? (
            <span className="s-success">
              <Divider type="vertical" /> Realizado
            </span>
          ) : (
            <Icon type="arrow-up" className="icon-timeline-is-team-changed" />
          );
        if (obj.create_pre_date)
          return type === "label" ? (
            <span className="s-success">
              <Divider type="vertical" /> Realizado
            </span>
          ) : (
            <Icon
              type="check-circle"
              theme="filled"
              className="icon-timeline-success"
            />
          );
        if (!obj.create_pre_date)
          return type === "label" ? (
            <span>{""}</span>
          ) : (
            <Icon
              type="check-circle"
              theme="filled"
              className="icon-timeline-start"
            />
          );
        if (obj.pre_probable_date && obj.owner && !obj.create_pre_date)
          return (
            <span className="s-running">
              <Divider type="vertical" /> Em curso
            </span>
          );
      } else {
        if (obj.cancellation_date)
          return type === "label" ? (
            <span className="s-cancel">
              <Divider type="vertical" /> Cancelado
            </span>
          ) : (
            <Icon
              type="close-circle"
              theme="filled"
              className="icon-timeline-cancel"
            />
          );
        if (obj.surgery_probable_date && !obj.anesthesia_start)
          return type === "label" ? (
            <span>{""}</span>
          ) : (
            <Icon
              type="check-circle"
              theme="filled"
              className="icon-timeline-start"
            />
          );
        if (obj.is_team_changed && !obj.procedure_closed_date)
          return type === "label" ? (
            <span>{""}</span>
          ) : (
            <span className="span-timeline-is-team-changed">
              <Icon type="arrow-up" className="icon-timeline-is-team-changed" />
            </span>
          );
        if (obj.is_team_changed && obj.procedure_closed_date)
          return type === "label" ? (
            <span className="s-success">
              <Divider type="vertical" /> Realizado
            </span>
          ) : (
            <Icon type="arrow-up" className="icon-timeline-is-team-changed" />
          );
        if (obj.anesthesia_start && obj.anesthesia_end)
          return type === "label" ? (
            <span className="s-success">
              <Divider type="vertical" /> Realizado
            </span>
          ) : (
            <Icon
              type="check-circle"
              theme="filled"
              className="icon-timeline-success"
            />
          );
        if (
          obj.surgery_probable_date &&
          obj.owner &&
          (obj.anesthesia_start && !obj.anesthesia_end)
        )
          return type === "label" ? (
            <span className="s-running">
              <Divider type="vertical" /> Em curso
            </span>
          ) : (
            <Badge status="processing" className="icon-timeline-processing" />
          );
      }
    } catch (error) {
      return "";
    }
  };

  routeProcedurePre = route => {
    if (route === 'pre') {
      mixPanelService.tracking(mixPanelService.MAPA_ADD_PRE);
      if (
        JSON.parse(localStorage.getItem("selected-institution")) &&
        JSON.parse(localStorage.getItem("selected-institution")).is_group
      ) {
        this.setState({
          modalVisibleInstitutions: true,
          checkInstituionRoute: "pre"
        });
      } else {
        this.props.history.push({
          pathname: pathRoutes.newSchedulePre
        });
      }
    } else {
      mixPanelService.tracking(mixPanelService.MAPA_ADD_TRANS);
      if (
        JSON.parse(localStorage.getItem("selected-institution")) &&
        JSON.parse(localStorage.getItem("selected-institution")).is_group
      ) {
        this.setState({
          modalVisibleInstitutions: true,
          checkInstituionRoute: "schedule"
        });
      } else {
        this.props.history.push({
          pathname: pathRoutes.newSchedule
        });
      }
    }
  };

  setTableImport = table => {
    const tableName =
      table === "surgical-map"
        ? "Procedimentos cirúrgicos"
        : "Consultas pré-anestésicas";
    this.setState({
      importConditionTable: table,
      importConditionTableName: tableName
    });
  };

  setMixPanel = (e) => {
    if (e.target.value == 1) {
      mixPanelService.tracking(mixPanelService.MAPA_SELECIONAR_PRE);
    } else {
      mixPanelService.tracking(mixPanelService.MAPA_SELECIONAR_TRANS);
    }
  };

  setMixPanelScheduling = () => {
    mixPanelService.tracking(mixPanelService.MAPA_ABRIR_AGENDAMENTO);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { cancellation_reason } = this.props.selects;

    let { modalProcedure } = this.state;

    let totalPreAnesthetics = this.state.dataPreAnesthetics.length
      ? this.state.dataPreAnesthetics.reduce((a, b) => a + b.data.length, 0)
      : 0;
    let totalSurgeries = this.state.dataSurgeries.length
      ? this.state.dataSurgeries.reduce((a, b) => a + b.data.length, 0)
      : 0;

    const menuAdd = (
      <Menu onClick={e => this.routeProcedurePre(e.key)}>
        <Menu.Item key="pre">
          <Icon component={IconTabPreSvg} />
          &nbsp;&nbsp;PRÉ-ANESTÉSICO&nbsp;&nbsp;
        </Menu.Item>
        <Menu.Item key="schedule">
          <Icon component={IconTabProcedureSvg} />
          &nbsp;&nbsp;PROCEDIMENTO&nbsp;&nbsp;
        </Menu.Item>
      </Menu>
    );

    return (
      <Content>
        <Row>
          <Col xs={24} lg={24} sm={24} xl={0}>
            <Dropdown overlay={menuAdd} onVisibleChange={this.addArrowChange}>
              <Button
                type="primary"
                size="large"
                shape="circle"
                className="add-procedure"
              >
                <Icon type="plus" fill="#ffffff" />
              </Button>
            </Dropdown>
          </Col>

          <Col xs={24} lg={24} sm={24} xl={16}>
            <h1 className="title">Agendamentos</h1>
          </Col>
          <Col xs={0} lg={0} sm={0} xl={8}>
            <div style={{ float: "right" }}>
              {
                <ImportExcel
                  table={this.state.importConditionTable}
                  type="map"
                  title={this.state.importConditionTableName}
                  setTableImport={this.setTableImport}
                  reload={this.loadMap}
                />
              }
              <Dropdown overlay={menuAdd} onVisibleChange={this.addArrowChange}>
                <Button type="primary" shape="round" className="add-procedure">
                  ADICIONAR &nbsp;
                  <Icon type={this.state.addDropdownArrow} />
                </Button>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <Row>
          <Row>
            <Col xs={24} lg={24} sm={24} x={24}>
              <Search
                placeholder="Buscar por paciente ou CPF"
                onChange={this.SearchScheduling}
                style={{ width: "100%", marginRight: 10 }}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col xs={24} lg={24} sm={24} xl={0} style={{ paddingRight: 10 }}>
              {!this.state.is_search && <FilterDate />}
            </Col>

            <Col xs={24} lg={24} sm={24} xl={18}>
              <Radio.Group
                defaultValue='1'
                buttonStyle='solid'
                onChange={(e) => {
                  this.setMixPanel(e);
                  this.setState({ keyTab: e.target.value });
                }}
              >
                <Radio.Button className="group-pre" value="1">
                  &nbsp;&nbsp; <Icon component={IconTabPreSvg} />{" "}
                  PRÉ-ANESTÉSICOS &nbsp;{" "}
                  <Badge
                    count={totalPreAnesthetics}
                    style={{
                      backgroundColor: "#fff",
                      color: "#999",
                      boxShadow: "0 0 0 1px #d9d9d9 inset"
                    }}
                  />{" "}
                  &nbsp;{" "}
                </Radio.Button>
                <Radio.Button className="group-surgery" value="2">
                  &nbsp;&nbsp; <Icon component={IconTabProcedureSvg} />{" "}
                  PROCEDIMENTOS &nbsp;{" "}
                  <Badge
                    count={totalSurgeries}
                    style={{
                      backgroundColor: "#fff",
                      color: "#999",
                      boxShadow: "0 0 0 1px #d9d9d9 inset"
                    }}
                  />
                  &nbsp;
                </Radio.Button>
              </Radio.Group>
            </Col>
            <Col xs={0} lg={0} sm={0} xl={6} style={{ paddingRight: 10 }}>
              {!this.state.is_search && <FilterDate />}
            </Col>
          </Row>

          <Row>
            <Tabs className="tab-map" activeKey={this.state.keyTab}>
              <TabPane tab="" key="1">
                {this.state.dataLoading ? (
                  <Skeleton active paragraph={{ rows: 8 }} avatar />
                ) : (
                  <div>
                    {this.state.dataPreAnesthetics.length ? (
                      this.state.dataPreAnesthetics.map(item => {
                        return (
                          <div key={item.date}>
                            <p className="title-time-line">
                              {this.checkDay(moment(item.date))}
                            </p>

                            {item.data.length ? (
                              <Timeline style={{ padding: 10 }}>
                                {item.data
                                  .sort(
                                    arrayHelp.sortArray("pre_probable_date")
                                  )
                                  .map(procedure => {
                                    return (
                                      <Timeline.Item
                                        key={procedure.id}
                                        dot={this.renderStatusScheduling(
                                          "pre",
                                          "icon",
                                          procedure
                                        )}
                                      >
                                        <Card
                                          className="margin-body-time-line card-pre"
                                          onClick={() => {
                                            this.setMixPanelScheduling();
                                            this.openModal(procedure, 'pre');
                                          }}
                                        >
                                          <div className="scheduling-list ant-list-item-time-line">
                                            <div className="ant-list-item-meta">
                                              <div className="ant-list-item-meta-avatar pre">
                                                <span className="hour">
                                                  {moment(
                                                    procedure.pre_probable_date
                                                  ).format("HH:mm")}
                                                </span>
                                              </div>
                                              <div className="ant-list-item-meta-content">
                                                <h4 className="ant-list-item-meta-title">
                                                  {stringsHelp.formatName(
                                                    procedure.patient_name
                                                  )}
                                                  {procedure.institution || procedure.clinic_name ? (
                                                    <span>
                                                      &nbsp;&nbsp;
                                                      <Tag
                                                        className="tag-custom-round"
                                                        color="rgb(221, 246, 246)"
                                                        style={{
                                                          marginBottom: 3
                                                        }}
                                                      >
                                                        {" "}
                                                        <Icon type="home" />
                                                        &nbsp;&nbsp;
                                                        <span>
                                                          {" "}
                                                          {stringsHelp.maxLength(
                                                            stringsHelp.formatName(
                                                              procedure.clinic_name ?
                                                                procedure.clinic_name
                                                                :
                                                              procedure
                                                                .institution
                                                                .name
                                                            ),
                                                            40,
                                                            true
                                                          )}
                                                        </span>
                                                      </Tag>
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </h4>
                                                <div className="ant-list-item-meta-description">
                                                  <div>{procedure.name}</div>
                                                </div>
                                              </div>
                                            </div>

                                            {procedure.documento_clinico ? (
                                              <div
                                                style={{
                                                  position: "absolute",
                                                  right: 65,
                                                  top: 6
                                                }}
                                              >
                                                {procedure.documento_clinico
                                                  .error ? (
                                                  <Tooltip
                                                    placement="left"
                                                    title={
                                                      <span>
                                                        Falha na integração:{" "}
                                                        {
                                                          procedure
                                                            .documento_clinico
                                                            .error.value
                                                        }{" "}
                                                      </span>
                                                    }
                                                  >
                                                    <Tag
                                                      color="red"
                                                      style={{
                                                        fontSize: 11,
                                                        border: "none",
                                                        padding:
                                                          "0 10px 0px 10px"
                                                      }}
                                                    >
                                                      <Icon type="arrows-alt" />{" "}
                                                      PDF{" "}
                                                    </Tag>
                                                  </Tooltip>
                                                ) : (
                                                  <Tag
                                                    color="green"
                                                    style={{
                                                      fontSize: 11,
                                                      border: "none",
                                                      padding: "0 10px 0px 10px"
                                                    }}
                                                  >
                                                    <Icon type="arrows-alt" />{" "}
                                                    PDF{" "}
                                                  </Tag>
                                                )}
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                            <div className="details-map">
                                              <div className="status-scheduling">
                                                {this.renderStatusScheduling(
                                                  "pre",
                                                  "label",
                                                  procedure
                                                )}
                                              </div>
                                              {procedure.owner && (
                                                <Avatar>
                                                  {stringsHelp.avatar(
                                                    procedure.owner
                                                  )}
                                                </Avatar>
                                              )}
                                            </div>
                                          </div>
                                        </Card>
                                      </Timeline.Item>
                                    );
                                  })}
                              </Timeline>
                            ) : (
                              <Card>
                                <Empty />
                              </Card>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <Card>
                        <Empty />
                      </Card>
                    )}
                  </div>
                )}
              </TabPane>
              <TabPane tab="" key="2">
                {this.state.dataLoading ? (
                  <Skeleton active paragraph={{ rows: 8 }} avatar />
                ) : (
                  <div>
                    {this.state.dataSurgeriesRealTime.length
                      ? this.state.dataSurgeriesRealTime.map(procedure => {
                          return (
                            <Card
                              className="margin-body-time-line card-surgery-realtime"
                              onClick={() => {
                                this.setMixPanelScheduling();
                                this.openModal(procedure, 'trans');
                              }}
                            >
                              <p className="title-surgery-realtime">
                                <Badge status="processing" /> Procedimento em
                                andamento
                              </p>
                              <div className="scheduling-list ant-list-item-time-line">
                                <div className="ant-list-item-meta">
                                  <div className="ant-list-item-meta-avatar realtime">
                                    <span className="hour">
                                      <Timer initialTime={procedure.duration}>
                                        {() => (
                                          <React.Fragment>
                                            <Timer.Hours
                                              formatValue={value =>
                                                `${
                                                  value < 10
                                                    ? `0${value}`
                                                    : value
                                                }`
                                              }
                                            />
                                            :
                                            <Timer.Minutes
                                              formatValue={value =>
                                                `${
                                                  value < 10
                                                    ? `0${value}`
                                                    : value
                                                }`
                                              }
                                            />
                                            :
                                            <Timer.Seconds
                                              formatValue={value =>
                                                `${
                                                  value < 10
                                                    ? `0${value}`
                                                    : value
                                                }`
                                              }
                                            />
                                          </React.Fragment>
                                        )}
                                      </Timer>
                                    </span>
                                  </div>
                                  <div className="ant-list-item-meta-content">
                                    <h4 className="ant-list-item-meta-title">
                                      {stringsHelp.formatName(
                                        procedure.patient_name
                                      )}
                                      {procedure.institution ? (
                                        <span>
                                          &nbsp;&nbsp;
                                          <Tag
                                            className="tag-custom-round"
                                            color="rgb(221, 246, 246)"
                                          >
                                            {" "}
                                            <Icon type="home" />
                                            &nbsp;&nbsp;
                                            <span>
                                              {" "}
                                              {stringsHelp.maxLength(
                                                stringsHelp.formatName(
                                                  procedure.institution.name
                                                ),
                                                40,
                                                true
                                              )}
                                            </span>
                                          </Tag>
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </h4>
                                    <div className="ant-list-item-meta-description">
                                      <div>{procedure.name}</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="details-map">
                                  {procedure.owner && (
                                    <Avatar>
                                      {stringsHelp.avatar(procedure.owner)}
                                    </Avatar>
                                  )}
                                </div>
                              </div>
                            </Card>
                          );
                        })
                      : ""}

                    {this.state.dataSurgeries.length ? (
                      this.state.dataSurgeries.map(item => {
                        return (
                          <div key={item.date}>
                            <p className="title-time-line">
                              {this.checkDay(moment(item.date))}
                            </p>

                            {item.data.length ? (
                              <Timeline style={{ padding: 10 }}>
                                {item.data
                                  .sort(
                                    arrayHelp.sortArray("surgery_probable_date")
                                  )
                                  .map(procedure => {
                                    return (
                                      <Timeline.Item
                                        key={procedure.id}
                                        dot={this.renderStatusScheduling(
                                          "procedure",
                                          "icon",
                                          procedure
                                        )}
                                      >
                                        <Card
                                          className="margin-body-time-line card-surgery"
                                          onClick={() =>
                                            this.openModal(procedure, "trans")
                                          }
                                        >
                                          <div className="scheduling-list ant-list-item-time-line">
                                            <div className="ant-list-item-meta">
                                              <div className="ant-list-item-meta-avatar">
                                                <span className="hour">
                                                  {moment(
                                                    procedure.surgery_probable_date
                                                  ).format("HH:mm")}
                                                </span>
                                              </div>
                                              <div className="ant-list-item-meta-content">
                                                <h4 className="ant-list-item-meta-title">
                                                  {stringsHelp.formatName(
                                                    procedure.patient_name
                                                  )}
                                                  {procedure.institution ? (
                                                    <span>
                                                      &nbsp;&nbsp;
                                                      <Tag
                                                        className="tag-custom-round"
                                                        color="rgb(221, 246, 246)"
                                                        style={{
                                                          marginBottom: 3
                                                        }}
                                                      >
                                                        {" "}
                                                        <Icon type="home" />
                                                        &nbsp;&nbsp;
                                                        <span>
                                                          {" "}
                                                          {stringsHelp.maxLength(
                                                            stringsHelp.formatName(
                                                              procedure
                                                                .institution
                                                                .name
                                                            ),
                                                            40,
                                                            true
                                                          )}
                                                        </span>
                                                      </Tag>
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </h4>
                                                <div className="ant-list-item-meta-description">
                                                  <div>{procedure.name}</div>
                                                </div>
                                              </div>
                                            </div>

                                            {procedure.documento_clinico ? (
                                              <div
                                                style={{
                                                  position: "absolute",
                                                  right: 65,
                                                  top: 6
                                                }}
                                              >
                                                {procedure.documento_clinico
                                                  .error ? (
                                                  <Tooltip
                                                    placement="left"
                                                    title={
                                                      <span>
                                                        Falha na integração:{" "}
                                                        {
                                                          procedure
                                                            .documento_clinico
                                                            .error.value
                                                        }{" "}
                                                      </span>
                                                    }
                                                  >
                                                    <Tag
                                                      color="red"
                                                      style={{
                                                        fontSize: 11,
                                                        border: "none",
                                                        padding:
                                                          "0 10px 0px 10px"
                                                      }}
                                                    >
                                                      <Icon type="arrows-alt" />{" "}
                                                      PDF{" "}
                                                    </Tag>
                                                  </Tooltip>
                                                ) : (
                                                  <Tag
                                                    color="green"
                                                    style={{
                                                      fontSize: 11,
                                                      border: "none",
                                                      padding: "0 10px 0px 10px"
                                                    }}
                                                  >
                                                    <Icon type="arrows-alt" />{" "}
                                                    PDF{" "}
                                                  </Tag>
                                                )}
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                            <div className="details-map">
                                              <div className="status-scheduling">
                                                {this.renderStatusScheduling(
                                                  "procedure",
                                                  "label",
                                                  procedure
                                                )}
                                              </div>
                                              {procedure.owner && (
                                                <Avatar>
                                                  {stringsHelp.avatar(
                                                    procedure.owner
                                                  )}
                                                </Avatar>
                                              )}
                                            </div>
                                          </div>
                                        </Card>
                                      </Timeline.Item>
                                    );
                                  })}
                              </Timeline>
                            ) : (
                              <Card>
                                <Empty />
                              </Card>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <Card>
                        <Empty />
                      </Card>
                    )}
                  </div>
                )}
              </TabPane>
            </Tabs>
          </Row>
        </Row>

        <ModalCheckInstitution
          visible={this.state.modalVisibleInstitutions}
          route={this.state.checkInstituionRoute}
          setVisible={this.setModalVisibleCheckInstitution}
        />

        <Modal
          title="Agendamento"
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          onOk={() =>
            modalProcedure && modalProcedure.user_id > 1
              ? null
              : this.handleOk(modalProcedure.id)
          }
          buttonOkText={
            modalProcedure && modalProcedure.user_id > 1 ? "" : "EDITAR"
          }
          buttonOkType="secondary"
        >
          {modalProcedure ? (
            <Row>
              <Col span={24} className="modal-scheduling-view">
                <Col span={24} className="modal-scheduling-view-title">
                  <span>Paciente</span>
                  {modalProcedure.cancellation_date && (
                    <span style={{ float: "right" }}>
                      {this.renderStatusScheduling(
                        "pre",
                        "label",
                        modalProcedure
                      )}
                    </span>
                  )}
                </Col>
                <Col span={24} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">NOME</span>
                  <span>
                    {stringsHelp.formatName(modalProcedure.patient.name)}
                  </span>
                </Col>
                <Col xs={24} sm={8} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">CPF</span>
                  <span>
                    {modalProcedure.patient.cpf
                      ? modalProcedure.patient.cpf
                      : "-"}
                  </span>
                </Col>
                <Col xs={24} sm={16} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">
                    IDADE
                  </span>
                  <span>
                    {modalProcedure.patient.birth_date
                      ? dates.age(
                          moment(modalProcedure.patient.birth_date).format(
                            "DD/MM/YYYY"
                          )
                        )
                      : "-"}
                  </span>
                </Col>
                <Col xs={24} sm={16} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">
                    ALERGIAS
                  </span>
                  <span>
                    {modalProcedure.patient.allergies
                      ? modalProcedure.patient.allergies
                      : "-"}
                  </span>
                </Col>
              </Col>
                  <Col span={24} className="modal-scheduling-view">
                    <Col span={24} className="modal-scheduling-view-title">
                      <span>Local</span>
                    </Col>
                    <Col span={24} className="modal-scheduling-view-item">
                     <span className="modal-scheduling-view-item-title">
                      INSTITUIÇÃO
                     </span>
                     <span>
                       {stringsHelp.formatName(
                          this.state.modalType === 'pre'
                            ? (modalProcedure.clinic ? modalProcedure.clinic.name : modalProcedure.institution.name)
                            : (modalProcedure.institution ? modalProcedure.institution.name : '-'),
                          true
                        )}
                      </span>
                    </Col>

                    {
                      this.state.modalType === 'trans' && (
                        <React.Fragment>
                          <Col xs={24} sm={8} className="modal-scheduling-view-item">
                            <span className="modal-scheduling-view-item-title">
                              CENTRO CIRÚRGICO
                            </span>
                            <span>
                              {modalProcedure.surgery_center
                                ? modalProcedure.surgery_center.name
                                : "-"}
                            </span>
                          </Col>
                          <Col xs={24} sm={8} className="modal-scheduling-view-item">
                            <span className="modal-scheduling-view-item-title">SALA</span>
                            <span>
                              {modalProcedure.surgery_center_room
                                ? modalProcedure.surgery_center_room.name
                                : "-"}
                            </span>
                          </Col>
                          <Col xs={24} sm={8} className="modal-scheduling-view-item">
                            <span className="modal-scheduling-view-item-title">
                              ACOMODAÇÃO
                            </span>
                            <span>
                              {modalProcedure.room
                                ? modalProcedure.room.room_i18n[0].name
                                : "-"}
                            </span>
                          </Col>
                          <Col span={24} className="modal-scheduling-view">
                            <Col span={24} className="modal-scheduling-view-title">
                              <span>Atendimento</span>
                            </Col>
                            <Col xs={24} sm={8} className="modal-scheduling-view-item">
                              <span className="modal-scheduling-view-item-title">
                                Nº DE PRONTUÁRIO
                              </span>
                              <span>
                                {modalProcedure.medical_record_number
                                  ? modalProcedure.medical_record_number
                                  : "-"}
                              </span>
                            </Col>
                            <Col xs={24} sm={16} className="modal-scheduling-view-item">
                              <span className="modal-scheduling-view-item-title">
                                Nº DE ATENDIMENTO
                              </span>
                              <span>
                                {modalProcedure.register_number
                                  ? modalProcedure.register_number
                                  : "-"}
                              </span>
                            </Col>
                            <Col xs={24} sm={8} className="modal-scheduling-view-item">
                              <span className="modal-scheduling-view-item-title">
                                CONVÊNIO
                              </span>
                                            <span>
                                {modalProcedure.medical_plan
                                  ? modalProcedure.medical_plan.name
                                  : "-"}
                              </span>
                            </Col>
                            <Col xs={24} sm={8} className="modal-scheduling-view-item">
                              <span className="modal-scheduling-view-item-title">
                                MATRÍCULA CONVÊNIO
                              </span>
                              <span>
                                {modalProcedure.patient_medical_plan_code
                                  ? modalProcedure.patient_medical_plan_code
                                  : "-"}
                              </span>
                            </Col>

                            <Col xs={24} sm={8} className="modal-scheduling-view-item">
                              <span className="modal-scheduling-view-item-title">
                                SENHA AUTORIZAÇÃO
                              </span>
                              <span>
                                {modalProcedure.patient_medical_plan_password
                                  ? modalProcedure.patient_medical_plan_password
                                  : "-"}
                              </span>
                            </Col>
                          </Col>

                          <Col span={24} className="modal-scheduling-view">
                            <Col span={24} className="modal-scheduling-view-title">
                              <span>Cirurgias</span>
                            </Col>
                          </Col>
                          <Col span={24} className="modal-scheduling-view-item">
                            <span className="modal-scheduling-view-item-title">
                              TIPO DE CIRURGIA
                            </span>
                            <span>
                              {modalProcedure.surgery_type
                                ? modalProcedure.surgery_type.surgery_type_i18n[0].name
                                : "-"}
                            </span>
                          </Col>
                          <Col span={24} className="modal-scheduling-view-item">
                            <span className="modal-scheduling-view-item-title">
                              CIRURGIAS
                            </span>
                              {modalProcedure.laterality_procedures.length ? (
                                modalProcedure.laterality_procedures.map(laterality => {
                                  let procedureName = stringsHelp.firstLetterUpper(
                                    laterality.surgical_procedure.name
                                  );
                                  return (
                                    <span key={laterality.id}>
                                      {laterality.surgical_procedure.code} - {procedureName}{" "}
                                      - {laterality.laterality}
                            </span>
                                );
                              })
                            ) : (
                              <span key={modalProcedure.id}>{modalProcedure.name}</span>
                            )}
                          </Col>
                        </React.Fragment>
                      )
                    }
                  </Col>
              <Col span={24} className="modal-scheduling-view">
                <Col span={24} className="modal-scheduling-view-title">
                  <span>Equipe</span>
                </Col>
                {this.state.modalType === 'trans' && (
                  <Col span={24} className="modal-scheduling-view-item">
                    <span className="modal-scheduling-view-item-title">
                      CIRURGIÕES
                    </span>
                      <span>
                      {this.state.modalProcedure.active_surgeons.length
                        ? this.state.modalProcedure.active_surgeons
                        .map(surgeon => {
                          return (
                            "Dr(a). " +
                            stringsHelp.formatName(surgeon.surgeon.name, true)
                          );
                        })
                        .join(",")
                        : "-"}
                    </span>
                  </Col>
                )}
                <Col span={24} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">
                    ANESTESIOLOGISTAS
                  </span>
                  {this.state.modalProcedure.active_anaesthetists.length ? (
                    this.state.modalProcedure.active_anaesthetists.filter((v) => {
                      if (this.state.modalType === 'trans') {
                        return !!v.is_trans;
                      }

                      return !!v.is_pre;
                    }).map(
                      (anaesthetist) => (
                        <span
                          key={anaesthetist.id}
                          style={{ padding: '5px 0px 5px 0px' }}
                        >
                          <Avatar
                            style={{ width: '30px', marginRight: '9px' }}
                          >
                            {stringsHelp.avatar(
                              anaesthetist.anaesthetist.name,
                            )}
                          </Avatar>
                          {`Dr(a). ${
                            stringsHelp.formatName(
                              anaesthetist.anaesthetist.name,
                              true,
                            )}`}
                          <ButtonFixAnaesthetist
                            procedureId={modalProcedure.id}
                            anestesistaId={anaesthetist.anaesthetist_id}
                          />
                        </span>
                      ),
                    )
                  ) : (
                    <span>-</span>
                  )}
                </Col>
                {this.state.modalProcedure.cancellation_date && (
                  <Col span={24} className="modal-scheduling-view">
                    <Col
                      xs={24}
                      sm={12}
                      className="modal-scheduling-view-item"
                      style={{ color: "#df4279" }}
                    >
                      <span className="modal-scheduling-view-item-title">
                        DATA DE CANCELAMENTO
                      </span>
                      <span>
                        {this.state.modalProcedure.cancellation_date
                          ? moment(modalProcedure.cancellation_date).format(
                              "DD/MM/YYYY"
                            )
                          : "-"}
                      </span>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      className="modal-scheduling-view-item"
                      style={{ marginTop: 17, color: "#df4279" }}
                    >
                      <span className="modal-scheduling-view-item-title">
                        MOTIVO DE CANCELAMENTO
                      </span>
                      <span>
                        {this.state.modalProcedure
                          .cancellation_reason_description
                          ? this.state.modalProcedure
                              .cancellation_reason_description
                          : this.state.modalProcedure.cancellation_reason
                              .cancellation_reason_i18n[0].description}
                      </span>
                    </Col>
                  </Col>
                )}

                {this.state.modalType === "pre" &&
                  (!this.state.modalProcedure.cancellation_date &&
                    !this.state.modalProcedure.create_pre_date &&
                    this.state.modalProcedure.user_id === 1) && (
                    <Col
                      span={24}
                      style={{ textAlign: "center", marginTop: 5 }}
                    >
                      <Popover
                        visible={this.state.popoverCancellationVisible}
                        onVisibleChange={e =>
                          this.setState({ popoverCancellationVisible: e })
                        }
                        content={
                          <div style={{ padding: 5, width: 510 }}>
                            <Form
                              onSubmit={this.handleSubmitCancellationReason}
                            >
                              <Row>
                                <Col span={24}>
                                  {!this.state.newCancellationReason ? (
                                    <FormItem label="MOTIVO">
                                      {getFieldDecorator(
                                        "cancellation_reason_id",
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message:
                                                "Selecione o motivo do cancelamento"
                                            }
                                          ]
                                        }
                                      )(
                                        <Select
                                          showSearch
                                          filterOption={(input, option) => {
                                            if (
                                              typeof option.props.children !==
                                              "string"
                                            ) {
                                              return false;
                                            } else {
                                              return (
                                                option.props.children
                                                  .toLowerCase()
                                                  .indexOf(
                                                    input.toLowerCase()
                                                  ) >= 0
                                              );
                                            }
                                          }}
                                          style={{ width: 500 }}
                                          loading={
                                            cancellation_reason
                                              ? cancellation_reason.loading
                                              : true
                                          }
                                        >
                                          {!cancellation_reason
                                            ? null
                                            : cancellation_reason.data.map(
                                                item => {
                                                  return (
                                                    <OptGroup
                                                      key={
                                                        item
                                                          .cancellation_reason_type_i18n[0]
                                                          .id
                                                      }
                                                      label={
                                                        item
                                                          .cancellation_reason_type_i18n[0]
                                                          .description
                                                      }
                                                    >
                                                      {item.cancellation_reasons.map(
                                                        d => (
                                                          <Option
                                                            key={
                                                              d
                                                                .cancellation_reason_i18n[0]
                                                                .id
                                                            }
                                                            value={
                                                              d
                                                                .cancellation_reason_i18n[0]
                                                                .id
                                                            }
                                                          >
                                                            {
                                                              d
                                                                .cancellation_reason_i18n[0]
                                                                .description
                                                            }
                                                          </Option>
                                                        )
                                                      )}
                                                    </OptGroup>
                                                  );
                                                }
                                              )}
                                        </Select>
                                      )}
                                    </FormItem>
                                  ) : (
                                    <FormItem label="ADICIONAR OUTRO MOTIVO">
                                      {getFieldDecorator(
                                        "cancellation_reason_description",
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message:
                                                "Digite o motivo do cancelamento"
                                            }
                                          ]
                                        }
                                      )(<Input />)}
                                    </FormItem>
                                  )}

                                  {!this.state.newCancellationReason ? (
                                    <Button
                                      style={{
                                        fontSize: 11,
                                        width: "100%",
                                        textAlign: "left",
                                        fontWeight: 500,
                                        padding: "2px 11px"
                                      }}
                                      type="link"
                                      size="small"
                                      onClick={() =>
                                        this.setState({
                                          newCancellationReason: true
                                        })
                                      }
                                    >
                                      Adicionar outro motivo
                                    </Button>
                                  ) : (
                                    <Button
                                      style={{
                                        fontSize: 11,
                                        width: "100%",
                                        textAlign: "left",
                                        fontWeight: 500,
                                        padding: "2px 11px"
                                      }}
                                      type="link"
                                      size="small"
                                      onClick={() =>
                                        this.setState({
                                          newCancellationReason: false
                                        })
                                      }
                                    >
                                      Selecionar motivo
                                    </Button>
                                  )}
                                </Col>

                                <Col span={24}>
                                  <FormItem label="">
                                    <Button
                                      type="primary"
                                      shape="round"
                                      className="add-procedure"
                                      htmlType={
                                        this.state.loadingCancellation
                                          ? "button"
                                          : "submit"
                                      }
                                      loading={this.state.loadingCancellation}
                                    >
                                      Confirmar
                                    </Button>
                                  </FormItem>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        }
                        title=""
                        trigger="click"
                      >
                        <Button className="btn-custom-danger" shape="round">
                          Cancelar este procedimento
                        </Button>
                      </Popover>
                    </Col>
                  )}

                {this.state.modalType === "trans" &&
                  !this.state.modalProcedure.cancellation_date &&
                  !this.state.modalProcedure.procedure_closed_date &&
                  this.state.modalProcedure.user_id === 1 && (
                    <Col
                      span={24}
                      style={{ textAlign: "center", marginTop: 5 }}
                    >
                      <Popover
                        visible={this.state.popoverCancellationVisible}
                        onVisibleChange={e =>
                          this.setState({ popoverCancellationVisible: e })
                        }
                        content={
                          <div style={{ padding: 5, width: 510 }}>
                            <Form
                              onSubmit={this.handleSubmitCancellationReason}
                            >
                              <Row>
                                <Col span={24}>
                                  {!this.state.newCancellationReason ? (
                                    <FormItem label="MOTIVO">
                                      {getFieldDecorator(
                                        "cancellation_reason_id",
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message:
                                                "Selecione o motivo do cancelamento"
                                            }
                                          ]
                                        }
                                      )(
                                        <Select
                                          showSearch
                                          filterOption={(input, option) => {
                                            if (
                                              typeof option.props.children !==
                                              "string"
                                            ) {
                                              return false;
                                            } else {
                                              return (
                                                option.props.children
                                                  .toLowerCase()
                                                  .indexOf(
                                                    input.toLowerCase()
                                                  ) >= 0
                                              );
                                            }
                                          }}
                                          style={{ width: 500 }}
                                          loading={
                                            cancellation_reason
                                              ? cancellation_reason.loading
                                              : true
                                          }
                                        >
                                          {!cancellation_reason
                                            ? null
                                            : cancellation_reason.data.map(
                                                item => {
                                                  return (
                                                    <OptGroup
                                                      key={
                                                        item
                                                          .cancellation_reason_type_i18n[0]
                                                          .id
                                                      }
                                                      label={
                                                        item
                                                          .cancellation_reason_type_i18n[0]
                                                          .description
                                                      }
                                                    >
                                                      {item.cancellation_reasons.map(
                                                        d => (
                                                          <Option
                                                            key={
                                                              d
                                                                .cancellation_reason_i18n[0]
                                                                .id
                                                            }
                                                            value={
                                                              d
                                                                .cancellation_reason_i18n[0]
                                                                .id
                                                            }
                                                          >
                                                            {
                                                              d
                                                                .cancellation_reason_i18n[0]
                                                                .description
                                                            }
                                                          </Option>
                                                        )
                                                      )}
                                                    </OptGroup>
                                                  );
                                                }
                                              )}
                                        </Select>
                                      )}
                                    </FormItem>
                                  ) : (
                                    <FormItem label="ADICIONAR OUTRO MOTIVO">
                                      {getFieldDecorator(
                                        "cancellation_reason_description",
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message:
                                                "Digite o motivo do cancelamento"
                                            }
                                          ]
                                        }
                                      )(<Input />)}
                                    </FormItem>
                                  )}

                                  {!this.state.newCancellationReason ? (
                                    <Button
                                      style={{
                                        fontSize: 11,
                                        width: "100%",
                                        textAlign: "left",
                                        fontWeight: 500,
                                        padding: "2px 11px"
                                      }}
                                      type="link"
                                      size="small"
                                      onClick={() =>
                                        this.setState({
                                          newCancellationReason: true
                                        })
                                      }
                                    >
                                      Adicionar outro motivo
                                    </Button>
                                  ) : (
                                    <Button
                                      style={{
                                        fontSize: 11,
                                        width: "100%",
                                        textAlign: "left",
                                        fontWeight: 500,
                                        padding: "2px 11px"
                                      }}
                                      type="link"
                                      size="small"
                                      onClick={() =>
                                        this.setState({
                                          newCancellationReason: false
                                        })
                                      }
                                    >
                                      Selecionar motivo
                                    </Button>
                                  )}
                                </Col>

                                <Col span={24}>
                                  <FormItem label="">
                                    <Button
                                      type="primary"
                                      shape="round"
                                      className="add-procedure"
                                      htmlType={
                                        this.state.loadingCancellation
                                          ? "button"
                                          : "submit"
                                      }
                                      loading={this.state.loadingCancellation}
                                    >
                                      Confirmar
                                    </Button>
                                  </FormItem>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        }
                        title=""
                        trigger="click"
                      >
                        <Button className="btn-custom-danger" shape="round">
                          Cancelar este procedimento
                        </Button>
                      </Popover>
                    </Col>
                  )}
              </Col>
            </Row>
          ) : (
            <Row style={{ textAlign: "center", padding: 20 }}>
              <Spin />
            </Row>
          )}
        </Modal>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { scheduling, institutions, selects } = state;
  return {
    scheduling,
    institutions,
    selects
  };
}

const connected = connect(mapStateToProps)(Form.create()(Map));
export default connected;
