export const arrayHelp = {
    sortArray
  };
  
  function sortArray(property ){
    try {
  
      if(property.length > 1){
        let sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
      }
      else{
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            var result = (a < b) ? -1 : (a > b) ? 1 : 0;
            return result * sortOrder;
        }
      }
      
  
    } catch (error) {
      return '?';
    }
  }