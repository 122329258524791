import {drugsConstants} from '../constants';
import {drugsService} from "../services";

export const drugsActions = {
  getDrugs,
};

function getDrugs(filter, cancelToken) {
  return dispatch => {
    dispatch(request());

    return drugsService.getDrugs(filter, cancelToken)
      .then(drugs => {        
        dispatch(success(drugs));
        return drugs;
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: drugsConstants.GETDRUGS_REQUEST}
  }

  function success(data) {
    return {type: drugsConstants.GETDRUGS_SUCCESS, data}
  }

  function failure(error) {
    return {type: drugsConstants.GETDRUGS_FAILURE, error}
  }
}
