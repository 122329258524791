import React, { Component } from "react";

import {
  Layout,
  Row,
  Col,
  Tooltip,
  Icon,
  Table,
  Button,
  Input,
  Tag,
  Modal,
  Form,
  Select,
  notification,
  Divider,
} from "antd";
import { connect } from "react-redux";
import { selectsActions } from "../../actions";
import { surgicalTeamsActions } from "../../actions";
import { surgicalTeamsService } from "../../services";
import { stringsHelp } from "../../helpers";
import { CancelToken } from "axios";
import { mixPanelService } from '../../services/mixpanel.service';

const { Content } = Layout;
const FormItem = Form.Item;
const Option = Select.Option;
const { Search } = Input;

class SurgicalTeams extends Component {
  state = {
    modalVisible: false,
    actionNew: true,
    loadingSave: false, 
    confirmVisible: false,
    confirmLoading: false,
    delete_uuid: undefined,
    dataSurgicalTeams: []
  };

  cancelSource = CancelToken.source();

  load = () => {
    const { dispatch, surgicalTeams } = this.props;

    if (surgicalTeams.loading) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    dispatch(surgicalTeamsActions.getSurgicalTeams({}, this.cancelSource.token)).then(data => {
      this.setState({
        dataSurgicalTeams: data.data
      });
    });
    dispatch(selectsActions.getSurgicalTeamPositions());
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        
        let promiseSave;

        if (values.id) {
          this.setState({ loadingSave: true });

          promiseSave = surgicalTeamsService.putSurgicalTeam(values).then(result => {
            if (result.id > 0) {
              this.handleCancel();

              notification.success({
                message: "Profissional alterado com sucesso!"
              });

              this.load();
            }
          });

        } else {
          this.setState({ loadingSave: true });

          delete values.id;

          promiseSave = surgicalTeamsService.postSurgicalTeam(values).then(result => {
            if (result.id > 0) {
              this.handleCancel();

              notification.success({
                message: "Profissional cadastrado com sucesso!"
              });

              this.load();
            }
          });
        }

        promiseSave.catch(error => {
          console.log(error);
          this.setState({ loadingSave: false });
          notification.error({
            message: error
          });
        });
      }
    });
  };

  handleCancel = () => {
    const clearSurgicalTeam = {
      name: '',
      id: null,
      document: '',
      surgical_team_position_id: null
    };

    this.setState({ modalVisible: false, loadingSave: false});
    this.props.form.setFieldsValue(clearSurgicalTeam);

  };

  openModal = p_id => {    
    if (p_id) {
      mixPanelService.tracking(mixPanelService.EQUIPE_AUXILIAR_EDITAR);

      const surgicalTeamObject = this.props.surgicalTeams.data.find(
        s => s.id === p_id
      );

      const editSurgicalTeam = {
        name: surgicalTeamObject.name,
        id: surgicalTeamObject.id,
        document: surgicalTeamObject.document,
        surgical_team_position_id: surgicalTeamObject.surgical_team_position_id
      };

      this.setState({ modalVisible: true});
      this.props.form.setFieldsValue(editSurgicalTeam);

    } else {
      mixPanelService.tracking(mixPanelService.EQUIPE_AUXILIAR_ADD);

      const newSurgicalTeam = {
        name: '',
        id: null,
        document: '',
        surgical_team_position_id: null
      };

      this.setState({ modalVisible: true});
      this.props.form.setFieldsValue(newSurgicalTeam);
    }
  };

  componentDidMount() {
    mixPanelService.tracking(mixPanelService.MENU_EQUIPE_AUXILIAR);
    this.load();
  }

  handleRoomCancel = () => {
    const clearMedicalPlan = {
      surgery_center_id: null,
      name: "",
      id: null,
      uuid: null
    };

    this.setState({ modalVisible: false, loadingSave: false });
    this.props.form.setFieldsValue(clearMedicalPlan);
  };

  showConfirm = uuid => {
    this.setState({
      confirmVisible: true,
      confirmLoading: false,
      delete_uuid: uuid
    });
  };

  handleDelete = () => {
    let promiseSave;

    if (this.state.delete_uuid) {
      this.setState({ confirmLoading: true });

      promiseSave = surgicalTeamsService
        .deleteSurgicalTeam(this.state.delete_uuid)
        .then(result => {
          notification.success({
            message: "Profissional removido com sucesso!"
          });

          this.load();

          this.setState({
            confirmLoading: false,
            confirmVisible: false,
            delete_uuid: null
          });
        });

      promiseSave.catch(error => {
        console.log(error);
        notification.error({
          message: "Falha ao remover profissional, tente novamente mais tarde!"
        });
      });
    }
  };


  searchTable = e => {
    const { surgicalTeams } = this.props;
    const text = e.target.value;

    setTimeout(() => {
    if (text.length) {

      let newDataSurgicalTeams = surgicalTeams.data.filter(x => {
        return (
          stringsHelp
            .removeAccent(x.name.toString().toLowerCase())
            .includes(stringsHelp.removeAccent(text.toLowerCase())) ||
            (x.surgical_team_position.surgical_team_position_i18n.map(function(elem){
              return stringsHelp.removeAccent(elem.name).toLowerCase();
          }).join(",").includes(stringsHelp.removeAccent(text.toLowerCase())))
        );
      });
      this.setState({ dataSurgicalTeams: newDataSurgicalTeams });
    } else {
      this.setState({ dataSurgicalTeams: surgicalTeams.data });
    }
  }, 300);
  };


  render() {
    const { surgicalTeams } = this.props;
    const {surgical_team_position} = this.props.selects;

    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 }
      },
      colon: false
    };

    const columnsTableSurgicalTeams = [
      {
        title: "Nome",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "Documento",
        dataIndex: "document",
        key: "document"
      },
      {
        title: "Função",
        dataIndex: "surgical_team_position_id",
        key: "surgical_team_position_id",
        render: (surgical_team_position_id, record) => (
          <Tag>{record.surgical_team_position.surgical_team_position_i18n[0].name}</Tag>
        )
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        width: 80,
        render: (id, record) => (
          <span>
            <Tooltip title="Editar">
            <Button type='link' onClick={() => this.openModal(id)}>
              <Icon type="edit" />
            </Button>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Remover">
              <Button type='link' onClick={() => this.showConfirm(record.id)}>
                <Icon type="delete" />
              </Button>
            </Tooltip>
          </span>
        )
      }
    ];

    return (
      <Content>
        <Row>
          <Col xs={24} sm={0}>
            <button
              onClick={() => this.openModal(null)}
              className="ant-btn ant-btn-primary ant-btn-lg ant-btn-circle add-procedure"
            >
              <Icon type="plus" fill="#ffffff" />
            </button>
          </Col>
          <Col xs={24} lg={8}  sm={16}>
            <h1 className="title">
            Equipe Auxiliar 
            </h1>
          </Col>
          <Col xs={0} lg={16} sm={8}>
          <div style={{display: 'flex'}}>
          <Search
                          placeholder="Buscar"
                          onChange={this.searchTable}
                          style={{ width: "100%", marginRight: 10 }}
                        />
            <Button
              type="primary"
              shape="round"
              className="add-procedure"
              onClick={() => this.openModal()}
            >
              ADICIONAR
            </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Table
            size="midle"
            loading={surgicalTeams.loading}
            dataSource={this.state.dataSurgicalTeams}
            columns={columnsTableSurgicalTeams}
            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '30', '50', '100']}}
            footer={() => {
              const rowsDataSource =  !this.state.dataSurgicalTeams ? 0 : this.state.dataSurgicalTeams.length
              return <span>Total de registros: <Tag>{rowsDataSource}</Tag></span>;
             }}
          />
        </Row>

        <Modal
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          title={
            <div>
              <button
                className="modal-close"
                type="button"
                onClick={this.handleCancel}
              >
                <i className="icon-close" />
              </button>
              <span>
                {this.props.form.getFieldValue('id')
                  ? "Editar profissional"
                  : "Adicionar profissional"}
              </span>
            </div>
          }
          footer={[
            <Button
              key="submit"
              type="primary"
              form="formMedicalPlan"
              htmlType="submit"
              loading={this.state.loadingSave}
            >
              Salvar
            </Button>
          ]}
          bodyStyle={{ paddingTop: "21px" }}
          width="701px"
          zIndex={1024}
        >
          <Form onSubmit={this.handleSubmit} id="formMedicalPlan">
            <Row gutter={28}>
              <Col md={24} lg={24} xl={24}>
                {getFieldDecorator("id", {})(<span />)}
                
                <FormItem {...formItemLayout} label="NOME">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Preencha o nome do profissional."
                      }
                    ]
                  })(<Input autoFocus={true} />)}
                </FormItem>
              </Col>

              <Col md={24} lg={24} xl={10}>
                <FormItem {...formItemLayout} label="DOCUMENTO">
                  {getFieldDecorator("document", {})(<Input />)}
                </FormItem>
              </Col>

              <Col md={24} lg={24} xl={14}>
                <FormItem {...formItemLayout} label="FUNÇÃO">
                  {getFieldDecorator("surgical_team_position_id", {
                    rules: [
                      {
                        required: true,
                        message: "Selecione a função."
                      }
                    ]
                  })(
                    <Select>
                    {!surgical_team_position
                      ? null
                      : surgical_team_position.data.map(position => {
                          return (
                            <Option key={position.id} value={position.id}>
                              {position.surgical_team_position_i18n[0].name}
                            </Option>
                          );
                        })}
                  </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title=""
          visible={this.state.confirmVisible}
          onOk={() => this.handleDelete()}
          okText="Sim"
          okType="primary"
          cancelText="Não"
          confirmLoading={this.state.confirmLoading}
          onCancel={() => this.setState({ confirmVisible: false })}
        >
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            <span style={{ fontSize: 24, color: "#ffcd56", marginRight: 10 }}>
              <Icon type="question-circle" />
            </span>{" "}
            Confirma remover o profissional ?
          </p>
        </Modal>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { surgicalTeams, selects } = state;
  return {
    surgicalTeams,
    selects
  };
}

const connected = connect(mapStateToProps)(Form.create()(SurgicalTeams));
export default connected;
