import React, { Component } from "react";

import {
  Layout,
  Row,
  Col,
  Form,
  Tooltip,
  Icon,
  Table,
  Button,
  Input,
  Tag,
  Divider,
  Modal,
  notification
} from "antd";
import { Link } from "react-router-dom";
import { pathRoutes } from "../../routes";
import { connect } from "react-redux";
import { surgeonsActions } from "../../actions";
import { stringsHelp } from "../../helpers";
import { CancelToken } from "axios";
import { surgeonsService } from "../../services";
import ImportExcel from "../../components/ImportExcel";
import { mixPanelService } from '../../services/mixpanel.service';

const { Content } = Layout;
const { Search } = Input;

class Map extends Component {
  state = {
    modalVisible: false,
    confirmVisible: false,
    confirmLoading: false,
    delete_uuid: undefined,
    dataSurgeons: []
  };

  cancelSource = CancelToken.source();

  loadMap = () => {
    const { dispatch, surgeons } = this.props;

    if (surgeons.loading) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    dispatch(surgeonsActions.getSurgeons({}, this.cancelSource.token)).then(
      data => {
        this.setState({
          dataSurgeons: data
        });
      }
    );
  };

  componentDidMount() {
    mixPanelService.tracking(mixPanelService.MENU_CIRUGIAO);
    this.loadMap();
  }

  showConfirm = uuid => {
    this.setState({
      confirmVisible: true,
      confirmLoading: false,
      delete_uuid: uuid
    });
  };

  handleDelete = () => {
    let promiseSave;

    if (this.state.delete_uuid) {
      this.setState({ confirmLoading: true });

      promiseSave = surgeonsService
        .deleteSurgeon(this.state.delete_uuid)
        .then(result => {
          notification.success({
            message: "Cirurgião removido com sucesso!"
          });

          this.loadMap();

          this.setState({
            confirmLoading: false,
            confirmVisible: false,
            delete_id: null
          });
        });

      promiseSave.catch(error => {
        console.log(error);
        notification.error({
          message: "Falha ao remover cirurgião, tente novamente mais tarde!"
        });
      });
    }
  };

  searchTable = e => {
    const { surgeons } = this.props;
    const text = e.target.value;

    setTimeout(() => {
      if (text.length) {
        let newDataSurgeons = surgeons.data.filter(x => {
          return (
            stringsHelp
              .removeAccent(x.name.toString().toLowerCase())
              .includes(stringsHelp.removeAccent(text.toLowerCase())) ||
            (x.cpf &&
              stringsHelp
                .removeAccent(x.cpf.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase()))) ||
            (x.crm_number &&
              stringsHelp
                .removeAccent(x.crm_number.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase()))) ||
            (x.speciality &&
              stringsHelp
                .removeAccent(x.speciality.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase())))
          );
        });
        this.setState({ dataSurgeons: newDataSurgeons });
      } else {
        this.setState({ dataSurgeons: surgeons.data });
      }
    }, 300);
  };

  render() {
    const { surgeons } = this.props;
    const columnsTableSurgeons = [
      {
        title: "Nome",
        dataIndex: "name",
        key: "name",
        render: (name) => {
          return stringsHelp.firstLetterUpper(
            name,
            true
          )
        }
      },
      {
        title: "Especialidade",
        dataIndex: "specialities",
        key: "specialities",
        render: (specialities, record) => {          
          if (!specialities.length && record.speciality !== "") {
            return <Tag key={record.id}>{record.speciality}</Tag>
          } else {
            return specialities.map((item, index) => (
              <Tag key={item.id}>{item.name}</Tag>
            ))
          }
        }
      },
      {
        title: "CRM",
        dataIndex: "crm_number",
        key: "crm_number",
        render: (crm_number, record) => (
          <span>
            {crm_number} {record.crm_state_id ? ' - ' + record.state.acronym : '' }
          </span>
        )
      },
      {
        title: "CPF",
        dataIndex: "cpf",
        key: "cpf"
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "Celular",
        dataIndex: "phone_number",
        key: "phone_number"
      },
      {
        title: "",
        key: "actions",
        align: "right",
        dataIndex: "id",
        width: 80,
        render: (id, record) => (
          <span>
            <Tooltip title="Editar">
              <Link to={pathRoutes.editSurgeon.replace("/:id", "") + "/" + id}>
                <Icon type="edit" />
              </Link>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Remover">
              <Button type="link" onClick={() => this.showConfirm(record.uuid)}>
                <Icon type="delete" />
              </Button>
            </Tooltip>
          </span>
        )
      }
    ];

    return (
      <Content>
        <Row>
          <Col xs={24} sm={0}>
            <Link
              to={pathRoutes.newSurgeon}
              className="ant-btn ant-btn-primary ant-btn-lg ant-btn-circle add-procedure"
            >
              <Icon type="plus" fill="#ffffff" />
            </Link>
          </Col>

          <Col xs={24} sm={8}>
            <h1 className="title">Cirurgiões</h1>
          </Col>
          <Col xs={24} sm={0}>
            <Search
              placeholder="Buscar"
              onChange={this.searchTable}
              style={{ width: "100%", marginRight: 10, marginBottom: 10 }}
            />
            </Col>
          <Col xs={0} sm={16}>
          <div  style={{ display: "flex" }}>
            <Search
              placeholder="Buscar"
              onChange={this.searchTable}
              style={{ width: "100%", marginRight: 10 }}
            />
            {
              <ImportExcel
                table="surgeons"
                title="Cirurgiões"
                reload={this.loadMap}
                restrictGroup="true"
              />
            }
            <Link to={pathRoutes.newSurgeon} className="add-procedure">
              ADICIONAR
            </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Table
            size="midle"
            loading={surgeons.loading}
            dataSource={this.state.dataSurgeons}
            columns={columnsTableSurgeons}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "30", "50", "100"]
            }}
            footer={() => {
              const rowsDataSource = !this.state.dataSurgeons
                ? 0
                : this.state.dataSurgeons.length;
              return (
                <span>
                  Total de registros: <Tag>{rowsDataSource}</Tag>
                </span>
              );
            }}
          />
        </Row>

        <Modal
          title=""
          visible={this.state.confirmVisible}
          onOk={() => this.handleDelete()}
          okText="Sim"
          okType="primary"
          cancelText="Não"
          confirmLoading={this.state.confirmLoading}
          onCancel={() => this.setState({ confirmVisible: false })}
        >
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            <span style={{ fontSize: 24, color: "#ffcd56", marginRight: 10 }}>
              <Icon type="question-circle" />
            </span>{" "}
            Confirma remover o cirurgião ?
          </p>
        </Modal>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { surgeons } = state;
  return {
    surgeons
  };
}

const connected = connect(mapStateToProps)(Form.create()(Map));
export default connected;
