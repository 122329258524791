import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Api from "../../helpers/api";
import statesUF from '../../helpers/estatesBr.json'
import countries from '../../helpers/countries.json'
import { institutionService } from '../../services/institution.service';
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Layout,
  notification,
  Row,
  Select,
  Spin,
  Checkbox, 
  Upload,
  Avatar
} from 'antd';


const FormItem = Form.Item;
const { Content } = Layout;
const Option = Select.Option;

class Institution extends Component{
  state = {
    loading: true,
    institution: {},
    group: {},
    id: this.props.match.params.id,
    institution_logo: [],
    groups: [],
    logo64: null
  };

	componentDidMount() {
    Api.get('/web/institutions').then((institutions) => {
      this.setState({
        groups: institutions.filter((v) => v.is_group && v.active),
      });
      this.setState({loadingGroups: false});
    }).catch((err) => {
      notification.error("Ocorreu um erro ao consultar os grupos");
      console.error(err);
    });

    if (!this.state.id) return this.setState({loading: false});

    this.setState({loadingGroups: true});

    Api.get(`/preset/institutions/${this.state.id}`).then((resp) => {
      this.setState({
        institution: resp,
        group: resp.group_institution.length > 0 ? resp.group_institution[0] : {},
        loading: false
      });
    }).catch((err) => {
      console.error(err);
    });
	}

  handleInputChange = (event) => {
    const auxValues = { ...this.state.institution };
    auxValues[event.target.value] = event.target.checked;
    this.setState({ institution: auxValues})
  };

	handleSelectChange = (value) => {
	  this.setState({
      group: { group_id: value }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({loading: true});
        let institution = Object.assign(this.state.institution, values);
        let promiseSave;

        if (institution.id > 0) {
          promiseSave = Api.put(
            `/preset/institutions/${this.state.id}`,
            institution
          );
        } else {
          promiseSave = Api.post(`/preset/institutions`, institution);
        }

        promiseSave.then(async (institutionCreated) => {
          const { group, institution_logo } = this.state;

          if (institution_logo.length) {
            await institutionService.saveInstitutionLogo(
              institution.uuid ? institution.uuid : institutionCreated.uuid,
              this.state.institution_logo[0]
            );
          }

          await institutionService.associateToGroup(
            institution.id ? institution.id : institutionCreated.id,
            group.group_id
          );

          return true;
        }).then(() => {
          this.setState({loading: false});
          const action = institution.id > 0 ? 'alterada' : 'cadastrada';

          notification.success({
            message: `Instituição ${action} com sucesso`,
          });

          this.props.history.goBack();
        });

        promiseSave.catch(error => {
          console.error(error);
          notification.error({
            message: error,
          });
        });
      }
    });
  };

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  viewLogo = async file => {
    const result = await this.toBase64(file).catch(e => Error(e));
    if(result instanceof Error) {
      console.error('Error: ', result.message);
      return;
    }
    
    this.setState({logo64: result})
  }

  render() {
    const { institution, group } = this.state;
    const isEdit = !!this.state.id;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 }
      },
      colon: false
    };
    const { getFieldDecorator } = this.props.form;
    if(this.state.loading) return <Spin spinning={this.loading} />

    return (
      <Content>
        <Form {...formItemLayout} onSubmit={this.handleSubmit} >
          <Row>
            <Col xs={24} sm={0}>
              <Button
                type="primary"
                className="add-procedure"
                shape="circle"
                size="large"
                htmlType="submit"
                >
                <Icon type="check" fill="#ffffff" />
              </Button>
            </Col>
            <Col xs={24} sm={20}>
              <h1 className="title">
                {isEdit ? "Editar" : "Nova"} instituição { isEdit ? this.state.id : ""}
              </h1>
            </Col>
            <Col xs={0} sm={4}>
              <Button
                type="primary"
                className="add-procedure"
                htmlType="submit"
                loading={this.loading}>
                SALVAR
              </Button>
            </Col>
          </Row>

          <Row gutter={28}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="Nome da instituição">
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: "Preencha o nome da instituição."
                    }
                  ],
                  initialValue: institution.name
                })( <Input /> )}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="Cidade">
                {getFieldDecorator("city", {
                  initialValue: institution.city
                })( <Input /> )}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="UF">
                {getFieldDecorator("state_id", { initialValue: institution.state_id})(
                  <Select
                    showSearch
                    showAction={['focus', 'click']}
                    filterOption={
                      (input, option) =>
                        option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {statesUF.map(statesUF => (
                      <Option key={statesUF.id} value={statesUF.id}>
                        {statesUF.sigla}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="País">
                {getFieldDecorator("country_id", { initialValue: institution.country_id})(
                  <Select
                    showSearch
                    showAction={['focus', 'click']}
                    filterOption={
                      (input, option) =>
                        option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {countries.map(countries => (
                      <Option key={countries.id} value={countries.id}>
                        {countries.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="Endereço">
                {getFieldDecorator("address", {
                  initialValue: institution.address
                })( <Input /> )}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="Telefone">
                {getFieldDecorator("phone_number", {
                  initialValue: institution.phone_number
                })( <Input /> )}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="CPF/CNPJ">
                {getFieldDecorator("document", {
                  initialValue: institution.document
                })( <Input /> )}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="CNES">
                {getFieldDecorator("cnes_code", {
                  initialValue: institution.cnes_code
                })( <Input /> )}
              </FormItem>
            </Col>
            {institution.is_healthchess &&
            <>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="Login do healthchess">
                {getFieldDecorator("healthchess_login", {
                  initialValue: institution.healthchess_login
                })( <Input /> )}
              </FormItem>
              </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormItem label="senha do healthchess">
              {getFieldDecorator("healthchess_password", {
                initialValue: institution.healthchess_password
              })( <Input /> )}
            </FormItem>
            </Col>
          </>
            }
          </Row>
          <Row gutter={28}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label={'Grupo associado'}>
                {getFieldDecorator('group_id', {
                  initialValue: group.group_id || null,
                })(
                  <Select
                    showSearch
                    onSelect={this.handleSelectChange}
                    loading={this.state.loadingGroups}
                    showAction={['focus', 'click']}
                    filterOption={
                      (input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={0} value={null}>
                      Nenhum
                    </Option>
                    {!this.state.groups
                      ? null
                      : this.state.groups.map(group => {
                        return (
                          <Option
                            key={group.id}
                            value={group.id}
                          >
                            {group.name}
                          </Option>
                        );
                      })}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item name="logo">
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormItem label={'Logo'}>
                    <Upload
                      onRemove={() => this.setState({
                        institution_logo: [],
                      })}
                      beforeUpload={file => {
                        this.setState({
                          institution_logo: [file],
                        });
                        this.viewLogo(file)
                        return false;
                      }}
                      fileList={this.state.institution_logo}
                    >
                      <Button>
                        <Icon type="upload"/> Selecionar arquivo
                      </Button>
                    </Upload>
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Avatar
                    shape="square"
                    alt="logo"
                    src={
                      this.state.logo64 
                      ? this.state.logo64 
                      : institution.logo_url
                        ? institution.logo_url
                        : null
                    }
                    className="institution-logo-intranet"
                  >
                    Sem logo
                  </Avatar>
                </Col>
              </Form.Item>
            </Col>
          </Row>
          
            <Form.Item name="states">
            <Row gutter={[28, 28]}>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleInputChange}
                  value="active"
                  checked={institution.active}>
                  Ativo
                </Checkbox>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleInputChange}
                  value="homologated"
                  checked={institution.homologated}>
                  Homologado
                </Checkbox>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleInputChange}
                  value="is_clinic"
                  checked={institution.is_clinic}>
                  É consultório
                </Checkbox>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleInputChange}
                  value="is_group"
                  checked={institution.is_group}>
                  É grupo
                </Checkbox>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleInputChange}
                  value="is_healthchess"
                  checked={institution.is_healthchess}>
                  É healthchess
                </Checkbox>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleInputChange}
                  value="is_hospital_clinic"
                  checked={institution.is_hospital_clinic}>
                  É clínica
                </Checkbox>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleInputChange}
                  value="enable_monitors"
                  checked={institution.enable_monitors}>
                  Habilitar monitores
                </Checkbox>
              </Col>
              
            </Row>
            </Form.Item>
        </Form>
      </Content>
    )
  }
}

function mapStateToProps(state) {return state}

const connected = withRouter(connect(mapStateToProps)(Form.create()(Institution)));
export default connected;
