import {usersConstants} from '../constants';
import {usersService} from "../services";

export const usersActions = {
  getUsers,
};

function getUsers(filter, cancelToken) {
  return dispatch => {
    dispatch(request());

    return usersService.getUsers(filter, cancelToken)
      .then(users => {        
        dispatch(success(users));
        return users;
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: usersConstants.GETUSERS_REQUEST}
  }

  function success(data) {
    return {type: usersConstants.GETUSERS_SUCCESS, data}
  }

  function failure(error) {
    return {type: usersConstants.GETUSERS_FAILURE, error}
  }
}
