import Api from '../helpers/api';

export const sectorsService = {
  getSectors,
  postSector,
  putSector,
  deleteSector
};

function getSectors(filter, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/preset/surgery-centers/?size=20&offset=0`, filter, {cancelToken})
}

function postSector(sector){
  return Api.post(`/preset/surgery-centers`, sector)
}

function putSector(sector){
  return Api.put(`/preset/surgery-centers/${sector.uuid}`, sector)
}

function deleteSector(uuid){
  return Api.delete(`/preset/surgery-centers/${uuid}`)
}
