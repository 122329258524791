import { Link, matchPath, withRouter } from "react-router-dom";
import React, { Component } from "react";
import { Menu, Layout } from "antd";
import routes, { pathRoutes } from "../../routes";
import DashboardLogo from "../../assets/imgs/dashboard-logo.svg";
import { connect } from "react-redux";
const { Sider } = Layout;
const SubMenu = Menu.SubMenu;

class AppMenu extends Component {
  state = {
    collapsed: false,
    broken: false,
    location: {},
  };

  onCollapse = (collapsed) => {
    if (typeof this.props.onCollapse === "function") {
      this.props.onCollapse(collapsed);
    }
    this.setState({ collapsed });
  };

  onBreakpoint = (broken) => {
    if (typeof this.props.onBreakpoint === "function") {
      this.props.onBreakpoint(broken);
    }
    this.setState({ broken });
  };

  componentDidMount() {
    this.setState({ location: this.props.location });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ location: this.props.location });
    }
  }

  render() {
    if (!this.state.location.pathname) {
      this.setState({ location: this.props.location });
    }
    let location = this.state.location.pathname;

    for (let index in pathRoutes) {
      let match = matchPath(this.state.location.pathname, {
        path: pathRoutes[index],
      });
      if (match && match.isExact) {
        location = match.path;
        break;
      }
    }

    let selected;
    switch (location) {
      case pathRoutes.home:
      case pathRoutes.editSchedule:
      case pathRoutes.newSchedule:
      case pathRoutes.schedules:
        selected = pathRoutes.schedules;
        break;
      case pathRoutes.newPatient:
      case pathRoutes.editPatient:
      case pathRoutes.patients_page:
      case pathRoutes.patients:
        selected = pathRoutes.patients;
        break;
      default:
        selected = location;
        break;
    }

    let userPermissions = JSON.parse(localStorage.getItem("user-permissions"));

    let menuUser = false;

    if (userPermissions && userPermissions.is_admin) {
      menuUser = (
        <Menu.Item
          key={"/users"}
          onClick={() => this.setState({ collapsed: this.state.broken })}
        >
          <Link to={"/users"}>{"Usuários"}</Link>
        </Menu.Item>
      );
    }

    const institution = JSON.parse(
      localStorage.getItem("selected-institution")
    );
    const user = JSON.parse(localStorage.getItem("user"));

    const generateMenu = (route) => {
      if (
        !route.menu ||
        (route.hasOwnProperty("not_group") &&
          JSON.parse(localStorage.getItem("selected-institution")).is_group)
      ) {
        return null;
      }

      if (
        typeof route.checkVisible === "function" &&
        !route.checkVisible(institution, user)
      ) {
        return null;
      }

      if (route.sub && route.sub.length) {
        return (
          <SubMenu key={route.description} title={route.description}>
            {route.sub.map((subRoute) => {
              return generateMenu(subRoute);
            })}
          </SubMenu>
        );
      }

      if (route.role && route.role.indexOf(user.type) == -1) {
        return null;
      }

      if (route.divider) {
        return (
          <Menu.Divider style={{marginTop: 3}}/>
        )
      }

      return (
        <Menu.Item
          key={route.to}
          onClick={() => this.setState({ collapsed: this.state.broken })}
        >
          <Link to={route.to}>{route.description}</Link>
        </Menu.Item>
      );
    };

    return (
      <Sider
        width={205}
        className="sider"
        breakpoint="xl"
        collapsedWidth="0"
        onBreakpoint={this.onBreakpoint}
        onCollapse={this.onCollapse}
        collapsed={this.state.collapsed}
      >
        <Menu
          mode="inline"
          className="menu"
          selectedKeys={[selected]}
          inlineIndent={19}
        >
          {routes.app.map((route) => generateMenu(route))}
        </Menu>
        <div
          className={`another-modules ${this.state.collapsed ? "hidden" : ""}`}
        >
          <a
            rel="noopener noreferrer"
            href="https://dashboard.axreg.com.br/"
            target="_blank"
          >
            <img src={DashboardLogo} alt="Dashboard" />
          </a>
        </div>
      </Sider>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const connected = withRouter(connect(mapStateToProps)(AppMenu));
export default connected;
