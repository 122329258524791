let configs = {};

switch (process.env.REACT_APP_NODE_ENV) {
  default:
  case 'localhost':
    configs = {
      urlApi: 'http://localhost',
    };
    break;
  case 'development':
    configs = {
      urlApi: 'https://dev-api.axreg.app',
    };
    break;
  case 'homolog':
    configs = {
      urlApi: 'http://elb-axreg-integra-hml-2111881642.sa-east-1.elb.amazonaws.com',
    };
    break;
  case 'production':
    configs = {
      urlApi: 'https://axreg-server.anestech.com.br',
    };
    break;
}

export default configs;
