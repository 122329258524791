import React, { Component } from "react";
import { Modal, Menu, Avatar,Button } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { institutionActions } from "../actions";
import { stringsHelp } from "../helpers";
import { pathRoutes } from "../routes";


class ModalCheckInstitution extends Component {

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(institutionActions.getInstitutions());
  }


  routeProcedurePre = obj_institution => {
    if (!obj_institution) return false;

    if (this.props.route === "pre") {
      this.props.history.push({
        pathname: pathRoutes.newSchedulePre,
        query: {institution: obj_institution}
      });
    }
    if (this.props.route === "schedule") {
      this.props.history.push({
        pathname: pathRoutes.newSchedule,
        query: {institution: obj_institution}
      });
    }
    if (this.props.route === "patient") {
      this.props.history.push({
        pathname: pathRoutes.newPatient,
        query: {institution: obj_institution}
      });
    }
  };

  filterInstitutions = (institutions) => {
    const selectedInstitution = JSON.parse(localStorage.getItem("selected-institution"));

    if (!institutions.items || !institutions.items.size > 1) return null;

    return institutions.items.filter((i) => {
      const validation1 = i.id !== selectedInstitution.id;
      let validation2 = true;

      if (this.props.route === 'schedule') {
        validation2 = !i.is_clinic;
      }

      return validation1 && validation2;
    });
  };

  render() {
    let { institutions } = this.props;
    institutions = this.filterInstitutions(institutions)
    return (
      <Modal
        visible={this.props.visible}
        title={<span>Selecione a instituição</span>}
        bodyStyle={{ paddingTop: "21px" }}
        zIndex={1024}
        footer={<Button
          key="cancel"
          type="default"
          onClick={() => this.props.setVisible(false)}
          style={{ textAlign: "left" }}
        >
          Cancelar
        </Button>}
      >
        <Menu>
          {institutions ? institutions.map(institution => {
                return (
                  <Menu.Item
                    key={institution.id}
                    onClick={(e) => this.routeProcedurePre(institution)}
                  >
                    <div className="ant-list-item-meta ant-group check-institution">
                      <div className="ant-list-item-meta-avatar">
                        <Avatar
                          shape="square"
                          src={
                            institution.logo_url
                              ? institution.logo_url
                              : null
                          }
                          className="institution-logo"
                        >
                          Logo
                        </Avatar>
                      </div>
                      <div className="ant-list-item-meta-content">
                        <h4
                          className="ant-list-item-meta-title"
                          style={{ marginBottom: -5 }}
                        >
                          {stringsHelp.firstLetterUpper(institution.name, true)}
                        </h4>
                      </div>
                    </div>
                  </Menu.Item>
                );
              })
            : ""}
        </Menu>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { institutions } = state;
  return {
    institutions
  };
}

const connected = withRouter(connect(mapStateToProps)(ModalCheckInstitution));

export default connected;
