import Api from '../helpers/api';

export const drugsService = {
  getDrugs,
  getDrug,
  putDrug
};

function getDrugs(filter, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/web/active-principles?page=1&limit=1000`, filter, {cancelToken})
}

function getDrug(id, filter, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/web/active-principles/${id}`, filter, {cancelToken})
}

function putDrug(id, objDrug){
  return Api.put(`/web/institutions/active-principles/${id}`, objDrug)
}