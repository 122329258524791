import "./device";
import React from 'react';
import ReactDOM from 'react-dom';
import { LocaleProvider } from 'antd';
import ptBR from 'antd/lib/locale-provider/pt_BR';
import App from './App';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import './index.scss';
import { Provider } from 'react-redux';
import { store } from './helpers';
import {PrivateRoute} from "./components/routes/PrivateRoute";
import Login from "./components/pages/Login";
import Logout from "./components/pages/Logout";
import RedirectApp from "./components/pages/RedirectApp";
import NotFound from "./components/routes/NotFound";
import AcceptInvite from "./components/routes/AcceptInvite";
import ValidateEmail from "./components/routes/ValidateEmail";
import ResetPassword from "./components/routes/ResetPassword";
import {pathRoutes} from "./routes";

ReactDOM.render((
  <Provider store={store}>
    <LocaleProvider locale={ptBR}>
      <BrowserRouter>
          <Switch>
            <Route path={pathRoutes.redirectToApp} component={RedirectApp}/>
            <Route path={pathRoutes.logout} component={Logout}/>
            <Route path={pathRoutes.login} component={Login}/>
            <PrivateRoute path={'(' + Object.values(pathRoutes).join('|') + ')'} component={App}/>
            <Route path={pathRoutes.acceptInvite} component={AcceptInvite}/>
            <Route path={pathRoutes.validateEmail} component={ValidateEmail}/>
            <Route path={pathRoutes.forgotPassword} component={ResetPassword}/>
            <Route component={NotFound}/>
          </Switch>
      </BrowserRouter>
    </LocaleProvider>
  </Provider>
), document.getElementById('root'));
