import { healthchessLogConstants } from '../constants';
import {List} from "immutable";

export function healthchessLog(state = {}, action) {
  switch (action.type) {
    case healthchessLogConstants.GETHEALTHCHESS_LOG_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        data: new List()
      });
    case healthchessLogConstants.GETHEALTHCHESS_LOG_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data
      });
    case healthchessLogConstants.GETHEALTHCHESS_LOG_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      });
    default:
      return state
  }
}
