export const stringsHelp = {
  firstLetterUpper,
  formatName,
  avatar,
  formatPhoneNumber,
  formatPhoneNumbers,
  removeAccent,
  removeSpecialCharacter,
  maxLength255,
  maxLength
};

function firstLetterUpper(string, eachWord) {  
  if(!string) return "";

  string = string.toLowerCase();
  if (eachWord) {
    return string.split(' ').map(word => firstLetterUpper(word)).join(' ');
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function formatName(name) {
  try {
    
    if(!name) return "";
    
    let newRame = name.toLowerCase().replace(/(?:^|\s)\S/g, function(capitalize) { return capitalize.toUpperCase(); });
 
    let PreposM = ["Da","De","Do","Das","Dos","A", "E"];
    let prepos = ["da","de","do","das","dos","a", "e"];
  
    for (var i = PreposM.length - 1; i >= 0; i--) {
      // eslint-disable-next-line
      newRame = newRame.replace(RegExp("\\b" + PreposM[i].replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + "\\b", "g"), prepos[i]);

    }
    
    return newRame;

  } catch (error) {
    return name.toLowerCase().replace(/(?:^|\s)\S/g, function(X) { return X.toUpperCase(); });
  }
}

function avatar(string) {
  if(!string) return "";
  
  string = string.toUpperCase().split(' ');

  let firstLetter = string[0].charAt(0);
  let secondLetter = string.length > 1 ? string.slice(-1).pop().charAt(0) : string[0].charAt(1);

  return firstLetter + secondLetter;
}

function removeAccent (text)
{   
    text = text.toLowerCase();                                                         
    text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
    text = text.replace(new RegExp('[Ç]','gi'), 'c');
    return text;                 
}

function formatPhoneNumbers(phone) {
  let phones = [];
  if(!phone) {
    return '';
  }

  phone = phone.replace(/\/\//g, '/').split('/');
  phone.forEach(v => {
    v = formatPhoneNumber(v);
    if (v.length > 0) {
      phones.push(v);
    }
  });

  return phones;
}

function formatPhoneNumber(phone) {
  phone = phone.replace(/[^\d]+/g, '');

  if(phone.length === 10 || phone.length === 11){
    return '(' + phone.substr(0, 2) + ') '  + phone.substr(2, phone.length-6) + '-' + phone.substr(phone.length-4, 4);
  } else if (phone.length > 7) {
    return phone.substr(0, phone.length-4) + '-' +phone.substr(phone.length-4, 4);
  } else if (phone.length > 4) {
    return phone.substr(0, 4) + '-' + phone.substr(4);
  }

  return phone;
}


function removeSpecialCharacter(text) {
  try {
      if(text.trim().length){
        return text.trim().replace(/[|&;$%@"<>()+,/]/g, "");
      }
      else{
        return text;
      }
  } catch (error) {
    return "";
  }
}


function maxLength255(text) {
  try {
      if(text.length > 255){
        return text.substring(0, 255)
      }
      else{
        return text;
      }
  } catch (error) {
    return "";
  }
}

function maxLength(text, length, ellipsis = false) {
  try {
      if(text.length > length){
        return text.substring(0, length) + (ellipsis ? "..." : "")
      }
      else{
        return text;
      }
  } catch (error) {
    return "";
  }
}