import React, { Component } from 'react';

import {
  Layout,
  Row,
  Col,
  Form,
  Table,
  Tag,
  Input,
  Select,
  Icon,
  Tooltip,
  DatePicker,
  Button,
  Popover,
  Empty,
  Tabs,
  Spin,
  notification,
  Modal,
} from 'antd';
import { connect } from 'react-redux';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import { CancelToken } from 'axios';
import moment from 'moment';
import { proceduresActions, selectsActions } from '../../actions';
import { proceduresService } from '../../services';
import { stringsHelp } from '../../helpers';
import { IconTabPreSvg, IconTabProcedureSvg } from '../../helpers/icons';
import {
  getFilterProcedures,
  setFilterProcedures,
} from '../../helpers/filters';
import Api from '../../helpers/api';
import { mixPanelService } from '../../services/mixpanel.service';

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const { TabPane } = Tabs;

class Procedures extends Component {
  state = {
    dataProcedures: [],
    start_date: getFilterProcedures()
      ? moment(getFilterProcedures().start_date, 'YYYY-MM-DD')
      : moment().subtract(parseInt(1, 10), 'months'),
    end_date: getFilterProcedures()
      ? moment(getFilterProcedures().end_date, 'YYYY-MM-DD')
      : moment(),
    anaesthetist_id: null,
    filterAll: '',
    loadingPDF: false,
    modalDeletePdf: {
      institution_name: '',
      patient_name: '',
      register_date: null,
      confirmVisible: false,
      pdf_id: null,
      type: '',
    },
  };

  cancelSource = CancelToken.source();

  loadMap = () => {
    const { dispatch, procedures } = this.props;

    if (procedures.loading) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    const objFilter = {
      start_date: this.state.start_date.format('YYYY-MM-DD'),
      end_date: this.state.end_date.format('YYYY-MM-DD'),
      anaesthetist_id: this.state.anaesthetist_id,
    };

    dispatch(
      proceduresActions.getProcedures(
        { params: objFilter },
        this.cancelSource.token
      )
    ).then((response) => {
      this.setState({
        dataProcedures: response,
      });

      this.searchTable(this.state.filterAll);
    });
  };

  loadSelects = () => {
    const { dispatch } = this.props;
    dispatch(selectsActions.getAnaesthetists(1, '', this.cancelSource.token));
  };

  componentDidMount() {
    this.loadSelects();
    this.loadMap();
    mixPanelService.tracking(mixPanelService.MENU_ATENDIMENTO);
  }

  searchTable = (value) => {
    const { procedures } = this.props;
    const text = value;

    setTimeout(() => {
      if (text.length) {
        const newDataProcedures = procedures.data.filter(
          (x) =>
            stringsHelp
              .removeAccent(x.name.toString().toLowerCase())
              .includes(stringsHelp.removeAccent(text.toLowerCase())) ||
            (x.surgeons &&
              stringsHelp
                .removeAccent(x.surgeons.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase()))) ||
            (x.patient_name &&
              stringsHelp
                .removeAccent(x.patient_name.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase()))) ||
            (x.surgeons &&
              stringsHelp
                .removeAccent(x.surgeons.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase()))) ||
            (x.surgical_teams &&
              stringsHelp
                .removeAccent(x.surgical_teams.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase()))) ||
            (x.institution_name &&
              stringsHelp
                .removeAccent(x.institution_name.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase()))) ||
            (x.anaesthetists &&
              stringsHelp
                .removeAccent(x.anaesthetists.toString().toLowerCase())
                .includes(stringsHelp.removeAccent(text.toLowerCase())))
        );
        this.setState({ dataProcedures: newDataProcedures, filterAll: value });
      } else {
        this.setState({ dataProcedures: procedures.data, filterAll: '' });
      }
    }, 300);
  };

  setFilter = (type, value) => {
    if (type === 'range_date') {
      this.setState({ start_date: value[0], end_date: value[1] }, () => {
        setFilterProcedures({ start_date: value[0], end_date: value[1] });
        this.loadMap();
      });
    }

    if (type === 'anaesthetist_id') {
      this.setState({ anaesthetist_id: value }, () => {
        this.setMixPanelSelect();
        this.loadMap();
      });
    }
  };

  downloadPdf = (pdf) => {
    this.setState({ loadingPDF: true });

    mixPanelService.tracking(mixPanelService.ATENDIMENTO_BAIXAR_PDF);

    proceduresService
      .getDownload(pdf)
      .then((response) => {
        this.setState({ loadingPDF: false });

        const link = document.createElement('a');
        link.href = response.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(() => {
        notification.error({
          message:
            'OPS! Falha ao realizar download, tente novamente mais tarde!',
        });
        this.setState({ loadingPDF: false });
      });
  };

  deletePdf = () => {
    this.setState({
      loadingPDF: true,
      modalDeletePdf: {
        ...this.state.modalDeletePdf,
        confirmVisible: false,
      },
    });

    Api.delete(`/app/procedure-pdf/${this.state.modalDeletePdf.pdf_id}`, {
      crossDomain: true,
    })
      .then(() => {
        this.loadMap();

        notification.success({
          message: 'PDF Excluído com sucesso',
        });

        this.setState({
          loadingPDF: false,
          modalDeletePdf: {
            institution_name: '',
            patient_name: '',
            register_date: null,
            type: '',
            pdf_id: null,
          },
        });
      })
      .catch((error) => {
        console.error(error);
        notification.error({
          message: 'Falha na exclusão do PDF. Tente novamente mais tarde.',
        });
      });
  };

  showConfirm = (all, tipo) => {
    const {
      institution_name: institutionName,
      patient_name: patientName,
      register_date: registerDate,
      id,
    } = all;
    this.setState({
      modalDeletePdf: {
        institution_name: institutionName,
        patient_name: patientName,
        register_date: registerDate,
        confirmVisible: true,
        pdf_id: id,
        type: tipo,
      },
    });
  };

  setMixPanel = (key) => {
    if (key === '1') {
      mixPanelService.tracking(mixPanelService.ATENDIMENTO_SELECIONAR_PRE);
    } else if (key === '2') {
      mixPanelService.tracking(mixPanelService.ATENDIMENTO_SELECIONAR_TRANS);
    } else {
      mixPanelService.tracking(mixPanelService.ATENDIMENTO_SELECIONAR_POS);
    }
  };

  setMixPanelSelect = () => {
    mixPanelService.tracking(mixPanelService.ATENDIMENTO_FILTRAR_ANESTESISTAS);
  };

  render() {
    const { procedures } = this.props;
    const { anaesthetists } = this.props.selects;
    const { modalDeletePdf } = this.state;
    const user = JSON.parse(localStorage.getItem('user'));
    const columnsTableProceduresPre = [
      {
        title: 'Paciente',
        dataIndex: 'patient_name',
        key: 'patient_name',
        render: (patientName) => patientName,
      },
      JSON.parse(localStorage.getItem('selected-institution')).is_group
        ? {
            title: 'Instituição',
            dataIndex: 'institution_name',
            key: 'institution_name',
            render: (institutionName) => {
              if (
                JSON.parse(localStorage.getItem('selected-institution'))
                  .is_group
              ) {
                return (
                  <span>
                    <Tag
                      className='tag-custom-round'
                      color='rgb(221, 246, 246)'
                      style={{ marginBottom: 3 }}
                    >
                      {' '}
                      <Icon type='home' />{' '}
                      {stringsHelp.maxLength(
                        stringsHelp.formatName(institutionName),
                        40,
                        true
                      )}
                    </Tag>
                  </span>
                );
              }
              return '';
            },
          }
        : {},
      {
        title: 'Convênio',
        dataIndex: 'medical_plan_name',
        key: 'medical_plan_name',
        render: (medicalPlanName) =>
          stringsHelp.firstLetterUpper(medicalPlanName, true),
      },
      {
        title: 'Procedimento(s)',
        dataIndex: 'name',
        key: 'name',
        render: (name) => stringsHelp.firstLetterUpper(name, true),
      },
      {
        title: 'Anestesiologista',
        dataIndex: 'anaesthetists',
        key: 'anaesthetists',
        render: (anaesthetists) => {
          if (!anaesthetists) return '';

          const listAnaesthetists = anaesthetists
            .split(',')
            .map((anaesthetist) => (
              <span style={{ lineHeight: '28px' }}>
                <Tag>{anaesthetist.trim()}</Tag>
              </span>
            ));

          return listAnaesthetists;
        },
      },
      {
        title: 'Data',
        dataIndex: 'register_date',
        key: 'register_date',
        width: 160,
        render: (registerDate) =>
          moment(registerDate).format('DD/MM/YYYY HH:mm'),
      },
      {
        title: 'PDF',
        dataIndex: 'pdf',
        key: 'pdf',
        render: (pdf, all) => {
          if (!pdf) return '';
          return (
            <>
              <Tooltip title='Download PDF'>
                <Button
                  type='link'
                  style={{ color: 'red', fontSize: '18px' }}
                  onClick={() => this.downloadPdf(pdf)}
                >
                  <Icon type='file-pdf' />
                </Button>
              </Tooltip>
              {user.type === 'AD' ? (
                <Tooltip title='Excluir PDF'>
                  <Button
                    type='link'
                    style={{ color: 'red', fontSize: '18px' }}
                    onClick={() => this.showConfirm(all, 'Pré-anestésico')}
                  >
                    <Icon type='delete' />
                  </Button>
                </Tooltip>
              ) : (
                ''
              )}
            </>
          );
        },
      },
    ];

    const columnsTableProcedures = [
      {
        title: 'Paciente',
        dataIndex: 'patient_name',
        key: 'patient_name',
        render: (patientName) => patientName,
      },
      JSON.parse(localStorage.getItem('selected-institution')).is_group
        ? {
            title: 'Instituição',
            dataIndex: 'institution_name',
            key: 'institution_name',
            render: (institutionName) => {
              if (
                JSON.parse(localStorage.getItem('selected-institution'))
                  .is_group
              ) {
                return (
                  <span>
                    <Tag
                      className='tag-custom-round'
                      color='rgb(221, 246, 246)'
                      style={{ marginBottom: 3 }}
                    >
                      {' '}
                      <Icon type='home' />{' '}
                      {stringsHelp.maxLength(
                        stringsHelp.formatName(institutionName),
                        40,
                        true
                      )}
                    </Tag>
                  </span>
                );
              }
              return '';
            },
          }
        : {},
      {
        title: 'Procedimento(s)',
        dataIndex: 'name',
        key: 'name',
        render: (name) => stringsHelp.firstLetterUpper(name, true),
      },
      {
        title: 'Anestesiologista',
        dataIndex: 'anaesthetists',
        key: 'anaesthetists',
        render: (anaesthetists) => {
          if (!anaesthetists) return '';

          const listAnaesthetists = anaesthetists
            .split(',')
            .map((anaesthetist) => (
              <span style={{ lineHeight: '28px' }}>
                <Tag>{anaesthetist.trim()}</Tag>
              </span>
            ));

          return listAnaesthetists;
        },
      },
      {
        title: 'Equipe cirúrgica',
        dataIndex: 'surgeons',
        key: 'surgeons',
        width: 140,
        render: (surgeons, record) => {
          const content = (
            <div>
              <p>
                <small>
                  <b>Cirúrgiões</b>
                </small>
              </p>
              {surgeons ? (
                surgeons.split(',').map((surgeon) => <p>{surgeon}</p>)
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
              <p style={{ marginTop: 5 }}>
                <small>
                  <b>Equipe auxiliar</b>
                </small>
              </p>
              {record.surgical_teams ? (
                record.surgical_teams
                  .split(',')
                  .map((surgicalTeam) => <p>{surgicalTeam}</p>)
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          );

          const contentNotFound = (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          );

          return (
            <div style={{ textAlign: 'center' }}>
              <Popover
                content={
                  surgeons || record.surgical_teams ? content : contentNotFound
                }
                title='Equipe cirúrgica'
                trigger='hover'
              >
                <Button type='dashed' shape='circle' icon='team' />
              </Popover>
            </div>
          );
        },
      },
      {
        title: 'Data',
        dataIndex: 'register_date',
        key: 'register_date',
        width: 160,
        render: (registerDate) =>
          moment(registerDate).format('DD/MM/YYYY HH:mm'),
      },
      {
        title: 'PDF',
        dataIndex: 'pdf',
        key: 'pdf',
        render: (pdf, all) => {
          if (!pdf) return '';
          return (
            <>
              <Tooltip title='Download PDF'>
                <Button
                  type='link'
                  style={{ color: 'red', fontSize: '18px' }}
                  onClick={() => this.downloadPdf(pdf)}
                >
                  <Icon type='file-pdf' />
                </Button>
              </Tooltip>
              {user.type === 'AD' ? (
                <Tooltip title='Excluir PDF'>
                  <Button
                    type='link'
                    style={{ color: 'red', fontSize: '18px' }}
                    onClick={() => this.showConfirm(all, 'Procedimento')}
                  >
                    <Icon type='delete' />
                  </Button>
                </Tooltip>
              ) : (
                ''
              )}
            </>
          );
        },
      },
    ];

    const columnsTableProceduresPos = [
      {
        title: 'Paciente',
        dataIndex: 'patient_name',
        key: 'patient_name',
        render: (patientName) => patientName,
      },
      JSON.parse(localStorage.getItem('selected-institution')).is_group
        ? {
            title: 'Instituição',
            dataIndex: 'institution_name',
            key: 'institution_name',
            render: (institutionName) => {
              if (
                JSON.parse(localStorage.getItem('selected-institution'))
                  .is_group
              ) {
                return (
                  <span>
                    <Tag
                      className='tag-custom-round'
                      color='rgb(221, 246, 246)'
                      style={{ marginBottom: 3 }}
                    >
                      {' '}
                      <Icon type='home' />{' '}
                      {stringsHelp.maxLength(
                        stringsHelp.formatName(institutionName),
                        40,
                        true
                      )}
                    </Tag>
                  </span>
                );
              }
              return '';
            },
          }
        : {},
      {
        title: 'Procedimento(s)',
        dataIndex: 'name',
        key: 'name',
        render: (name) => stringsHelp.firstLetterUpper(name, true),
      },
      {
        title: 'Anestesiologista',
        dataIndex: 'anaesthetists',
        key: 'anaesthetists',
        render: (anaesthetists) => {
          if (!anaesthetists) return '';

          const listAnaesthetists = anaesthetists
            .split(',')
            .map((anaesthetist) => (
              <span style={{ lineHeight: '28px' }}>
                <Tag>{anaesthetist.trim()}</Tag>
              </span>
            ));

          return listAnaesthetists;
        },
      },
      {
        title: 'Data',
        dataIndex: 'register_date',
        key: 'register_date',
        width: 160,
        render: (registerDate) =>
          moment(registerDate).format('DD/MM/YYYY HH:mm'),
      },
      {
        title: 'PDF',
        dataIndex: 'pdf',
        key: 'pdf',
        render: (pdf, all) => {
          if (!pdf) return '';
          return (
            <>
              <Tooltip title='Download PDF'>
                <Button
                  type='link'
                  style={{ color: 'red', fontSize: '18px' }}
                  onClick={() => this.downloadPdf(pdf)}
                >
                  <Icon type='file-pdf' />
                </Button>
              </Tooltip>
              {user.type === 'AD' ? (
                <Tooltip title='Excluir PDF'>
                  <Button
                    type='link'
                    style={{ color: 'red', fontSize: '18px' }}
                    onClick={() => this.showConfirm(all, 'Pós-anestésico')}
                  >
                    <Icon type='delete' />
                  </Button>
                </Tooltip>
              ) : (
                ''
              )}
            </>
          );
        },
      },
    ];

    return (
      <Content>
        <Row>
          <Col xs={24} sm={0}>
            <p>&nbsp;</p>
          </Col>
          <Col lg={24} xl={8}>
            <h1 className='title'>Atendimentos</h1>
          </Col>
          <Col lg={24} xl={16} className='containterFilterProcedures'>
            <Col xs={24} xl={16} className='filterProcedures'>
              <Select
                showSearch
                allowClear
                placeholder='Todos anestesistas'
                style={{ width: '100%' }}
                onChange={(e) => this.setFilter('anaesthetist_id', e)}
                loading={anaesthetists ? anaesthetists.loading : true}
                showAction={['click']}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {!anaesthetists
                  ? null
                  : anaesthetists.data.map((anaesthetist) => (
                      <Option key={anaesthetist.id} value={anaesthetist.id}>
                        {stringsHelp.formatName(anaesthetist.name)}
                      </Option>
                    ))}
              </Select>
            </Col>
            <Col lg={24} xl={8} className='filterProcedures'>
              <RangePicker
                allowClear={false}
                locale={locale}
                value={[
                  moment(this.state.start_date, 'DD/MM/YYYY'),
                  moment(this.state.end_date, 'DD/MM/YYYY'),
                ]}
                style={{ width: '100%' }}
                format={dateFormat}
                onChange={(e) => this.setFilter('range_date', e)}
              />
            </Col>
          </Col>
        </Row>
        <Row>
          <Search
            placeholder='Busca livre'
            disabled={procedures.loading}
            onChange={(e) => this.searchTable(e.target.value)}
            style={{ width: '100%', marginRight: 10, marginBottom: 10 }}
          />
        </Row>

        <Spin tip='Processando...' spinning={this.state.loadingPDF}>
          <Row>
            <Tabs defaultActiveKey='1' onChange={this.setMixPanel}>
              <TabPane
                tab={
                  <span>
                    <Icon component={IconTabPreSvg} />
                    &nbsp;&nbsp;PRÉ-ANESTÉSICO&nbsp;&nbsp;
                  </span>
                }
                key='1'
              >
                <Table
                  size='midle'
                  loading={procedures.loading}
                  dataSource={this.state.dataProcedures.filter(
                    (x) => x.type === 'pre'
                  )}
                  columns={columnsTableProceduresPre}
                  pagination={{
                    defaultPageSize: 30,
                    showSizeChanger: true,
                    pageSizeOptions: ['30', '50', '100'],
                  }}
                  footer={() => {
                    const rowsDataSource = !this.state.dataProcedures
                      ? 0
                      : this.state.dataProcedures.filter(
                          (x) => x.type === 'pre'
                        ).length;
                    return (
                      <span>
                        Total de registros: <Tag>{rowsDataSource}</Tag>
                      </span>
                    );
                  }}
                />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <Icon component={IconTabProcedureSvg} />
                    &nbsp;&nbsp;PROCEDIMENTO&nbsp;&nbsp;
                  </span>
                }
                key='2'
              >
                <Table
                  size='midle'
                  loading={procedures.loading}
                  dataSource={this.state.dataProcedures.filter(
                    (x) => x.type === 'trans'
                  )}
                  columns={columnsTableProcedures}
                  pagination={{
                    defaultPageSize: 30,
                    showSizeChanger: true,
                    pageSizeOptions: ['30', '50', '100'],
                  }}
                  footer={() => {
                    const rowsDataSource = !this.state.dataProcedures
                      ? 0
                      : this.state.dataProcedures.filter(
                          (x) => x.type === 'trans'
                        ).length;
                    return (
                      <span>
                        Total de registros: <Tag>{rowsDataSource}</Tag>
                      </span>
                    );
                  }}
                />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <Icon component={IconTabPreSvg} />
                    &nbsp;&nbsp;PÓS-ANESTÉSICO&nbsp;&nbsp;
                  </span>
                }
                key='3'
              >
                <Table
                  size='midle'
                  loading={procedures.loading}
                  dataSource={this.state.dataProcedures.filter(
                    (x) => x.type === 'pos'
                  )}
                  columns={columnsTableProceduresPos}
                  pagination={{
                    defaultPageSize: 30,
                    showSizeChanger: true,
                    pageSizeOptions: ['30', '50', '100'],
                  }}
                  footer={() => {
                    const rowsDataSource = !this.state.dataProcedures
                      ? 0
                      : this.state.dataProcedures.filter(
                          (x) => x.type === 'pos'
                        ).length;
                    return (
                      <span>
                        Total de registros: <Tag>{rowsDataSource}</Tag>
                      </span>
                    );
                  }}
                />
              </TabPane>
            </Tabs>
          </Row>
        </Spin>
        <Modal
          title=''
          visible={modalDeletePdf.confirmVisible}
          onOk={() => this.deletePdf()}
          okText='Excluir PDF'
          okType='danger'
          cancelText='Cancelar'
          confirmLoading={modalDeletePdf.loading}
          onCancel={() =>
            this.setState({
              modalDeletePdf: {
                ...modalDeletePdf,
                confirmVisible: false,
              },
            })
          }
        >
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            <span style={{ fontSize: 24, color: '#ffcd56', marginRight: 10 }}>
              <Icon type='question-circle' />
            </span>
            Confirmar exclusão do PDF
            <br />
            Tipo do PDF: {this.state.modalDeletePdf.type}
            <br />
            Paciente: {this.state.modalDeletePdf.patient_name}
            <br />
            Hospital: {this.state.modalDeletePdf.institution_name}
            <br />
            Atendimento:{' '}
            {moment(this.state.modalDeletePdf.register_date).format(
              'DD/MM/YYYY HH:mm'
            )}
          </p>
        </Modal>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { procedures, selects } = state;
  return {
    procedures,
    selects,
  };
}

export default connect(mapStateToProps)(Form.create()(Procedures));
