import Api from '../helpers/api';

export const healthchessLogService = {
  getHealthchessLog
};

function getHealthchessLog(objFilter, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`preset/healthchess/logs`, objFilter, { crossDomain: true, cancelToken })

}
