import Api from '../helpers/api';

export const usersService = {
  getUsers,
  getUserAccept,
  getValidateEmail,
  postTickets,
  postInvite,
  putInvite,
  postAcceptInvite,
  deleteUser,
  putPasswordUpdate,
  addToInstitution,
  removeUser
};

function getUsers(filter, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/users`, {cancelToken})
}

function getUserAccept(token) {
  return Api.get(`/users/token/${token}`)
}

function getValidateEmail(token) {
  return Api.get(`/web/users/validate/${token}`)
}

function postInvite(objInvite){
  return Api.post(`/users/invite-to-institution`, objInvite)
}

function putInvite(user_id,objPermission){
  return Api.put(`/users/permissions/${user_id}`, objPermission)
}

function putPasswordUpdate(objUpdate){
  return Api.put(`/web/users/passwordUpdate`, objUpdate, {validateStatus: false})
}

function postAcceptInvite(token,objInvite){
  return Api.post(`/users/accept-invite-to-institution/${token}`, objInvite)
}

function postTickets(msg){
  return Api.post(`/app/tickets`, msg)
}

function deleteUser(user_id, institution_id){
  return Api.delete(`/users/institution-association/${user_id}`, {data: {institution_id}})
}

// As funções removeUser e addtoInstitution abaixo, diferente das funções de
// convite, imediatamente adicionam o usuário à instituição. Elas são usadas no
// intranet.
function removeUser(userId, institutionId) {
  return Api.post(`/users/remove-from-institution`, {
    user_id: userId,
    institution_id: institutionId,
  })
}

function addToInstitution(objInvite) {
  return Api.post('/users/add-to-institution', objInvite);
}
