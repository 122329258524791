import Api from '../helpers/api';

export const selectsService = {
  getEthnicGroups,
  getSurgeryCenters,
  getSurgeryCenterRooms,
  getRooms,
  getMedicalPlans,
  getSurgicalProceduresMostUsed,
  getSurgicalProcedures,
  getSurgeryTypes,
  getSurgeons,
  getAnaesthetists,
  getSpecialites,
  getStates,
  getTables,
  getSurgicalTeamPositions,
  getCancellationReason
};

function getEthnicGroups() {
  return Api.get(`/app/ethnic-groups`)
}

function getSurgicalProceduresMostUsed(table_name) {
  return Api.get(`/surgical-map/surgical-procedures/most-used/${table_name}`)
}

function getSurgicalProcedures(table_name, page, filter, cancelToken) {
  filter = filter === undefined ? '' : filter ;
  cancelToken = cancelToken === undefined ? null : cancelToken ;

  return Api.get(`/surgical-map/surgical-procedures/${page}/${table_name}${filter !== '' ? '/' + filter : ''}`, {cancelToken})
}

function getSurgeons(page, filter, cancelToken) {
  filter = filter === undefined ? '' : filter ;
  cancelToken = cancelToken === undefined ? null : cancelToken ;

  return Api.get(`/surgical-map/surgeons/${page}${filter !== '' ? '/' + filter : ''}`, {cancelToken})
}

function getAnaesthetists(page, filter, cancelToken) {
  filter = filter === undefined ? '' : filter ;
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/surgical-map/anaesthetists/${page}${filter !== '' ? '/' + filter : ''}`, {cancelToken})
}

function getSurgeryCenters(institutionId = null) {
  return Api.get(`/surgical-map/surgery_centers/${institutionId ? `?institution_id=${institutionId}` : ""}`)
    .then(centers => {
      localStorage.setItem('surgery_centers', JSON.stringify(centers));
      return centers;
    })
}

function getSurgeryCenterRooms(surgery_center_id) {
  return new Promise((resolve, reject) => {
    try {
      let centers = JSON.parse(localStorage.getItem('surgery_centers'));

      let center = centers.filter(center => center.id === surgery_center_id);

      if(center.length === 1) {
        resolve(center[0].surgery_center_rooms);
      } else {
        throw new Error('Não foi possível identificar o centro cirúrgico.');
      }
    } catch (e) {
      reject(e);
    }
  })
}

function getSurgeryTypes() {
  return Api.get(`/app/surgery_types`)
}

function getRooms() {
  return Api.get(`/app/rooms`)
}

function getMedicalPlans() {
  return Api.get(`/surgical-map/medical-plans`)
}


function getSpecialites() {
  return Api.get(`/v2/surgeons/specialities`)
    .then(specialities => {
      localStorage.setItem('specialites', JSON.stringify(specialities));
      return specialities;
    })
}


function getStates() {
  return Api.get(`/v2/states`)
    .then(states => {
      localStorage.setItem('states', JSON.stringify(states));
      return states;
    })
}


function getTables() {
  return Api.get(`/v2/medical-plan-tables`)
    .then(states => {
      localStorage.setItem('tables', JSON.stringify(states));
      return states;
    })
}

function getSurgicalTeamPositions() {
  return Api.get(`/preset/surgical-team-positions`)
    .then(states => {
      return states;
    })
}

function getCancellationReason() {
  return Api.get(`/app/procedures/cancellation-reasons`)
    .then(states => {
      return states;
    })
}


